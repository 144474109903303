import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  Slice,
} from '@reduxjs/toolkit'
import { db } from 'fb/index'
import {
  collection,
  DocumentReference,
  Timestamp,
  addDoc,
  serverTimestamp,
  doc,
  getDoc,
  updateDoc,
  query,
  where,
  getDocs,
} from 'firebase/firestore'
import { AppDispatch } from 'index'
import { RootState } from 'reducks/reducers'

interface MaterialCostState {
  materialCost: MaterialCost | null
  materialCosts: MaterialCost[]
}

interface MaterialCost {
  id: string
  material_ref: DocumentReference
  shop_ref: DocumentReference
  suppliers: Supplier[]
  yield_rate: number
  author: string
  created_at: Timestamp | null
  updated_at: Timestamp | null
}

interface InternalCapacity {
  internal_capacity_count: number
  internal_capacity_unit: string
  jancode: string | null
  number_item: number | null
  number_item_unit: string | null
  origin_name: string | null
}

interface Supplier {
  internal_capacity: InternalCapacity | null
  internal_capacity_manual_input: string | null
  internal_capacity_count: number
  internal_capacity_unit: string
  number_item: number | null
  number_item_unit: string | null
  supplier_category: string
  supplier_company_name: string
  supplier_branch_name: string
  cost_price: number
  tax: number
  memo: string
  used_costing: boolean
}

export type MaterialCostType = MaterialCost

export type InternalCapacityType = InternalCapacity

export type SupplierType = Supplier

const initialState = {
  materialCost: null,
  materialCosts: [],
} as MaterialCostState

export const getMaterialCostById = createAsyncThunk<
  MaterialCost | null,
  { id: string },
  {
    dispatch: AppDispatch
    state: RootState
  }
>('materialCost/getMaterialCostById', async ({ id }, thunkApi) => {
  const { staff } = thunkApi.getState().staffSlice
  if (!staff || !staff.shopId) {
    return null
  }
  const q = doc(db, 'material_costs', id)
  const querySnapshot = await getDoc(q)
  const materialCost = querySnapshot.data()
  if (!materialCost) {
    return null
  }
  const shopId = materialCost.shop_ref.id
    ? materialCost.shop_ref.id
    : // @ts-ignore
      materialCost.shop_ref._key.path.segments[6]
  if (!staff.isAdmin && shopId !== staff.shopId) {
    return null
  }
  const response: MaterialCost = {
    id: querySnapshot.id,
    material_ref: materialCost.material_ref,
    shop_ref: materialCost.shop_ref,
    suppliers: materialCost.suppliers,
    yield_rate: materialCost.yield_rate,
    author: materialCost.author,
    created_at: materialCost.created_at,
    updated_at: materialCost.updated_at,
  }
  return response
})

export const getMaterialCostByMaterialId = createAsyncThunk<
  MaterialCost | null,
  { id: string },
  {
    dispatch: AppDispatch
    state: RootState
  }
>('materialCost/getMaterialCostByMaterialId', async ({ id }, thunkApi) => {
  const { staff } = thunkApi.getState().staffSlice
  if (!staff || !staff.shopId) {
    return null
  }
  const q = query(
    collection(db, 'material_costs'),
    where('material_ref', '==', doc(db, 'materials', id)),
    where('shop_ref', '==', doc(db, 'shops', staff.shopId)),
  )
  const querySnapshot = await getDocs(q)
  const materialCost = querySnapshot.docs[0].data()
  if (!materialCost) {
    return null
  }
  const response: MaterialCost = {
    id: querySnapshot.docs[0].id,
    material_ref: materialCost.material_ref,
    shop_ref: materialCost.shop_ref,
    suppliers: materialCost.suppliers,
    yield_rate: materialCost.yield_rate,
    author: materialCost.author,
    created_at: materialCost.created_at,
    updated_at: materialCost.updated_at,
  }
  return response
})

export const getMaterialCostByMaterialIds = createAsyncThunk<
  MaterialCost[],
  { ids: string[] | null },
  {
    dispatch: AppDispatch
    state: RootState
  }
>('materialCost/getMaterialCostByMaterialIds', async ({ ids }, thunkApi) => {
  const { staff } = thunkApi.getState().staffSlice
  if (!staff || !staff.shopId) {
    return []
  }
  // const refList: DocumentReference[] = []
  // await Promise.all(
  //   ids.map(async (id) => {
  //     refList.push(doc(db, 'materials', id))
  //   }),
  // )
  const q = query(
    collection(db, 'material_costs'),
    where('shop_ref', '==', doc(db, 'shops', staff.shopId)),
    // where('material_ref', 'in', refList),
  )
  const querySnapshot = await getDocs(q)
  const materialCosts: MaterialCost[] = []
  await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      const materialCost = doc.data()
      materialCosts.push({
        id: doc.id,
        material_ref: materialCost.material_ref,
        shop_ref: materialCost.shop_ref,
        suppliers: materialCost.suppliers,
        yield_rate: materialCost.yield_rate,
        author: materialCost.author,
        created_at: materialCost.created_at,
        updated_at: materialCost.updated_at,
      })
    }),
  )
  if (ids !== null) {
    // material_refでフィルタ
    materialCosts.filter((materialCost) =>
      ids.includes(materialCost.material_ref.id),
    )
  }
  return materialCosts
})

export const createMaterialCost = createAsyncThunk<
  string | null,
  {
    material_id: string
    suppliers: Supplier[]
    yield_rate: number
    author: string
  },
  {
    dispatch: AppDispatch
    state: RootState
  }
>(
  'materialCost/createMaterialCost',
  async ({ material_id, suppliers, yield_rate, author }, thunkApi) => {
    const { staff } = thunkApi.getState().staffSlice
    if (!staff || !staff.shopId || !staff.storeId) {
      return null
    }
    const materialCostRef = collection(db, 'material_costs')
    const data = {
      material_ref: doc(db, 'materials', material_id),
      shop_ref: doc(db, 'shops', staff.shopId),
      suppliers: suppliers,
      yield_rate: yield_rate,
      author: author,
      created_at: serverTimestamp(),
      updated_at: serverTimestamp(),
    }
    const docRef = await addDoc(materialCostRef, data)
    return docRef.id
  },
)

export const updateMaterialCost = createAsyncThunk<
  string | null,
  {
    id: string
    material_id: string
    suppliers: Supplier[]
    yield_rate: number
    author: string
  },
  {
    dispatch: AppDispatch
    state: RootState
  }
>(
  'materialCost/updateMaterialCost',
  async ({ id, material_id, suppliers, yield_rate, author }, thunkApi) => {
    const { staff } = thunkApi.getState().staffSlice
    if (!staff || !staff.shopId || !staff.storeId) {
      return null
    }
    // データの存在を確認
    const q = doc(db, 'material_costs', id)
    const querySnapshot = await getDoc(q)
    const materialCost = querySnapshot.data()
    if (!materialCost) {
      return null
    }
    // shopが同じか確認
    if (staff.shopId !== materialCost.shop_ref.id) {
      return null
    }
    const materialCostRef = doc(db, 'material_costs', id)
    const data = {
      material_ref: doc(db, 'materials', material_id),
      suppliers: suppliers,
      author: author,
      yield_rate: yield_rate,
      updated_at: serverTimestamp(),
    }
    await updateDoc(materialCostRef, data)
    return id
  },
)

export const materialCostSlice: Slice<
  MaterialCostState,
  { clearMaterialCost(state: MaterialCostState): void },
  'materialCost'
> = createSlice({
  name: 'materialCost',
  initialState,
  reducers: {
    clearMaterialCost(state) {
      state.materialCost = null
    },
  },
  extraReducers: {
    [getMaterialCostById.fulfilled.type]: (
      state,
      action: PayloadAction<MaterialCost | null>,
    ) => {
      if (action.payload !== null) {
        state.materialCost = action.payload
      }
    },
  },
})

export const { clearMaterialCost } = materialCostSlice.actions
export default materialCostSlice.reducer
