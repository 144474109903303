import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  Slice,
} from '@reduxjs/toolkit'
import { db } from 'fb/index'
import {
  collection,
  query,
  getDocs,
  DocumentReference,
  Timestamp,
  addDoc,
  serverTimestamp,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  where,
  orderBy,
} from 'firebase/firestore'
import { BreadDetailPetternInformationType } from 'reducks/business/slice'
import { AppDispatch } from 'index'
import { RootState } from 'reducks/reducers'

interface CostSimulationState {
  costSimulation: CostSimulation | null
  costSimulations: CostSimulation[]
}

interface CostSimulation {
  id: string
  name: string
  recipes: BreadDetailPetternInformationType[]
  doughs: BreadDetailPetternInformationType[]
  fillings: BreadDetailPetternInformationType[]
  amount_dough_before_baking: number
  loss_rate: number
  memo: string
  selling_price: number
  material_cost: number
  packaging_cost: number
  cost_rate: number
  is_percent: boolean
  is_gram: boolean
  shop_ref: DocumentReference
  created_at: Timestamp | null
  updated_at: Timestamp | null
}

export type CostSimulationType = CostSimulation

const initialState = {
  costSimulation: null,
  costSimulations: [],
} as CostSimulationState

export const getCostSimulations = createAsyncThunk<
  CostSimulation[] | null,
  void,
  {
    dispatch: AppDispatch
    state: RootState
  }
>('costSimulation/getCostSimulations', async (_, thunkApi) => {
  const { staff } = thunkApi.getState().staffSlice
  if (!staff || !staff.shopId) {
    return null
  }
  const costSimulations: CostSimulation[] = []
  const q = query(
    collection(db, 'costSimulations'),
    where('shop_ref', '==', doc(db, 'shops', staff.shopId)),
    orderBy('name'),
  )
  const querySnapshot = await getDocs(q)
  await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      const costSimulation = doc.data()
      costSimulations.push({
        id: doc.id,
        name: costSimulation.name,
        recipes: costSimulation.recipes,
        doughs: costSimulation.doughs,
        fillings: costSimulation.fillings,
        amount_dough_before_baking: costSimulation.amount_dough_before_baking,
        loss_rate: costSimulation.loss_rate,
        memo: costSimulation.memo,
        selling_price: costSimulation.selling_price,
        material_cost: costSimulation.material_cost,
        packaging_cost: costSimulation.packaging_cost,
        cost_rate: costSimulation.cost_rate,
        is_percent: costSimulation.is_percent,
        is_gram: costSimulation.is_gram,
        shop_ref: costSimulation.shop_ref,
        created_at: costSimulation.created_at,
        updated_at: costSimulation.updated_at,
      })
    }),
  )
  return costSimulations
})

export const getCostSimulationById = createAsyncThunk<
  CostSimulation | null,
  { id: string },
  {
    dispatch: AppDispatch
    state: RootState
  }
>('costSimulation/getCostSimulationById', async ({ id }, thunkApi) => {
  const { staff } = thunkApi.getState().staffSlice
  if (!staff || !staff.shopId) {
    return null
  }
  const q = doc(db, 'costSimulations', id)
  const querySnapshot = await getDoc(q)
  const costSimulation = querySnapshot.data()
  if (!costSimulation) {
    return null
  }
  const costSimulationShopId = costSimulation.shop_ref.id
    ? costSimulation.shop_ref.id
    : // @ts-ignore
      costSimulation.shop_ref._key.path.segments[6]
  // 所属しているお店の情報しか取得できない
  if (costSimulationShopId !== staff.shopId) {
    return null
  }
  const response: CostSimulation = {
    id: querySnapshot.id,
    name: costSimulation.name,
    recipes: costSimulation.recipes,
    doughs: costSimulation.doughs,
    fillings: costSimulation.fillings,
    amount_dough_before_baking: costSimulation.amount_dough_before_baking,
    loss_rate: costSimulation.loss_rate,
    memo: costSimulation.memo,
    selling_price: costSimulation.selling_price,
    material_cost: costSimulation.material_cost,
    packaging_cost: costSimulation.packaging_cost,
    cost_rate: costSimulation.cost_rate,
    is_percent: costSimulation.is_percent,
    is_gram: costSimulation.is_gram,
    shop_ref: costSimulation.shop_ref,
    created_at: costSimulation.created_at,
    updated_at: costSimulation.updated_at,
  }
  return response
})

export const createCostSimulation = createAsyncThunk<
  string | null,
  {
    name: string
    recipes: BreadDetailPetternInformationType[]
    doughs: BreadDetailPetternInformationType[]
    fillings: BreadDetailPetternInformationType[]
    amount_dough_before_baking: number
    loss_rate: number
    memo: string
    selling_price: number
    material_cost: number
    packaging_cost: number
    cost_rate: number
    is_percent: boolean
    is_gram: boolean
  },
  {
    dispatch: AppDispatch
    state: RootState
  }
>(
  'costSimulation/createCostSimulation',
  async (
    {
      name,
      recipes,
      doughs,
      fillings,
      amount_dough_before_baking,
      loss_rate,
      memo,
      selling_price,
      material_cost,
      packaging_cost,
      cost_rate,
      is_percent,
      is_gram,
    },
    thunkApi,
  ) => {
    const { staff } = thunkApi.getState().staffSlice
    if (!staff || !staff.shopId) {
      return null
    }
    const costSimulationRef = collection(db, 'costSimulations')
    const data = {
      name: name,
      recipes: recipes,
      doughs: doughs,
      fillings: fillings,
      amount_dough_before_baking: amount_dough_before_baking,
      loss_rate: loss_rate,
      memo: memo,
      selling_price: selling_price,
      material_cost: material_cost,
      packaging_cost: packaging_cost,
      cost_rate: cost_rate,
      is_percent: is_percent,
      is_gram: is_gram,
      shop_ref: doc(db, 'shops', staff.shopId),
      created_at: serverTimestamp(),
      updated_at: serverTimestamp(),
    }
    const docRef = await addDoc(costSimulationRef, data)
    return docRef.id
  },
)

export const updateCostSimulation = createAsyncThunk<
  string | null,
  {
    id: string
    name: string
    recipes: BreadDetailPetternInformationType[]
    doughs: BreadDetailPetternInformationType[]
    fillings: BreadDetailPetternInformationType[]
    amount_dough_before_baking: number
    loss_rate: number
    memo: string
    selling_price: number
    material_cost: number
    packaging_cost: number
    cost_rate: number
    is_percent: boolean
    is_gram: boolean
  },
  {
    dispatch: AppDispatch
    state: RootState
  }
>(
  'costSimulation/updateCostSimulation',
  async (
    {
      id,
      name,
      recipes,
      doughs,
      fillings,
      amount_dough_before_baking,
      loss_rate,
      memo,
      selling_price,
      material_cost,
      packaging_cost,
      cost_rate,
      is_percent,
      is_gram,
    },
    thunkApi,
  ) => {
    const { staff } = thunkApi.getState().staffSlice
    if (!staff || !staff.shopId) {
      return null
    }
    const costSimulationRef = doc(db, 'costSimulations', id)
    const querySnapshot = await getDoc(costSimulationRef)
    const costSimulation = querySnapshot.data()
    if (!costSimulation) {
      return null
    }
    const costSimulationShopId = costSimulation.shop_ref.id
      ? costSimulation.shop_ref.id
      : // @ts-ignore
        costSimulation.shop_ref._key.path.segments[6]
    // 所属しているお店の情報しか更新できない
    if (costSimulationShopId !== staff.shopId) {
      return null
    }
    const data = {
      name: name,
      recipes: recipes,
      doughs: doughs,
      fillings: fillings,
      amount_dough_before_baking: amount_dough_before_baking,
      loss_rate: loss_rate,
      memo: memo,
      selling_price: selling_price,
      material_cost: material_cost,
      packaging_cost: packaging_cost,
      cost_rate: cost_rate,
      is_percent: is_percent,
      is_gram: is_gram,
      shop_ref: doc(db, 'shops', staff.shopId),
      updated_at: serverTimestamp(),
    }
    await updateDoc(costSimulationRef, data)
    return id
  },
)

export const deleteCostSimulation = createAsyncThunk<void, { id: string }>(
  'costSimulation/deleteCostSimulation',
  async ({ id }) => {
    await deleteDoc(doc(db, 'costSimulations', id))
  },
)

export const costSimulationSlice: Slice<
  CostSimulationState,
  { clearCostSimulation(state: CostSimulationState): void },
  'costSimulation'
> = createSlice({
  name: 'costSimulation',
  initialState,
  reducers: {
    clearCostSimulation(state) {
      state.costSimulation = null
    },
  },
  extraReducers: {
    [getCostSimulations.fulfilled.type]: (
      state,
      action: PayloadAction<CostSimulation[] | null>,
    ) => {
      if (action.payload !== null) {
        state.costSimulations = action.payload
      }
    },
    [getCostSimulationById.fulfilled.type]: (
      state,
      action: PayloadAction<CostSimulation | null>,
    ) => {
      if (action.payload !== null) {
        state.costSimulation = action.payload
      }
    },
  },
})

export const { clearCostSimulation } = costSimulationSlice.actions
export default costSimulationSlice.reducer
