import { FC } from 'react'
import styles from 'styles/components/tab.module.scss'
import Tab from 'components/Tab/Tab'

type Props = {
  isRecipe: boolean
  toBread: string | null
  toRecipe: string | null
  breadId?: string | null
  breadName?: string | null
  handleSubmit?: () => Promise<void>
}

const BreadAddTabHeader: FC<Props> = (props) => {
  const { isRecipe, toBread, toRecipe, breadId, breadName, handleSubmit } =
    props

  return (
    <>
      <div className={styles.container}>
        <div className={styles.container_tab}>
          <Tab
            name="商品基本情報"
            selected={!isRecipe}
            to={toBread ? toBread : ''}
            breadId={breadId}
            handleSubmit={isRecipe ? handleSubmit : undefined}
          />
          <Tab
            name="レシピ情報"
            selected={isRecipe}
            to={toRecipe ? toRecipe : ''}
            breadId={breadId}
            handleSubmit={!isRecipe ? handleSubmit : undefined}
          />
        </div>
      </div>
      <div className={styles.title}>{breadName ? breadName : '　'}</div>
    </>
  )
}

export default BreadAddTabHeader
