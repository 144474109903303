import PageHedding from 'components/PageHedding/PageHedding'
import { FC, useCallback, useEffect, useState, useRef, MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import ReactToPrint from 'react-to-print'
import ExcelJS from 'exceljs'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import styles from 'styles/components/template.module.scss'
import pageHeddingArrow from 'images/PageHedding/page-hedding-arrow.png'
import SubmitButton from 'components/Button/SubmitButton'
import SelectBox from 'components/Form/SelectBox'
import CheckBoxWithLightLabel from 'components/Form/WithLightLabel/CheckBox'
import RadioButton from 'components/Form/RadioButton'
// import { format } from 'date-fns'
import IconArrow from '../PriceCardTemplate/IconArrow'
import {
  getBreads,
  BreadType,
  BreadRepresentationLawName,
  BreadRepresentationLawNameLabel,
} from 'reducks/bread/slice'
import {
  getRecipeById,
  getRecipeByBreadId,
  RecipeType,
  MaterialInformationType,
} from 'reducks/recipe/slice'
import { getMaterialById, MaterialType } from 'reducks/material/slice'
import { getDoughById, DoughType } from 'reducks/dough/slice'
import { getFillingById, FillingType } from 'reducks/filling/slice'
import {
  getMaterialInfomationByBreadId,
  BreadDetailInformationType,
} from 'reducks/business/slice'
import recycleMarkPaper from 'images/BackSeal/recycleMark-paper.png'
import recycleMarkPlastic from 'images/BackSeal/recycleMark-plastic.png'
import { RootState } from 'reducks/reducers'
import { setIsLoading } from 'reducks/loading/slice'

type BackSealInfo = {
  breadName: string
  count: number
  labelName: string
  rawMaterialName: string
  additive: string
  allergen: string
  baseAllergen: string
  additiveAllergen: string
  internalCapacityCount: string
  internalCapacityUnit: string
  deadlineType: string
  deadlineDate: string
  afterThawingDeadlineDate: string
  preservationMethod: string
  manufacturer: string
  nutritionalContent: string
  energy: string
  protein: string
  lipid: string
  carbohydrate: string
  saltEquivalent: string
  contamination: string
}

type CsvOutputBackSealInfo = {
  breadName: string
  labelName: string
  rawMaterialName: string
  internalCapacity: string
  consumption: string
  relish: string
  afterThawingConsumption: string
  afterThawingRelish: string
  preservationMethod: string
  manufacturer: string
  energy: string
  protein: string
  lipid: string
  carbohydrate: string
  saltEquivalent: string
  contamination: string
}

const FONT_SIZE_13_STR_BYTE_LENGTH = 190
const FONT_SIZE_12_STR_BYTE_LENGTH = 260
const FONT_SIZE_11_STR_BYTE_LENGTH = 340

const FoodNutritionLabelTemplate: FC = () => {
  const navigate = useNavigate()
  const search = useLocation().search
  const query = new URLSearchParams(search)
  const dispatch = useDispatch<any>()
  const location = useLocation()
  const path = location.pathname
  const componentRef = useRef<HTMLDivElement>(null)
  dayjs.locale('ja')
  const [page, setPage] = useState(0)
  const [breadName, setBreadName] = useState('')
  const [count, setCount] = useState(0)
  const [labelName, setLabelName] = useState('')
  const [rawMaterialName, setRawMaterialName] = useState('')
  // const [additive, setAdditive] = useState('')
  // const [allergen, setAllergen] = useState('')
  // const [baseAllergen, setBaseAllergen] = useState('')
  // const [additiveAllergen, setAdditiveAllergen] = useState('')
  const [internalCapacityCount, setInternalCapacityCount] = useState('')
  const [internalCapacityUnit, setInternalCapacityUnit] = useState('')
  const [deadlineType, setDeadlineType] = useState('')
  const [deadlineDate, setDeadlineDate] = useState('')
  const [afterThawingDeadlineDate, setAfterThawingDeadlineDate] = useState('')
  const [preservationMethod, setPreservationMethod] = useState('')
  const [otherPreservationMethodText, setOtherPreservationMethodText] =
    useState('')
  const [manufacturer, setManufacturer] = useState('')
  const [nutritionalContent, setNutritionalContent] = useState('')
  const [contamination, setContamination] = useState('')
  const [isPreview, setIsPreview] = useState(false)
  const [backSealInfos, setBackSealInfos] = useState<BackSealInfo[]>([])
  const [printBackSealInfos, setPrintBackSealInfos] = useState<
    BackSealInfo[][]
  >([])
  const [printDisplayBackSealInfos, setPrintDisplayBackSealInfos] = useState<
    BackSealInfo[][]
  >([])
  const [printPlasticMark, setPrintPlasticMark] = useState(false)
  const [printPaperMark, setPrintPaperMark] = useState(false)
  const [printable, setPrintable] = useState(true)
  const [
    showRectangularBreadNotification,
    setShowRectangularBreadNotification,
  ] = useState(false)

  const handlePreview = useCallback(() => {
    setIsPreview(true)
  }, [])
  const handleClosePreview = useCallback(() => {
    setIsPreview(false)
  }, [])

  const handleCount = (count: number) => {
    setCount(count)
    const newBackSealInfos = [...backSealInfos]
    const newPrintBackSealInfos = [...printBackSealInfos]
    newBackSealInfos[page].count = count
    newPrintBackSealInfos[page] = []
    for (let i = 0; i < newBackSealInfos[page].count; i++) {
      newPrintBackSealInfos[page].push(newBackSealInfos[page])
    }
    for (let i = 0; i < newBackSealInfos.length; i++) {
      if (!newPrintBackSealInfos[i]) {
        newPrintBackSealInfos[i] = []
      }
    }
    setBackSealInfos(newBackSealInfos)
    setPrintBackSealInfos(newPrintBackSealInfos)
    const infos: BackSealInfo[][] = []
    let countLoop = 0
    let printable = true

    newPrintBackSealInfos.map((backSealInfos) =>
      backSealInfos.forEach((backSealInfo) => {
        const num = Math.floor(countLoop / 18)
        if (countLoop % 18 === 0) {
          infos.push([])
        }
        infos[num].push(backSealInfo)
        countLoop++
      }),
    )
    newBackSealInfos.forEach((backSealInfo) => {
      // 印刷枚数が0 or 内容量が0のものがある時は印刷できない
      if (
        backSealInfo.count === 0 ||
        Number(backSealInfo.internalCapacityCount) === 0
      ) {
        printable = false
      }
    })
    setPrintable(printable)
    setPrintDisplayBackSealInfos(infos)
  }
  const handleLabelName = (labelName: string) => {
    setLabelName(labelName)
    const newBackSealInfos = [...backSealInfos]
    newBackSealInfos[page].labelName = labelName
    setBackSealInfos(newBackSealInfos)
  }
  const handleRawMaterialName = (rawMaterialName: string) => {
    setRawMaterialName(rawMaterialName)
    const newBackSealInfos = [...backSealInfos]
    newBackSealInfos[page].rawMaterialName = rawMaterialName
    setBackSealInfos(newBackSealInfos)
  }
  // const handleAdditive = (additive: string) => {
  //   setAdditive(additive)
  //   const newBackSealInfos = [...backSealInfos]
  //   newBackSealInfos[page].additive = additive
  //   setBackSealInfos(newBackSealInfos)
  // }
  const handleInternalCapacityCount = (internalCapacityCount: string) => {
    // 半角数字に変換
    internalCapacityCount = internalCapacityCount.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (!Number(internalCapacityCount) && Number(internalCapacityCount) !== 0) {
      return
    }
    setInternalCapacityCount(internalCapacityCount)
    const newBackSealInfos = [...backSealInfos]
    newBackSealInfos[page].internalCapacityCount = internalCapacityCount
    let printable = true
    newBackSealInfos.forEach((backSealInfo) => {
      // 印刷枚数が0 or 内容量が0のものがある時は印刷できない
      if (
        backSealInfo.count === 0 ||
        Number(backSealInfo.internalCapacityCount) === 0
      ) {
        printable = false
      }
    })
    setPrintable(printable)
    setBackSealInfos(newBackSealInfos)
  }
  const handleInternalCapacityUnit = (internalCapacityUnit: string) => {
    setInternalCapacityUnit(internalCapacityUnit)
    const newBackSealInfos = [...backSealInfos]
    newBackSealInfos[page].internalCapacityUnit = internalCapacityUnit
    setBackSealInfos(newBackSealInfos)
  }
  const handleDeadlineType = (deadlineType: string) => {
    if (
      deadlineType === 'afterThawingConsumption' ||
      deadlineType === 'afterThawingRelish'
    ) {
      handlePreservationMethod('frozen')
    } else {
      if (preservationMethod === 'frozen') {
        handlePreservationMethod('avoid_sunlight')
      }
    }
    setDeadlineType(deadlineType)
    const newBackSealInfos = [...backSealInfos]
    newBackSealInfos[page].deadlineType = deadlineType
    setBackSealInfos(newBackSealInfos)
  }
  const handleDeadlineDate = (deadlineDate: string) => {
    setDeadlineDate(deadlineDate)
    const newBackSealInfos = [...backSealInfos]
    newBackSealInfos[page].deadlineDate = deadlineDate
    setBackSealInfos(newBackSealInfos)
  }
  const handleAfterThawingDeadlineDate = (afterThawingDeadlineDate: string) => {
    setAfterThawingDeadlineDate(afterThawingDeadlineDate)
    const newBackSealInfos = [...backSealInfos]
    newBackSealInfos[page].afterThawingDeadlineDate = afterThawingDeadlineDate
    setBackSealInfos(newBackSealInfos)
  }
  const handlePreservationMethod = (preservationMethod: string) => {
    setPreservationMethod(preservationMethod)
    const newBackSealInfos = [...backSealInfos]
    newBackSealInfos[page].preservationMethod = preservationMethod
    setBackSealInfos(newBackSealInfos)
  }
  const handleManufacturer = (manufacturer: string) => {
    setManufacturer(manufacturer)
    const newBackSealInfos = [...backSealInfos]
    newBackSealInfos[page].manufacturer = manufacturer
    setBackSealInfos(newBackSealInfos)
  }
  const handleNutritionalContent = (nutritionalContent: string) => {
    setNutritionalContent(nutritionalContent)
    const newBackSealInfos = [...backSealInfos]
    newBackSealInfos[page].nutritionalContent = nutritionalContent
    setBackSealInfos(newBackSealInfos)
  }
  const handleContamination = (contamination: string) => {
    setContamination(contamination)
    const newBackSealInfos = [...backSealInfos]
    newBackSealInfos[page].contamination = contamination
    setBackSealInfos(newBackSealInfos)
  }

  const getMaterialInfo = useCallback(
    async (materialInfos: MaterialInformationType[]) => {
      const materials: MaterialType[] = []
      await Promise.all(
        materialInfos.map(async (materialInfo) => {
          if (materialInfo.material_ref) {
            const materialId = materialInfo.material_ref.id
              ? materialInfo.material_ref.id
              : // @ts-ignore
                materialInfo.material_ref._key.path.segments[6]
            const materialPayload = await dispatch(
              getMaterialById({ id: materialId }),
            )
            const material: MaterialType = materialPayload.payload
            if (!material) {
              return
            }
            materials.push(material)
          }
          if (materialInfo.dough_ref) {
            const doughId = materialInfo.dough_ref.id
              ? materialInfo.dough_ref.id
              : // @ts-ignore
                materialInfo.dough_ref._key.path.segments[6]
            const doughPayload = await dispatch(getDoughById({ id: doughId }))
            const dough: DoughType = doughPayload.payload
            if (!dough || !dough.material_informations) {
              return
            }
            const doughMaterials = await getMaterialInfo(
              dough.material_informations,
            )
            materials.push(...doughMaterials)
          }
          if (materialInfo.filling_ref) {
            const fillingId = materialInfo.filling_ref.id
              ? materialInfo.filling_ref.id
              : // @ts-ignore
                materialInfo.filling_ref._key.path.segments[6]
            const fillingPayload = await dispatch(
              getFillingById({ id: fillingId }),
            )
            const filling: FillingType = fillingPayload.payload
            if (!filling || !filling.material_informations) {
              return
            }
            const fillingMaterials = await getMaterialInfo(
              filling.material_informations,
            )
            materials.push(...fillingMaterials)
          }
          if (materialInfo.recipe_ref) {
            const recipeId = materialInfo.recipe_ref.id
              ? materialInfo.recipe_ref.id
              : // @ts-ignore
                materialInfo.recipe_ref._key.path.segments[6]
            const recipePayload = await dispatch(
              getRecipeById({ id: recipeId }),
            )
            const recipe: RecipeType = recipePayload.payload
            if (!recipe) {
              return
            }
            if (recipe.dough_material_informations) {
              const recipeDoughMaterials = await getMaterialInfo(
                recipe.dough_material_informations,
              )
              materials.push(...recipeDoughMaterials)
            }
            if (recipe.filling_material_informations) {
              const recipeFillingMaterials = await getMaterialInfo(
                recipe.filling_material_informations,
              )
              materials.push(...recipeFillingMaterials)
            }
            if (recipe.recipe_material_informations) {
              const recipeMaterials = await getMaterialInfo(
                recipe.recipe_material_informations,
              )
              materials.push(...recipeMaterials)
            }
          }
        }),
      )
      return materials
    },
    [],
  )

  const getAllergenText = useCallback((allergenList: string[]) => {
    // const additiveAllergenUniqueList = Array.from(new Set(allergenList))
    let allergenText = ''
    // additiveAllergenUniqueList.map((allergen) => {
    //   if (allergen === Allergens.EGG) {
    //     allergenText += '卵・'
    //   }
    //   if (allergen === Allergens.MILK) {
    //     allergenText += '乳成分・'
    //   }
    //   if (allergen === Allergens.WHEAT) {
    //     allergenText += '小麦粉・'
    //   }
    //   if (allergen === Allergens.SHRIMP) {
    //     allergenText += 'えび・'
    //   }
    //   if (allergen === Allergens.CRUB) {
    //     allergenText += 'かに・'
    //   }
    //   if (allergen === Allergens.PEANUTS) {
    //     allergenText += '落花生・'
    //   }
    //   if (allergen === Allergens.SOBA) {
    //     allergenText += 'そば・'
    //   }
    // })
    if (allergenList.length) {
      allergenText = '（一部に' + allergenList.join('・') + 'を含む）'
    }
    return allergenText
  }, [])

  const { staff } = useSelector((state: RootState) => state.staffSlice)

  const initBreads = useCallback(async () => {
    const breadids: string | null = query.get('breadIds')
    if (!breadids) {
      navigate('/initload')
      window.scrollTo(0, 0)
      return
    }
    if (!staff) {
      const addPath = breadids ? path + '?breadIds=' + breadids : path
      navigate('/initload?path=' + addPath)
      window.scrollTo(0, 0)
      return
    }
    dispatch(setIsLoading({ isLoading: true }))
    // 製造所情報の取得
    const breadIdList: string[] = breadids.split(',')
    const breadPayload = await dispatch(getBreads())
    const breads: BreadType[] = breadPayload.payload
    const filteredBreads: BreadType[] = breads.filter((bread) =>
      breadIdList.includes(bread.id),
    )
    const backSealInfos: BackSealInfo[] = []
    await Promise.all(
      filteredBreads.map(async (bread) => {
        // 原材料小カテゴリを抽出
        const recipePayload = await dispatch(
          getRecipeByBreadId({ id: bread.id }),
        )
        const recipe: RecipeType = recipePayload.payload
        if (!recipe) {
          return
        }
        const infoPayload = await dispatch(
          getMaterialInfomationByBreadId({ breadId: bread.id }),
        )
        const info: BreadDetailInformationType | null = infoPayload.payload
        if (!info) {
          return
        }
        const allergen = getAllergenText(
          Array.from(
            new Set([
              ...info.backSealInformation.baseAllergens,
              ...info.backSealInformation.additiveAllergens,
            ]),
          ),
        )
        const baseAllergen = getAllergenText(
          Array.from(new Set([...info.backSealInformation.baseAllergens])),
        )
        const additiveAllergen = getAllergenText(
          Array.from(new Set([...info.backSealInformation.additiveAllergens])),
        )
        const name = staff.shopName
          ? staff.shopName
          : `${staff.storeName}(${staff.responsiblePersion})`
        const address = staff.manufacturerAddress || staff.storeAddress
        const manufacturer = name + '\n' + address
        const nutritionalContent =
          '熱量:' +
          info.backSealInformation.energy +
          'kcal タンパク質:' +
          info.backSealInformation.protein +
          'g 脂質:' +
          info.backSealInformation.lipid +
          'g 炭水化物:' +
          info.backSealInformation.carbohydrate +
          'g 食塩相当量:' +
          info.backSealInformation.saltEquivalent +
          'g'
        const contamination = bread.contamination ? bread.contamination : ''

        let labelName = ''
        switch (bread.representation_law_name) {
          case BreadRepresentationLawName.RECTANGULAR_BREAD:
            labelName = BreadRepresentationLawNameLabel.RECTANGULAR_BREAD
            setShowRectangularBreadNotification(true)
            break
          case BreadRepresentationLawName.SWEETENED_BUN:
            labelName = BreadRepresentationLawNameLabel.SWEETENED_BUN
            break
          case BreadRepresentationLawName.BREAD:
            labelName = BreadRepresentationLawNameLabel.BREAD
            break
          case BreadRepresentationLawName.BAKED_SWEETS:
            labelName = BreadRepresentationLawNameLabel.BAKED_SWEETS
            break
          case BreadRepresentationLawName.WESTERN_SWEETS:
            labelName = BreadRepresentationLawNameLabel.WESTERN_SWEETS
            break
          case BreadRepresentationLawName.FRESH_WESTERN_SWEETS:
            labelName = BreadRepresentationLawNameLabel.FRESH_WESTERN_SWEETS
            break
          case BreadRepresentationLawName.OTHER:
            labelName = bread.representation_law_name_other
              ? bread.representation_law_name_other
              : ''
            break
          default:
            labelName = ''
        }

        const partition = info.backSealInformation.additives ? ' / ' : ''

        backSealInfos.push({
          breadName: bread.name,
          count: 1,
          labelName: labelName,
          rawMaterialName:
            info.backSealInformation.materialNames +
            partition +
            info.backSealInformation.additives +
            '、' +
            allergen,
          additive: info.backSealInformation.additives,
          allergen: allergen,
          baseAllergen: baseAllergen,
          additiveAllergen: additiveAllergen,
          internalCapacityCount: bread.internal_capacity_count
            ? String(bread.internal_capacity_count)
            : '1',
          internalCapacityUnit: bread.internal_capacity_unit
            ? String(bread.internal_capacity_unit)
            : '個',
          deadlineType:
            bread.preservation_method === 'frozen'
              ? 'afterThawingConsumption'
              : bread.is_consumption_period
              ? 'consumption'
              : 'relish',
          deadlineDate: dayjs()
            .add(bread.period_date, 'd')
            .format('YYYY年M月D日')
            .toString(),
          afterThawingDeadlineDate: dayjs()
            .add(
              bread.period_date_after_thawing
                ? bread.period_date_after_thawing
                : bread.period_date,
              'd',
            )
            .format('YYYY年M月D日')
            .toString(),
          preservationMethod: bread.preservation_method,
          manufacturer: manufacturer,
          nutritionalContent: nutritionalContent,
          energy: info.backSealInformation.energy,
          protein: info.backSealInformation.protein,
          lipid: info.backSealInformation.lipid,
          carbohydrate: info.backSealInformation.carbohydrate,
          saltEquivalent: info.backSealInformation.saltEquivalent,
          contamination: contamination,
        })
      }),
    )
    // 名前順に並べ替える
    backSealInfos.sort((first, second) => {
      if (first.breadName > second.breadName) {
        return 1
      } else if (first.breadName < second.breadName) {
        return -1
      } else {
        return 0
      }
    })
    setBackSealInfos(backSealInfos)
    const printDisplayBackSealInfos: BackSealInfo[][] = []
    backSealInfos.forEach((info) => {
      printDisplayBackSealInfos.push([info])
    })
    setPrintBackSealInfos(printDisplayBackSealInfos)
    setPrintDisplayBackSealInfos([backSealInfos])

    setBreadName(backSealInfos[0].breadName)
    setCount(backSealInfos[0].count)
    setLabelName(backSealInfos[0].labelName)
    setRawMaterialName(backSealInfos[0].rawMaterialName)
    // setAdditive(backSealInfos[0].additive)
    // setAllergen(backSealInfos[0].allergen)
    // setBaseAllergen(backSealInfos[0].baseAllergen)
    // setAdditiveAllergen(backSealInfos[0].additiveAllergen)
    setInternalCapacityCount(backSealInfos[0].internalCapacityCount)
    setInternalCapacityUnit(backSealInfos[0].internalCapacityUnit)
    setDeadlineType(backSealInfos[0].deadlineType)
    setDeadlineDate(backSealInfos[0].deadlineDate)
    setAfterThawingDeadlineDate(backSealInfos[0].afterThawingDeadlineDate)
    setPreservationMethod(backSealInfos[0].preservationMethod)
    setManufacturer(backSealInfos[0].manufacturer)
    setNutritionalContent(backSealInfos[0].nutritionalContent)
    setContamination(backSealInfos[0].contamination)
    dispatch(setIsLoading({ isLoading: false }))
  }, [])

  const setBreads = useCallback(
    (page: number) => {
      if (backSealInfos.length !== 0) {
        setBreadName(backSealInfos[page].breadName)
        setCount(backSealInfos[page].count)
        setLabelName(backSealInfos[page].labelName)
        setRawMaterialName(backSealInfos[page].rawMaterialName)
        // setAdditive(backSealInfos[page].additive)
        setInternalCapacityCount(backSealInfos[page].internalCapacityCount)
        setInternalCapacityUnit(backSealInfos[page].internalCapacityUnit)
        setDeadlineType(backSealInfos[page].deadlineType)
        setDeadlineDate(backSealInfos[page].deadlineDate)
        setAfterThawingDeadlineDate(
          backSealInfos[page].afterThawingDeadlineDate,
        )
        setPreservationMethod(backSealInfos[page].preservationMethod)
        setManufacturer(backSealInfos[page].manufacturer)
        setNutritionalContent(backSealInfos[page].nutritionalContent)
        setContamination(backSealInfos[page].contamination)
      }
    },
    [page],
  )

  useEffect(() => {
    initBreads()
  }, [])

  useEffect(() => {
    setBreads(page)
  }, [page])

  const handlerCsvDownloadButton = async (
    e:
      | MouseEvent<HTMLButtonElement, MouseEvent>
      | MouseEvent<HTMLButtonElement>
      | undefined,
    format: 'xlsx' | 'csv',
  ) => {
    if (!e) {
      return
    }
    e.preventDefault()

    const workbook = new ExcelJS.Workbook()
    workbook.addWorksheet('sheet1')
    const worksheet = workbook.getWorksheet('sheet1')

    worksheet.columns = [
      { header: '商品名', key: 'breadName' },
      { header: '名称', key: 'labelName' },
      { header: '原材料名', key: 'rawMaterialName' },
      { header: '内容量', key: 'internalCapacity' },
      { header: '消費期限', key: 'consumption' },
      { header: '賞味期限', key: 'relish' },
      { header: '冷凍時消費期限', key: 'afterThawingConsumption' },
      { header: '冷凍時賞味期限', key: 'afterThawingRelish' },
      { header: '保存方法', key: 'preservationMethod' },
      { header: '製造者', key: 'manufacturer' },
      { header: 'エネルギー(kcal)', key: 'energy' },
      { header: 'タンパク質(g)', key: 'protein' },
      { header: '脂質(g)', key: 'lipid' },
      { header: '炭水化物(g)', key: 'carbohydrate' },
      { header: '食塩相当量(g)', key: 'saltEquivalent' },
      { header: 'コンタミネーション', key: 'contamination' },
    ]

    const csvRows: CsvOutputBackSealInfo[] = []
    printDisplayBackSealInfos.forEach((backSealInfos) => {
      backSealInfos.forEach((backSealInfo) => {
        csvRows.push({
          breadName: backSealInfo.breadName,
          labelName: backSealInfo.labelName,
          rawMaterialName: backSealInfo.rawMaterialName,
          internalCapacity:
            String(backSealInfo.internalCapacityCount) +
            backSealInfo.internalCapacityUnit,
          consumption:
            backSealInfo.deadlineType === 'consumption'
              ? backSealInfo.deadlineDate
              : '',
          relish:
            backSealInfo.deadlineType === 'relish'
              ? backSealInfo.deadlineDate
              : '',
          afterThawingConsumption:
            backSealInfo.deadlineType === 'afterThawingConsumption'
              ? backSealInfo.afterThawingDeadlineDate
              : '',
          afterThawingRelish:
            backSealInfo.deadlineType === 'afterThawingRelish'
              ? backSealInfo.afterThawingDeadlineDate
              : '',
          preservationMethod:
            backSealInfo.preservationMethod === 'avoid_sunlight'
              ? '直射日光・高温多湿を避けお早目にお召し上がり下さい。'
              : backSealInfo.preservationMethod === 'refrigerated'
              ? '要冷蔵（10℃以下で保存して下さい。）'
              : backSealInfo.preservationMethod === 'frozen'
              ? '要冷凍（-18℃以下で保存して下さい。）'
              : backSealInfo.preservationMethod === 'other' &&
                otherPreservationMethodText
              ? otherPreservationMethodText
              : '',
          manufacturer: backSealInfo.manufacturer,
          energy: backSealInfo.energy,
          protein: backSealInfo.protein,
          lipid: backSealInfo.lipid,
          carbohydrate: backSealInfo.carbohydrate,
          saltEquivalent: backSealInfo.saltEquivalent,
          contamination: backSealInfo.contamination,
        })
      })
    })

    worksheet.addRows(csvRows)

    const uint8Array =
      format === 'xlsx'
        ? await workbook.xlsx.writeBuffer()
        : await workbook.csv.writeBuffer()
    const bom = new Uint8Array([0xef, 0xbb, 0xbf])
    const blob = new Blob([bom, uint8Array], {
      type: 'application/octet-binary',
    })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = '食品栄養表示ラベル.' + format
    a.click()
    a.remove()
  }

  const calcCountStrByte = (str: string) => {
    var length = 0
    for (var i = 0; i < str.length; i++) {
      var c = str.charCodeAt(i)
      if (
        (c >= 0x0 && c < 0x81) ||
        c === 0xf8f0 ||
        (c >= 0xff61 && c < 0xffa0) ||
        (c >= 0xf8f1 && c < 0xf8f4)
      ) {
        length += 1
      } else {
        length += 2
      }
    }
    return length
  }

  const determinePreviewContainerCSS = (
    breadName: string,
    rawMaterialName: string,
    manufacturer: string,
    nutritionalContent: string,
    contamination: string,
  ) => {
    const count =
      calcCountStrByte(breadName) +
      calcCountStrByte(rawMaterialName) +
      calcCountStrByte(manufacturer) +
      calcCountStrByte(nutritionalContent) +
      calcCountStrByte(contamination)
    return count < FONT_SIZE_13_STR_BYTE_LENGTH
      ? styles.food_nutrition_label_preview_container_13
      : count < FONT_SIZE_12_STR_BYTE_LENGTH
      ? styles.food_nutrition_label_preview_container_12
      : count < FONT_SIZE_11_STR_BYTE_LENGTH
      ? styles.food_nutrition_label_preview_container_11
      : styles.food_nutrition_label_preview_container
  }

  const determinePreviewLabelCSS = (
    breadName: string,
    rawMaterialName: string,
    manufacturer: string,
    nutritionalContent: string,
    contamination: string,
  ) => {
    const count =
      calcCountStrByte(breadName) +
      calcCountStrByte(rawMaterialName) +
      calcCountStrByte(manufacturer) +
      calcCountStrByte(nutritionalContent) +
      calcCountStrByte(contamination)
    console.log('determinePreviewLabelCSS')
    console.log(count)
    return count < FONT_SIZE_13_STR_BYTE_LENGTH
      ? styles.food_nutrition_label_preview_label_13
      : count < FONT_SIZE_12_STR_BYTE_LENGTH
      ? styles.food_nutrition_label_preview_label_12
      : count < FONT_SIZE_11_STR_BYTE_LENGTH
      ? styles.food_nutrition_label_preview_label_11
      : styles.food_nutrition_label_preview_label
  }

  const determinePreviewRecycleMarkImageCSS = (
    breadName: string,
    rawMaterialName: string,
    manufacturer: string,
    nutritionalContent: string,
    contamination: string,
  ) => {
    const count =
      calcCountStrByte(breadName) +
      calcCountStrByte(rawMaterialName) +
      calcCountStrByte(manufacturer) +
      calcCountStrByte(nutritionalContent) +
      calcCountStrByte(contamination)
    return count < FONT_SIZE_13_STR_BYTE_LENGTH
      ? styles.food_nutrition_label_preview_content_image_default
      : count < FONT_SIZE_12_STR_BYTE_LENGTH
      ? styles.food_nutrition_label_preview_content_image_default
      : count < FONT_SIZE_11_STR_BYTE_LENGTH
      ? styles.food_nutrition_label_preview_content_image_small
      : styles.food_nutrition_label_preview_content_image_small
  }

  const determinePreviewRecycleMarkMarginCSS = (
    breadName: string,
    rawMaterialName: string,
    manufacturer: string,
    nutritionalContent: string,
    contamination: string,
  ) => {
    const count =
      calcCountStrByte(breadName) +
      calcCountStrByte(rawMaterialName) +
      calcCountStrByte(manufacturer) +
      calcCountStrByte(nutritionalContent) +
      calcCountStrByte(contamination)
    return count < FONT_SIZE_13_STR_BYTE_LENGTH
      ? styles.food_nutrition_label_preview_content_margin_minus_default
      : count < FONT_SIZE_12_STR_BYTE_LENGTH
      ? styles.food_nutrition_label_preview_content_margin_minus_default
      : count < FONT_SIZE_11_STR_BYTE_LENGTH
      ? styles.food_nutrition_label_preview_content_margin_minus_small
      : styles.food_nutrition_label_preview_content_margin_minus_small
  }

  const determinePrintContainerCSS = (
    breadName: string,
    rawMaterialName: string,
    manufacturer: string,
    nutritionalContent: string,
    contamination: string,
  ) => {
    const count =
      calcCountStrByte(breadName) +
      calcCountStrByte(rawMaterialName) +
      calcCountStrByte(manufacturer) +
      calcCountStrByte(nutritionalContent) +
      calcCountStrByte(contamination)
    return count < FONT_SIZE_13_STR_BYTE_LENGTH
      ? styles.food_nutrition_label_print_container_13
      : count < FONT_SIZE_12_STR_BYTE_LENGTH
      ? styles.food_nutrition_label_print_container_12
      : count < FONT_SIZE_11_STR_BYTE_LENGTH
      ? styles.food_nutrition_label_print_container_11
      : styles.food_nutrition_label_print_container
  }

  const determinePrintLabelCSS = (
    breadName: string,
    rawMaterialName: string,
    manufacturer: string,
    nutritionalContent: string,
    contamination: string,
  ) => {
    const count =
      calcCountStrByte(breadName) +
      calcCountStrByte(rawMaterialName) +
      calcCountStrByte(manufacturer) +
      calcCountStrByte(nutritionalContent) +
      calcCountStrByte(contamination)
    return count < FONT_SIZE_13_STR_BYTE_LENGTH
      ? styles.food_nutrition_label_print_label_13
      : count < FONT_SIZE_12_STR_BYTE_LENGTH
      ? styles.food_nutrition_label_print_label_12
      : count < FONT_SIZE_11_STR_BYTE_LENGTH
      ? styles.food_nutrition_label_print_label_11
      : styles.food_nutrition_label_print_label
  }

  const determinePrintRecycleMarkImageCSS = (
    breadName: string,
    rawMaterialName: string,
    manufacturer: string,
    nutritionalContent: string,
    contamination: string,
  ) => {
    const count =
      calcCountStrByte(breadName) +
      calcCountStrByte(rawMaterialName) +
      calcCountStrByte(manufacturer) +
      calcCountStrByte(nutritionalContent) +
      calcCountStrByte(contamination)
    return count < FONT_SIZE_13_STR_BYTE_LENGTH
      ? styles.food_nutrition_label_print_content_image_default
      : count < FONT_SIZE_12_STR_BYTE_LENGTH
      ? styles.food_nutrition_label_print_content_image_default
      : count < FONT_SIZE_11_STR_BYTE_LENGTH
      ? styles.food_nutrition_label_print_content_image_small
      : styles.food_nutrition_label_print_content_image_small
  }

  const determinePrintRecycleMarkMarginCSS = (
    breadName: string,
    rawMaterialName: string,
    manufacturer: string,
    nutritionalContent: string,
    contamination: string,
  ) => {
    const count =
      calcCountStrByte(breadName) +
      calcCountStrByte(rawMaterialName) +
      calcCountStrByte(manufacturer) +
      calcCountStrByte(nutritionalContent) +
      calcCountStrByte(contamination)
    return count < FONT_SIZE_13_STR_BYTE_LENGTH
      ? styles.food_nutrition_label_print_content_margin_minus_default
      : count < FONT_SIZE_12_STR_BYTE_LENGTH
      ? styles.food_nutrition_label_print_content_margin_minus_default
      : count < FONT_SIZE_11_STR_BYTE_LENGTH
      ? styles.food_nutrition_label_print_content_margin_minus_small
      : styles.food_nutrition_label_print_content_margin_minus_small
  }

  return (
    <>
      <div className={styles.container}>
        <PageHedding title="食品栄養表示ラベル" to="/initload" />
        <div
          style={{
            display: 'flex',
            marginTop: 40,
            alignItems: 'center',
            fontSize: 12,
          }}
        >
          {page !== 0 ? (
            <div
              onClick={() => setPage(page - 1)}
              className={styles.food_nutrition_label_arrow_button}
            >
              <img
                style={{ width: 30, height: 30 }}
                src={pageHeddingArrow}
                alt="<"
              />
              <div style={{ marginTop: 16 }}>
                {page}/{backSealInfos.length}
              </div>
            </div>
          ) : (
            <div className={styles.food_nutrition_label_arrow_none} />
          )}
          <div>
            <section
              style={{
                width: 632,
                backgroundColor: '#E2E8F0',
                borderRadius: 2,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  padding: '24px 48px 24px 48px',
                  alignItems: 'center',
                  borderBottom: '2px solid #fff',
                }}
              >
                <div
                  className={styles.food_nutrition_label_text}
                  style={{ marginRight: 52 }}
                >
                  {breadName}
                </div>
                <input
                  className={styles.food_nutrition_label_content_short_input}
                  value={count}
                  onChange={(e) =>
                    !isNaN(Number(e.target.value)) &&
                    handleCount(Number(e.target.value))
                  }
                />
                <div
                  className={styles.food_nutrition_label_text}
                  style={{ marginLeft: 12 }}
                >
                  枚
                </div>
              </div>
              <div style={{ padding: 24 }}>
                <div className={styles.food_nutrition_label_content}>
                  <div className={styles.food_nutrition_label_content_label}>
                    名称
                  </div>
                  <input
                    value={labelName}
                    onChange={(e) => handleLabelName(e.target.value)}
                    className={styles.food_nutrition_label_content_input}
                  />
                </div>
                <div className={styles.food_nutrition_label_content}>
                  <div className={styles.food_nutrition_label_content_label}>
                    原材料名
                  </div>
                  <textarea
                    value={rawMaterialName}
                    onChange={(e) => handleRawMaterialName(e.target.value)}
                    className={styles.food_nutrition_label_content_input}
                    style={{ height: 84 }}
                  />
                </div>
                {/* <div className={styles.food_nutrition_label_content}>
                  <div className={styles.food_nutrition_label_content_label}>
                    添加物
                  </div>
                  <textarea
                    value={additive + additiveAllergen}
                    onChange={(e) => handleAdditive(e.target.value)}
                    className={styles.food_nutrition_label_content_input}
                    style={{ height: 60 }}
                  />
                </div> */}
                <div className={styles.food_nutrition_label_content}>
                  <div className={styles.food_nutrition_label_content_label}>
                    内容量
                  </div>
                  <input
                    className={styles.food_nutrition_label_content_short_input}
                    value={internalCapacityCount}
                    onChange={(e) =>
                      handleInternalCapacityCount(e.target.value)
                    }
                  />
                  <SelectBox
                    style={{
                      width: 90,
                      height: 50,
                      padding: '10px 20px',
                      marginLeft: 14,
                    }}
                    size="large2"
                    value={internalCapacityUnit}
                    options={[
                      {
                        is_default: true,
                        label: '個',
                        value: '個',
                      },
                      {
                        is_default: false,
                        label: 'g',
                        value: 'g',
                      },
                      {
                        is_default: false,
                        label: 'mg',
                        value: 'mg',
                      },
                      {
                        is_default: false,
                        label: '枚',
                        value: '枚',
                      },
                      {
                        is_default: false,
                        label: '斤',
                        value: '斤',
                      },
                    ]}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                      handleInternalCapacityUnit(event.target.value)
                    }
                  />
                </div>
                <div className={styles.food_nutrition_label_content}>
                  <div className={styles.food_nutrition_label_content_label}>
                    期限表示
                  </div>
                  <RadioButton
                    style={{
                      backgroundColor: '#fff',
                      border: 'none',
                      cursor: 'pointer',
                    }}
                    isChecked={deadlineType === 'consumption'}
                    label=""
                    onChange={() => handleDeadlineType('consumption')}
                  />
                  <div className={styles.food_nutrition_label_text}>
                    消費期限
                  </div>
                  <RadioButton
                    style={{
                      backgroundColor: '#fff',
                      border: 'none',
                      cursor: 'pointer',
                      marginLeft: 16,
                    }}
                    isChecked={deadlineType === 'relish'}
                    label=""
                    onChange={() => handleDeadlineType('relish')}
                  />
                  <div className={styles.food_nutrition_label_text}>
                    賞味期限
                  </div>
                  <input
                    className={
                      styles.food_nutrition_label_content_box_with_radio_button
                    }
                    value={deadlineDate}
                    onChange={(e) => handleDeadlineDate(e.target.value)}
                  />
                </div>
                <div className={styles.food_nutrition_label_content}>
                  <div className={styles.food_nutrition_label_content_label}>
                    冷凍時期限表示
                  </div>
                  <RadioButton
                    style={{
                      backgroundColor: '#fff',
                      border: 'none',
                      cursor: 'pointer',
                    }}
                    isChecked={deadlineType === 'afterThawingConsumption'}
                    label=""
                    onChange={() =>
                      handleDeadlineType('afterThawingConsumption')
                    }
                  />
                  <div className={styles.food_nutrition_label_text}>
                    消費期限
                  </div>
                  <RadioButton
                    style={{
                      backgroundColor: '#fff',
                      border: 'none',
                      cursor: 'pointer',
                      marginLeft: 16,
                    }}
                    isChecked={deadlineType === 'afterThawingRelish'}
                    label=""
                    onChange={() => handleDeadlineType('afterThawingRelish')}
                  />
                  <div className={styles.food_nutrition_label_text}>
                    賞味期限
                  </div>
                  <input
                    className={
                      styles.food_nutrition_label_content_box_with_radio_button
                    }
                    value={afterThawingDeadlineDate}
                    onChange={(e) =>
                      handleAfterThawingDeadlineDate(e.target.value)
                    }
                  />
                </div>
                <div className={styles.food_nutrition_label_content}>
                  <div className={styles.food_nutrition_label_content_label}>
                    保存方法
                  </div>
                  <SelectBox
                    style={{ width: 400, height: 50, padding: '10px 20px' }}
                    size="large2"
                    value={preservationMethod}
                    options={
                      deadlineType === 'consumption' ||
                      deadlineType === 'relish'
                        ? [
                            {
                              is_default: false,
                              label:
                                '直射日光・高温多湿を避けお早目にお召し上がり下さい。',
                              value: 'avoid_sunlight',
                            },
                            {
                              is_default: false,
                              label: '要冷蔵（10℃以下で保存して下さい。）',
                              value: 'refrigerated',
                            },
                            {
                              is_default: false,
                              label: 'その他',
                              value: 'other',
                            },
                          ]
                        : [
                            {
                              is_default: false,
                              label: '要冷凍（-18℃以下で保存して下さい。）',
                              value: 'frozen',
                            },
                            {
                              is_default: false,
                              label: 'その他',
                              value: 'other',
                            },
                          ]
                    }
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                      handlePreservationMethod(event.target.value)
                    }
                  />
                </div>
                <div className={styles.food_nutrition_label_content}>
                  {preservationMethod === 'other' ? (
                    <>
                      <div
                        className={styles.food_nutrition_label_content_label}
                      >
                        保存方法(その他)
                      </div>
                      <input
                        value={otherPreservationMethodText}
                        onChange={(e) =>
                          setOtherPreservationMethodText(e.target.value)
                        }
                        className={styles.food_nutrition_label_content_input}
                      />
                    </>
                  ) : (
                    ''
                  )}
                </div>
                <div className={styles.food_nutrition_label_content}>
                  <div className={styles.food_nutrition_label_content_label}>
                    製造者
                  </div>
                  <textarea
                    value={manufacturer}
                    onChange={(e) => handleManufacturer(e.target.value)}
                    className={styles.food_nutrition_label_content_input}
                    style={{ height: 84 }}
                  />
                </div>
                <div className={styles.food_nutrition_label_content}>
                  <div
                    className={styles.food_nutrition_label_content_label}
                    style={{ whiteSpace: 'break-spaces' }}
                  >{`栄養成分表示
100g当たり
（推定値）`}</div>
                  <textarea
                    value={nutritionalContent}
                    onChange={(e) => handleNutritionalContent(e.target.value)}
                    className={styles.food_nutrition_label_content_input}
                    style={{ height: 60 }}
                  />
                </div>
                <div className={styles.food_nutrition_label_content}>
                  <div className={styles.food_nutrition_label_content_label}>
                    コンタミネーション
                  </div>
                  <textarea
                    value={contamination}
                    onChange={(e) => handleContamination(e.target.value)}
                    className={styles.food_nutrition_label_content_input}
                    style={{ height: 84 }}
                  />
                </div>
              </div>
            </section>
            <div className={styles.shopInfoButtonTextContainer}>
              <CheckBoxWithLightLabel
                labelName="リサイクルマーク表示"
                description=""
                line={1}
                checkInfos={[
                  {
                    isChecked: printPlasticMark,
                    label: 'プラスチック',
                    size: 'large',
                    type: 'default',
                    onChange: () => setPrintPlasticMark(!printPlasticMark),
                  },
                  {
                    isChecked: printPaperMark,
                    label: '紙',
                    size: 'large',
                    type: 'default',
                    onChange: () => setPrintPaperMark(!printPaperMark),
                  },
                ]}
              />
            </div>
            <div className={styles.shopInfoButtonTextContainer}>
              {!printable
                ? '印刷枚数か内容量が0の商品があるため印刷できません'
                : ''}
            </div>
            <div className={styles.shopInfoButtonTextUnderContainer}>
              <SubmitButton
                label="戻る"
                color="orange_outlined"
                size="xmiddle"
                icon="none"
                onClick={() => navigate('/initload')}
              />
              <div style={{ marginLeft: 32 }}>
                {printable ? (
                  <SubmitButton
                    label="プレビューをみる"
                    color="orange"
                    size="xmiddle"
                    icon="none"
                    onClick={handlePreview}
                  />
                ) : (
                  <SubmitButton
                    label="プレビューをみる"
                    color="gray"
                    size="xmiddle"
                    icon="none"
                    onClick={() => {}}
                  />
                )}
              </div>
            </div>
          </div>
          {page + 1 !== backSealInfos.length ? (
            <div
              onClick={() => setPage(page + 1)}
              className={styles.food_nutrition_label_arrow_button}
            >
              <img
                style={{ width: 30, height: 30, transform: 'rotate(180deg)' }}
                src={pageHeddingArrow}
                alt=">"
              />
              <div style={{ marginTop: 16 }}>
                {page + 2}/{backSealInfos.length}
              </div>
            </div>
          ) : (
            <div className={styles.food_nutrition_label_arrow_none} />
          )}
        </div>
      </div>
      {isPreview && (
        <div className={styles.modal_price_card}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {page !== 0 ? (
              <div
                style={{ marginRight: 52, cursor: 'pointer' }}
                onClick={() => {
                  setPage(page - 1)
                }}
              >
                <IconArrow reverse />
              </div>
            ) : (
              <div style={{ marginRight: 52, width: 20 }} />
            )}
            {/* 画面に表示するプレビュー */}
            <div
              // ref={componentRef}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div
                className={determinePreviewContainerCSS(
                  breadName,
                  rawMaterialName,
                  manufacturer,
                  nutritionalContent,
                  contamination,
                )}
              >
                <div style={{ fontWeight: 'bold' }}>{breadName}</div>
                <div className={styles.food_nutrition_label_preview_content}>
                  <div
                    className={determinePreviewLabelCSS(
                      breadName,
                      rawMaterialName,
                      manufacturer,
                      nutritionalContent,
                      contamination,
                    )}
                  >
                    名称
                  </div>
                  <div className={styles.food_nutrition_label_preview_value}>
                    {labelName}
                  </div>
                </div>
                <div className={styles.food_nutrition_label_preview_content}>
                  <div
                    className={determinePreviewLabelCSS(
                      breadName,
                      rawMaterialName,
                      manufacturer,
                      nutritionalContent,
                      contamination,
                    )}
                  >
                    原材料名
                  </div>
                  <div className={styles.food_nutrition_label_preview_value}>
                    {rawMaterialName}
                  </div>
                </div>
                <div className={styles.food_nutrition_label_preview_content}>
                  <div
                    className={determinePreviewLabelCSS(
                      breadName,
                      rawMaterialName,
                      manufacturer,
                      nutritionalContent,
                      contamination,
                    )}
                  >
                    内容量
                  </div>
                  <div
                    className={styles.food_nutrition_label_preview_value}
                  >{`${internalCapacityCount}${internalCapacityUnit}`}</div>
                </div>
                <div
                  className={styles.food_nutrition_label_preview_content}
                  style={{ alignItems: 'center' }}
                >
                  <div
                    className={determinePreviewLabelCSS(
                      breadName,
                      rawMaterialName,
                      manufacturer,
                      nutritionalContent,
                      contamination,
                    )}
                  >
                    {deadlineType === 'consumption'
                      ? '消費期限'
                      : deadlineType === 'relish'
                      ? '賞味期限'
                      : deadlineType === 'afterThawingConsumption'
                      ? '冷凍時消費期限'
                      : deadlineType === 'afterThawingRelish'
                      ? '冷凍時賞味期限'
                      : ''}
                  </div>
                  <div className={styles.food_nutrition_label_preview_value}>
                    {deadlineType === 'consumption' || deadlineType === 'relish'
                      ? deadlineDate
                      : afterThawingDeadlineDate}
                  </div>
                </div>
                <div className={styles.food_nutrition_label_preview_content}>
                  <div
                    className={determinePreviewLabelCSS(
                      breadName,
                      rawMaterialName,
                      manufacturer,
                      nutritionalContent,
                      contamination,
                    )}
                  >
                    保存方法
                  </div>
                  <div className={styles.food_nutrition_label_preview_value}>
                    {preservationMethod === 'avoid_sunlight'
                      ? '直射日光・高温多湿を避けお早目にお召し上がり下さい。'
                      : preservationMethod === 'refrigerated'
                      ? '要冷蔵（10℃以下で保存して下さい。）'
                      : preservationMethod === 'frozen'
                      ? '要冷凍（-18℃以下で保存して下さい。）'
                      : preservationMethod === 'other' &&
                        otherPreservationMethodText
                      ? otherPreservationMethodText
                      : ''}
                  </div>
                </div>
                <div className={styles.food_nutrition_label_preview_content}>
                  <div
                    className={determinePreviewLabelCSS(
                      breadName,
                      rawMaterialName,
                      manufacturer,
                      nutritionalContent,
                      contamination,
                    )}
                  >
                    製造者
                  </div>
                  <div className={styles.food_nutrition_label_preview_value}>
                    {manufacturer}
                  </div>
                </div>
                <div className={styles.food_nutrition_label_preview_content}>
                  <div
                    className={determinePreviewLabelCSS(
                      breadName,
                      rawMaterialName,
                      manufacturer,
                      nutritionalContent,
                      contamination,
                    )}
                  >
                    栄養成分表示
                    <br />
                    100g当たり
                    <br />
                    （推定値）
                  </div>
                  <div className={styles.food_nutrition_label_preview_value}>
                    {nutritionalContent}
                  </div>
                </div>
                <div className={styles.food_nutrition_label_preview_content}>
                  {
                    // TODO: 28品目表示について特定店舗のみの対応から、店舗ごとに設定値を持つ実装に切り替える
                  }
                  {process.env.REACT_APP_NODE_ENV === 'development' ||
                  (process.env.REACT_APP_NODE_ENV === 'production' &&
                    ['LmrupsmUA1TCNvU8JnZJ', 'CEjRQ2AiDJMXZVK3kExF'].includes(
                      staff && staff.shopId ? staff.shopId : '',
                    )) ? (
                    ''
                  ) : (
                    <div>※アレルゲンは義務8品目を対象範囲としています。</div>
                  )}
                </div>
                {contamination.split(/\r?\n/).map((c) => (
                  <div className={styles.food_nutrition_label_preview_content}>
                    <div>{c}</div>
                  </div>
                ))}
                {(printPaperMark ||
                  printPlasticMark ||
                  showRectangularBreadNotification) && (
                  <div
                    className={
                      styles.food_nutrition_label_preview_content_between
                    }
                  >
                    {showRectangularBreadNotification ? (
                      <div>※1斤は340g以上です。</div>
                    ) : (
                      <div></div>
                    )}
                    {(printPaperMark || printPlasticMark) && (
                      <div
                        className={determinePreviewRecycleMarkMarginCSS(
                          breadName,
                          rawMaterialName,
                          manufacturer,
                          nutritionalContent,
                          contamination,
                        )}
                      >
                        {printPaperMark ? (
                          <div>
                            <img
                              className={determinePreviewRecycleMarkImageCSS(
                                breadName,
                                rawMaterialName,
                                manufacturer,
                                nutritionalContent,
                                contamination,
                              )}
                              src={recycleMarkPaper}
                              alt="紙"
                            ></img>
                          </div>
                        ) : (
                          ''
                        )}
                        {printPlasticMark ? (
                          <div>
                            <img
                              className={determinePreviewRecycleMarkImageCSS(
                                breadName,
                                rawMaterialName,
                                manufacturer,
                                nutritionalContent,
                                contamination,
                              )}
                              src={recycleMarkPlastic}
                              alt="プラ"
                            ></img>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            {/* 実際に印刷するDOM */}
            <div style={{ display: 'none' }}>
              <div
                ref={componentRef}
                className={styles.food_nutrition_label_print_all_container}
              >
                {printDisplayBackSealInfos.map((backSealInfos) => (
                  <section className={styles.food_nutrition_label_print_sheet}>
                    <div className={styles.food_nutrition_label_print_wrap}>
                      {backSealInfos.map((backSealInfo) => (
                        <div
                          className={determinePrintContainerCSS(
                            backSealInfo.breadName,
                            backSealInfo.rawMaterialName,
                            backSealInfo.manufacturer,
                            backSealInfo.nutritionalContent,
                            backSealInfo.contamination,
                          )}
                        >
                          <div style={{ fontWeight: 'bold' }}>
                            {backSealInfo.breadName}
                          </div>
                          <div
                            className={
                              styles.food_nutrition_label_print_content
                            }
                          >
                            <div
                              className={determinePrintLabelCSS(
                                backSealInfo.breadName,
                                backSealInfo.rawMaterialName,
                                backSealInfo.manufacturer,
                                backSealInfo.nutritionalContent,
                                backSealInfo.contamination,
                              )}
                            >
                              名称
                            </div>
                            <div
                              className={
                                styles.food_nutrition_label_print_value
                              }
                            >
                              {backSealInfo.labelName}
                            </div>
                          </div>
                          <div
                            className={
                              styles.food_nutrition_label_print_content
                            }
                          >
                            <div
                              className={determinePrintLabelCSS(
                                backSealInfo.breadName,
                                backSealInfo.rawMaterialName,
                                backSealInfo.manufacturer,
                                backSealInfo.nutritionalContent,
                                backSealInfo.contamination,
                              )}
                            >
                              原材料名
                            </div>
                            <div
                              className={
                                styles.food_nutrition_label_print_value
                              }
                            >
                              {backSealInfo.rawMaterialName}
                            </div>
                          </div>
                          <div
                            className={
                              styles.food_nutrition_label_print_content
                            }
                          >
                            <div
                              className={determinePrintLabelCSS(
                                backSealInfo.breadName,
                                backSealInfo.rawMaterialName,
                                backSealInfo.manufacturer,
                                backSealInfo.nutritionalContent,
                                backSealInfo.contamination,
                              )}
                            >
                              内容量
                            </div>
                            <div
                              className={
                                styles.food_nutrition_label_print_value
                              }
                            >{`${backSealInfo.internalCapacityCount}${backSealInfo.internalCapacityUnit}`}</div>
                          </div>
                          <div
                            className={
                              styles.food_nutrition_label_print_content
                            }
                            style={{ alignItems: 'center' }}
                          >
                            <div
                              className={determinePrintLabelCSS(
                                backSealInfo.breadName,
                                backSealInfo.rawMaterialName,
                                backSealInfo.manufacturer,
                                backSealInfo.nutritionalContent,
                                backSealInfo.contamination,
                              )}
                            >
                              {backSealInfo.deadlineType === 'consumption'
                                ? '消費期限'
                                : backSealInfo.deadlineType === 'relish'
                                ? '賞味期限'
                                : backSealInfo.deadlineType ===
                                  'afterThawingConsumption'
                                ? '冷凍時消費期限'
                                : backSealInfo.deadlineType ===
                                  'afterThawingRelish'
                                ? '冷凍時賞味期限'
                                : ''}
                            </div>
                            <div
                              className={
                                styles.food_nutrition_label_print_value
                              }
                            >
                              {backSealInfo.deadlineType === 'consumption' ||
                              backSealInfo.deadlineType === 'relish'
                                ? backSealInfo.deadlineDate
                                : backSealInfo.afterThawingDeadlineDate}
                            </div>
                          </div>
                          <div
                            className={
                              styles.food_nutrition_label_print_content
                            }
                          >
                            <div
                              className={determinePrintLabelCSS(
                                backSealInfo.breadName,
                                backSealInfo.rawMaterialName,
                                backSealInfo.manufacturer,
                                backSealInfo.nutritionalContent,
                                backSealInfo.contamination,
                              )}
                            >
                              保存方法
                            </div>
                            <div
                              className={
                                styles.food_nutrition_label_print_value
                              }
                            >
                              {backSealInfo.preservationMethod ===
                              'avoid_sunlight'
                                ? '直射日光・高温多湿を避けお早目にお召し上がり下さい。'
                                : backSealInfo.preservationMethod ===
                                  'refrigerated'
                                ? '要冷蔵（10℃以下で保存して下さい。）'
                                : backSealInfo.preservationMethod === 'frozen'
                                ? '要冷凍（-18℃以下で保存して下さい。）'
                                : backSealInfo.preservationMethod === 'other' &&
                                  otherPreservationMethodText
                                ? otherPreservationMethodText
                                : ''}
                            </div>
                          </div>
                          <div
                            className={
                              styles.food_nutrition_label_print_content
                            }
                          >
                            <div
                              className={determinePrintLabelCSS(
                                backSealInfo.breadName,
                                backSealInfo.rawMaterialName,
                                backSealInfo.manufacturer,
                                backSealInfo.nutritionalContent,
                                backSealInfo.contamination,
                              )}
                            >
                              製造者
                            </div>
                            <div
                              className={
                                styles.food_nutrition_label_print_value
                              }
                            >
                              {backSealInfo.manufacturer}
                            </div>
                          </div>
                          <div
                            className={
                              styles.food_nutrition_label_print_content
                            }
                          >
                            <div
                              className={determinePrintLabelCSS(
                                backSealInfo.breadName,
                                backSealInfo.rawMaterialName,
                                backSealInfo.manufacturer,
                                backSealInfo.nutritionalContent,
                                backSealInfo.contamination,
                              )}
                            >
                              栄養成分表示
                              <br />
                              100g当たり
                              <br />
                              （推定値）
                            </div>
                            <div
                              className={
                                styles.food_nutrition_label_print_value
                              }
                            >
                              {backSealInfo.nutritionalContent}
                            </div>
                          </div>
                          <div
                            className={
                              styles.food_nutrition_label_print_content
                            }
                          >
                            {
                              // TODO: 28品目表示について特定店舗のみの対応から、店舗ごとに設定値を持つ実装に切り替える
                            }
                            {process.env.REACT_APP_NODE_ENV === 'development' ||
                            (process.env.REACT_APP_NODE_ENV === 'production' &&
                              [
                                'LmrupsmUA1TCNvU8JnZJ',
                                'CEjRQ2AiDJMXZVK3kExF',
                              ].includes(
                                staff && staff.shopId ? staff.shopId : '',
                              )) ? (
                              ''
                            ) : (
                              <div>
                                ※アレルゲンは義務8品目を対象範囲としています。
                              </div>
                            )}
                          </div>
                          {backSealInfo.contamination
                            .split(/\r?\n/)
                            .map((c) => (
                              <div
                                className={
                                  styles.food_nutrition_label_print_content
                                }
                              >
                                <div>{c}</div>
                              </div>
                            ))}

                          {(printPaperMark ||
                            printPlasticMark ||
                            showRectangularBreadNotification) && (
                            <div
                              className={
                                styles.food_nutrition_label_print_content_between
                              }
                            >
                              {showRectangularBreadNotification ? (
                                <div>※1斤は340g以上です。</div>
                              ) : (
                                <div></div>
                              )}
                              {(printPaperMark || printPlasticMark) && (
                                <div
                                  className={determinePrintRecycleMarkMarginCSS(
                                    breadName,
                                    rawMaterialName,
                                    manufacturer,
                                    nutritionalContent,
                                    contamination,
                                  )}
                                >
                                  {printPaperMark ? (
                                    <div>
                                      <img
                                        className={determinePrintRecycleMarkImageCSS(
                                          breadName,
                                          rawMaterialName,
                                          manufacturer,
                                          nutritionalContent,
                                          contamination,
                                        )}
                                        src={recycleMarkPaper}
                                        alt="紙"
                                      ></img>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  {printPlasticMark ? (
                                    <div>
                                      <img
                                        className={determinePrintRecycleMarkImageCSS(
                                          breadName,
                                          rawMaterialName,
                                          manufacturer,
                                          nutritionalContent,
                                          contamination,
                                        )}
                                        src={recycleMarkPlastic}
                                        alt="プラ"
                                      ></img>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                      ,
                    </div>
                  </section>
                ))}
              </div>
            </div>
            {page + 1 !== backSealInfos.length ? (
              <div
                style={{ marginLeft: 52, cursor: 'pointer' }}
                onClick={() => {
                  setPage(page + 1)
                }}
              >
                <IconArrow />
              </div>
            ) : (
              <div style={{ marginLeft: 52, width: 20 }} />
            )}
          </div>

          <div className={styles.food_nutrition_label_preview_text_container}>
            <div className={styles.food_nutrition_label_preview_text}>
              ※印刷にはスリーエムジャパン株式会社の以下の用紙をご使用ください。
            </div>
            <div className={styles.food_nutrition_label_preview_text}>
              A-oneシリーズの品番28390：ラベルシール [レーザープリンタ]
            </div>
            <div className={styles.food_nutrition_label_preview_text}>
              A-oneシリーズの品番28857：ラベルシール [インクジェット]
            </div>
            <div className={styles.food_nutrition_label_preview_text}>
              A-oneシリーズの品番31507：ラベルシール [プリンタ兼用]
            </div>
          </div>

          <div className={styles.shopInfoButtonContainer}>
            <SubmitButton
              label="閉じる"
              color="orange_outlined"
              size="xmiddle"
              icon="none"
              onClick={handleClosePreview}
            />
            <div style={{ marginLeft: 32 }}>
              <SubmitButton
                label="CSV形式で書き出す"
                color="orange"
                size="xmiddle"
                icon="none"
                onClick={(e) => handlerCsvDownloadButton(e, 'csv')}
              />
            </div>
            <div style={{ marginLeft: 32 }}>
              <ReactToPrint
                trigger={() => (
                  <SubmitButton
                    label="印刷する"
                    color="orange"
                    size="xmiddle"
                    icon="none"
                    onClick={() => {}}
                  />
                )}
                pageStyle="@page { size: A3 landscape; margin: 0; }"
                content={() => componentRef.current}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

// type Props = {
//   backSealInfos: BackSealInfo[]
// }

// const PrintPreview: FC<Props> = (props) => {
//   const { backSealInfos } = props
//   return (
//     <div>
//       {backSealInfos.map((backSealInfo) => (
//         <div>
//           <div>{backSealInfo.breadName}</div>
//           <div>{backSealInfo.count}</div>
//           <div>{backSealInfo.labelName}</div>
//           <div>{backSealInfo.manufacturer}</div>
//           <div>{backSealInfo.rawMaterialName}</div>
//           <div>{backSealInfo.additive}</div>
//         </div>
//       ))}
//     </div>
//   )
// }

export default FoodNutritionLabelTemplate
