import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  Slice,
} from '@reduxjs/toolkit'
import { db } from 'fb/index'
import {
  collection,
  query,
  getDocs,
  where,
  doc,
  updateDoc,
  getDoc,
  DocumentReference,
} from 'firebase/firestore'
import {
  BackingStickerStatus,
  BreadStatus,
  Allergens,
} from 'reducks/bread/slice'
import { DoughType } from 'reducks/dough/slice'
import { FillingType } from 'reducks/filling/slice'
import { AdditiveType } from 'reducks/material/slice'
import { RecipeType, MaterialInformationType } from 'reducks/recipe/slice'
import { MaterialCostType } from 'reducks/materialCost/slice'
import { AppDispatch } from 'index'
import { RootState } from 'reducks/reducers'

interface BusinessState {
  businesses: Business[]
  ngBreadIdList: string[]
}
interface Business {
  name: string
}

export enum DataType {
  MATERIAL = 'material',
  DOUGH = 'dough',
  FILLING = 'filling',
  RECIPE = 'recipe',
}

export enum UnitType {
  PERCENT = '%',
  GRAM = 'g',
}

export type BusinessType = Business

export interface UsedData {
  id: string
  breadIdList: string[]
}

const initialState = {
  businesses: [],
  ngBreadIdList: [],
} as BusinessState

export const getTopBreadIdsRecursivelyById = createAsyncThunk<
  string[],
  { id: string; dataType: DataType }
>('business/getTopBreadIdsRecursivelyById', async ({ id, dataType }) => {
  const fillings: FillingType[] = []
  const fillingsQuery = query(collection(db, 'fillings'))
  const fillingsQuerySnapshot = await getDocs(fillingsQuery)
  await Promise.all(
    fillingsQuerySnapshot.docs.map(async (doc) => {
      const filling = doc.data()
      fillings.push({
        id: doc.id,
        pettern_name: filling.pettern_name,
        material_informations: filling.material_informations,
        finished_weight: filling.finished_weight,
        process: filling.process,
        memo: filling.memo,
        is_disabled: filling.is_disabled,
        is_percent: filling.is_percent,
        is_gram: filling.is_gram,
        shop_ref: filling.shop_ref,
        created_at: filling.created_at,
      })
    }),
  )
  const doughs: DoughType[] = []
  const doughsQuery = query(collection(db, 'doughs'))
  const doughsQuerySnapshot = await getDocs(doughsQuery)
  await Promise.all(
    doughsQuerySnapshot.docs.map(async (doc) => {
      const dough = doc.data()
      doughs.push({
        id: doc.id,
        pettern_name: dough.pettern_name,
        material_informations: dough.material_informations,
        process: dough.process,
        memo: dough.memo,
        is_disabled: dough.is_disabled,
        is_percent: dough.is_percent,
        is_gram: dough.is_gram,
        shop_ref: dough.shop_ref,
        created_at: dough.created_at,
      })
    }),
  )
  const recipes: RecipeType[] = []
  const recipesQuery = query(collection(db, 'recipes'))
  const recipesQuerySnapshot = await getDocs(recipesQuery)
  await Promise.all(
    recipesQuerySnapshot.docs.map(async (doc) => {
      const recipe = doc.data()
      recipes.push({
        id: doc.id,
        dough_material_informations: recipe.dough_material_informations,
        filling_material_informations: recipe.filling_material_informations,
        recipe_material_informations: recipe.recipe_material_informations,
        amount_dough_before_baking: recipe.amount_dough_before_baking,
        loss_rate: recipe.loss_rate,
        burnout_rate: recipe.burnout_rate,
        amount_dough_after_baking: recipe.amount_dough_after_baking,
        is_after_baking_percent: recipe.is_after_baking_percent,
        is_after_baking_gram: recipe.is_after_baking_gram,
        memo: recipe.memo,
        process: recipe.process,
        is_percent: recipe.is_percent,
        is_gram: recipe.is_gram,
        bread_ref: recipe.bread_ref,
        recipe_name: recipe.recipe_name,
        is_draft: recipe.is_draft,
        shop_ref: recipe.shop_ref,
        created_at: recipe.created_at,
      })
    }),
  )

  const breadIdList = getBreadIdsRecursive(
    id,
    dataType,
    doughs,
    fillings,
    recipes,
  )

  return Array.from(new Set(breadIdList))
})

export const getUsedBreadIdsRecursivelyByIds = createAsyncThunk<
  UsedData[],
  { ids: string[]; dataType: DataType },
  {
    dispatch: AppDispatch
    state: RootState
  }
>(
  'business/getUsedBreadIdsRecursivelyByIds',
  async ({ ids, dataType }, thunkApi) => {
    const { staff } = thunkApi.getState().staffSlice
    if (!staff || !staff.shopId) {
      return []
    }
    const fillings: FillingType[] = []
    const fillingsQuery = query(
      collection(db, 'fillings'),
      where('shop_ref', '==', doc(db, 'shops', staff.shopId)),
      where('is_disabled', '==', false),
    )
    const fillingsQuerySnapshot = await getDocs(fillingsQuery)
    await Promise.all(
      fillingsQuerySnapshot.docs.map(async (doc) => {
        const filling = doc.data()
        fillings.push({
          id: doc.id,
          pettern_name: filling.pettern_name,
          material_informations: filling.material_informations,
          finished_weight: filling.finished_weight,
          process: filling.process,
          memo: filling.memo,
          is_disabled: filling.is_disabled,
          is_percent: filling.is_percent,
          is_gram: filling.is_gram,
          shop_ref: filling.shop_ref,
          created_at: filling.created_at,
        })
      }),
    )
    const doughs: DoughType[] = []
    const doughsQuery = query(
      collection(db, 'doughs'),
      where('shop_ref', '==', doc(db, 'shops', staff.shopId)),
      where('is_disabled', '==', false),
    )
    const doughsQuerySnapshot = await getDocs(doughsQuery)
    await Promise.all(
      doughsQuerySnapshot.docs.map(async (doc) => {
        const dough = doc.data()
        doughs.push({
          id: doc.id,
          pettern_name: dough.pettern_name,
          material_informations: dough.material_informations,
          process: dough.process,
          memo: dough.memo,
          is_disabled: dough.is_disabled,
          is_percent: dough.is_percent,
          is_gram: dough.is_gram,
          shop_ref: dough.shop_ref,
          created_at: dough.created_at,
        })
      }),
    )
    const recipes: RecipeType[] = []
    const recipesQuery = query(
      collection(db, 'recipes'),
      where('shop_ref', '==', doc(db, 'shops', staff.shopId)),
    )
    const recipesQuerySnapshot = await getDocs(recipesQuery)
    await Promise.all(
      recipesQuerySnapshot.docs.map(async (document) => {
        const recipe = document.data()
        // 削除ステータスの商品は計算処理に用いない
        const breadQuery = doc(db, 'breads', recipe.bread_ref.id)
        const breadQuerySnapshot = await getDoc(breadQuery)
        const bread = breadQuerySnapshot.data()
        if (!bread || bread.status === BreadStatus.DELETE) {
          return
        }
        recipes.push({
          id: document.id,
          dough_material_informations: recipe.dough_material_informations,
          filling_material_informations: recipe.filling_material_informations,
          recipe_material_informations: recipe.recipe_material_informations,
          amount_dough_before_baking: recipe.amount_dough_before_baking,
          loss_rate: recipe.loss_rate,
          burnout_rate: recipe.burnout_rate,
          amount_dough_after_baking: recipe.amount_dough_after_baking,
          is_after_baking_percent: recipe.is_after_baking_percent,
          is_after_baking_gram: recipe.is_after_baking_gram,
          memo: recipe.memo,
          process: recipe.process,
          is_percent: recipe.is_percent,
          is_gram: recipe.is_gram,
          bread_ref: recipe.bread_ref,
          recipe_name: recipe.recipe_name,
          is_draft: recipe.is_draft,
          shop_ref: recipe.shop_ref,
          created_at: recipe.created_at,
        })
      }),
    )

    const response: UsedData[] = []
    ids.forEach((id) => {
      const breadIdList = getBreadIdsRecursive(
        id,
        dataType,
        doughs,
        fillings,
        recipes,
      )
      response.push({ id: id, breadIdList: breadIdList })
    })

    return response
  },
)

const getBreadIdsRecursive = (
  id: string,
  dataType: string,
  doughs: DoughType[],
  fillings: FillingType[],
  recipes: RecipeType[],
) => {
  const breadIdList: string[] = []
  fillings.forEach((filling) => {
    if (
      filling.material_informations &&
      filling.material_informations.length > 0
    ) {
      filling.material_informations.forEach((material_information) => {
        if (
          (dataType === DataType.MATERIAL &&
            material_information.material_ref &&
            material_information.material_ref.id === id) ||
          (dataType === DataType.FILLING &&
            material_information.filling_ref &&
            material_information.filling_ref.id === id) ||
          (dataType === DataType.DOUGH &&
            material_information.dough_ref &&
            material_information.dough_ref.id === id)
        ) {
          const ids = getBreadIdsRecursive(
            filling.id,
            DataType.FILLING,
            doughs,
            fillings,
            recipes,
          )
          breadIdList.push(...ids)
        }
      })
    }
  })
  doughs.forEach((dough) => {
    if (dough.material_informations && dough.material_informations.length > 0) {
      dough.material_informations.forEach((material_information) => {
        if (
          (dataType === DataType.MATERIAL &&
            material_information.material_ref &&
            material_information.material_ref.id === id) ||
          (dataType === DataType.FILLING &&
            material_information.filling_ref &&
            material_information.filling_ref.id === id) ||
          (dataType === DataType.DOUGH &&
            material_information.dough_ref &&
            material_information.dough_ref.id === id)
        ) {
          const ids = getBreadIdsRecursive(
            dough.id,
            DataType.DOUGH,
            doughs,
            fillings,
            recipes,
          )
          breadIdList.push(...ids)
        }
      })
    }
  })
  recipes.forEach((recipe) => {
    if (
      recipe.dough_material_informations &&
      recipe.dough_material_informations.length > 0
    ) {
      recipe.dough_material_informations.forEach((material_information) => {
        if (
          (dataType === DataType.MATERIAL &&
            material_information.material_ref &&
            material_information.material_ref.id === id) ||
          (dataType === DataType.FILLING &&
            material_information.filling_ref &&
            material_information.filling_ref.id === id) ||
          (dataType === DataType.DOUGH &&
            material_information.dough_ref &&
            material_information.dough_ref.id === id) ||
          (dataType === DataType.RECIPE &&
            material_information.recipe_ref &&
            material_information.recipe_ref.id === id)
        ) {
          const ids = getBreadIdsRecursive(
            recipe.id,
            DataType.RECIPE,
            doughs,
            fillings,
            recipes,
          )
          breadIdList.push(...ids)
          breadIdList.push(recipe.bread_ref.id)
        }
      })
    }
    if (
      recipe.filling_material_informations &&
      recipe.filling_material_informations.length > 0
    ) {
      recipe.filling_material_informations.forEach((material_information) => {
        if (
          (dataType === DataType.MATERIAL &&
            material_information.material_ref &&
            material_information.material_ref.id === id) ||
          (dataType === DataType.FILLING &&
            material_information.filling_ref &&
            material_information.filling_ref.id === id) ||
          (dataType === DataType.DOUGH &&
            material_information.dough_ref &&
            material_information.dough_ref.id === id) ||
          (dataType === DataType.RECIPE &&
            material_information.recipe_ref &&
            material_information.recipe_ref.id === id)
        ) {
          const ids = getBreadIdsRecursive(
            recipe.id,
            DataType.RECIPE,
            doughs,
            fillings,
            recipes,
          )
          breadIdList.push(...ids)
          breadIdList.push(recipe.bread_ref.id)
        }
      })
    }
    if (
      recipe.recipe_material_informations &&
      recipe.recipe_material_informations.length > 0
    ) {
      recipe.recipe_material_informations.forEach((material_information) => {
        if (
          (dataType === DataType.MATERIAL &&
            material_information.material_ref &&
            material_information.material_ref.id === id) ||
          (dataType === DataType.FILLING &&
            material_information.filling_ref &&
            material_information.filling_ref.id === id) ||
          (dataType === DataType.DOUGH &&
            material_information.dough_ref &&
            material_information.dough_ref.id === id) ||
          (dataType === DataType.RECIPE &&
            material_information.recipe_ref &&
            material_information.recipe_ref.id === id)
        ) {
          const ids = getBreadIdsRecursive(
            recipe.id,
            DataType.RECIPE,
            doughs,
            fillings,
            recipes,
          )
          breadIdList.push(...ids)
          breadIdList.push(recipe.bread_ref.id)
        }
      })
    }
  })
  return breadIdList
}

export const setBreadBackSealStatusByBreadId = createAsyncThunk<
  void,
  { breadId: string }
>('business/setBreadBackSealStatusByBreadId', async ({ breadId }) => {
  let isNg: boolean = false
  let isAnalysis: boolean = false
  const q = query(
    collection(db, 'recipes'),
    where('bread_ref', '==', doc(db, 'breads', breadId)),
  )
  const querySnapshot = await getDocs(q)
  const recipe = querySnapshot.docs[0].data()
  if (!recipe) {
    // レシピがなければNGステータスで更新
    isNg = true
    return
  }

  const checkMaterialInformation = async (
    material_information: MaterialInformationType,
  ) => {
    if (material_information.material_ref) {
      const q = doc(db, 'materials', material_information.material_ref.id)
      const querySnapshot = await getDoc(q)
      const material = querySnapshot.data()
      if (!material) {
        // 原材料がなければNGステータスで更新
        isNg = true
      }
    } else if (material_information.dough_ref) {
      const q = doc(db, 'doughs', material_information.dough_ref.id)
      const querySnapshot = await getDoc(q)
      const dough = querySnapshot.data()
      if (!dough || dough.is_disabled || !dough.material_informations) {
        // 生地パターンがない、もしくは使用不可であればNGステータスで更新
        isNg = true
        return
      }
      await Promise.all(
        dough.material_informations.map(
          async (material_information: MaterialInformationType) => {
            await checkMaterialInformation(material_information)
          },
        ),
      )
    } else if (material_information.filling_ref) {
      const q = doc(db, 'fillings', material_information.filling_ref.id)
      const querySnapshot = await getDoc(q)
      const filling = querySnapshot.data()
      if (!filling || filling.is_disabled || !filling.material_informations) {
        // フィリングパターンがない、もしくは使用不可であればNGステータスで更新
        isNg = true
        return
      }
      await Promise.all(
        filling.material_informations.map(
          async (material_information: MaterialInformationType) => {
            await checkMaterialInformation(material_information)
          },
        ),
      )
    } else if (material_information.recipe_ref) {
      const q = doc(db, 'recipes', material_information.recipe_ref.id)
      const querySnapshot = await getDoc(q)
      const recipe = querySnapshot.data()
      if (!recipe) {
        // レシピがない場合NGステータスで更新
        isNg = true
        return
      }
      const breadQuery = doc(db, 'breads', recipe.bread_ref.id)
      const breadQuerySnapshot = await getDoc(breadQuery)
      const bread = breadQuerySnapshot.data()
      if (
        !bread ||
        bread.status === BreadStatus.DELETE ||
        bread.daseruno_status === BreadStatus.DRAFT ||
        bread.daseruno_recipe_status === BreadStatus.DRAFT
      ) {
        // 商品がない、もしくは下書き状態であればNGステータスで更新
        isNg = true
        return
      }
      if (
        recipe.dough_material_informations &&
        recipe.dough_material_informations.length > 0
      ) {
        await Promise.all(
          recipe.dough_material_informations.map(
            async (material_information: MaterialInformationType) => {
              await checkMaterialInformation(material_information)
            },
          ),
        )
      }
      if (
        recipe.filling_material_informations &&
        recipe.filling_material_informations.length > 0
      ) {
        await Promise.all(
          recipe.filling_material_informations.map(
            async (material_information: MaterialInformationType) => {
              await checkMaterialInformation(material_information)
            },
          ),
        )
      }
      if (
        recipe.recipe_material_informations &&
        recipe.recipe_material_informations.length > 0
      ) {
        await Promise.all(
          recipe.recipe_material_informations.map(
            async (material_information: MaterialInformationType) => {
              await checkMaterialInformation(material_information)
            },
          ),
        )
      }
    } else {
      // いずれのrefもnullの場合は未登録原材料を指定しているため解析中とする
      isAnalysis = true
    }
  }

  if (
    recipe.dough_material_informations &&
    recipe.dough_material_informations.length > 0
  ) {
    await Promise.all(
      recipe.dough_material_informations.map(
        async (material_information: MaterialInformationType) => {
          await checkMaterialInformation(material_information)
        },
      ),
    )
  }
  if (
    recipe.filling_material_informations &&
    recipe.filling_material_informations.length > 0
  ) {
    await Promise.all(
      recipe.filling_material_informations.map(
        async (material_information: MaterialInformationType) => {
          await checkMaterialInformation(material_information)
        },
      ),
    )
  }
  if (
    recipe.recipe_material_informations &&
    recipe.recipe_material_informations.length > 0
  ) {
    await Promise.all(
      recipe.recipe_material_informations.map(
        async (material_information: MaterialInformationType) => {
          await checkMaterialInformation(material_information)
        },
      ),
    )
  }
  if (
    (!recipe.dough_material_informations ||
      recipe.dough_material_informations.length === 0) &&
    (!recipe.filling_material_informations ||
      recipe.filling_material_informations.length === 0) &&
    (!recipe.recipe_material_informations ||
      recipe.recipe_material_informations.length === 0)
  ) {
    // いずれの情報もない場合はNG
    isNg = true
  }

  const status: BackingStickerStatus = isNg
    ? BackingStickerStatus.NG
    : isAnalysis
    ? BackingStickerStatus.ANALYSIS
    : BackingStickerStatus.OK
  const breadDocRef = doc(db, 'breads', breadId)
  await updateDoc(breadDocRef, {
    backing_sticker_status: status,
  })
})

interface BreadDetailInformation {
  recipes: BreadDetailPetternInformation[] | null
  doughs: BreadDetailPetternInformation[] | null
  fillings: BreadDetailPetternInformation[] | null
  allMaterials: BreadDetailPetternInformation[]
  backSealInformation: BackSealInformation
  costPrice: number | null
  notCostCalculatedReasons: NotCostCalculatedReasons
  notCostCalculatedRecipeReasons: string[]
}
export type BreadDetailInformationType = BreadDetailInformation

interface BreadDetailPetternInformation {
  productName: string
  category: string
  categoryId: string | null
  dataType: DataType
  manufacturerName: string | null
  middleKneadPercent: number
  authenticKneadPercent: number
  allPercent: number
  unitType: UnitType
  energy: number | null
  protein: number | null
  lipid: number | null
  carbohydrate: number | null
  saltEquivalent: number | null
  materialId: string | null
  materialName: string | null
  originName: string | null
  additive: AdditiveType[] | null
  baseAllergens: string[] | null
  additiveAllergens: string[] | null
  percentageOfUse: number
  parentFillingId: string | null
  parentRecipeId: string | null
  callInRecipe: boolean
  supplierCostPrice: number | null
  supplierTax: number | null
  internalCapacityMemo: string | null
  yieldRate: number | null
  internalCapacityCount: number | null
  internalCapacityUnit: string | null
  numberItem: number | null
  numberItemUnit: string | null
  unitPrice1g: number | null
  cost: number | null
  burnout_rate: number | null
  amount_dough_after_baking: number | null
  is_after_baking_percent: boolean | null
  is_after_baking_gram: boolean | null
  notCalculatedReasons: NotCalculatedReasons | null
  recipes: BreadDetailPetternInformation[] | null
  doughs: BreadDetailPetternInformation[] | null
  fillings: BreadDetailPetternInformation[] | null
  materials: BreadDetailPetternInformation[] | null
  alls: BreadDetailPetternInformation[] | null
}
export type BreadDetailPetternInformationType = BreadDetailPetternInformation

interface BackSealInformation {
  energy: string
  protein: string
  lipid: string
  carbohydrate: string
  saltEquivalent: string
  materialNames: string
  additives: string
  baseAllergens: string[]
  additiveAllergens: string[]
}

interface MaterialNameInfo {
  materialName: string
  originName: string
  categoryId: string | null
  percentageOfUse: number
}

interface MaterialNameInfoMap {
  [materialId: string]: MaterialNameInfo
}

interface AdditiveInfo {
  additiveUseId: string
  additiveUseName: string
  additiveUseDisabledMaterialName: boolean
  materialName: string
  percentageOfUse: number
  order: number // 同じpercentageOfUse内での順番判断に使用する
}

interface AdditiveInfoMap {
  [additiveUseId: string]: AdditiveInfoMap2
}

interface AdditiveInfoMap2 {
  [materialName: string]: AdditiveInfo
}

interface NotCalculatedReasons {
  notAmountDoughBeforeBaking: boolean
  notAmountDoughBeforeBakingRecipeId: string
  notMaterialCostData: boolean
  notInputInternalCapacity: boolean
}

export interface CostInfo {
  supplierCostPrice: number | null
  supplierTax: number | null
  internalCapacityMemo: string | null
  yieldRate: number | null
  internalCapacityCount: number | null
  internalCapacityUnit: string | null
  numberItem: number | null
  numberItemUnit: string | null
  unitPrice1g: number | null
  cost: number | null
  notCalculatedReasons: NotCalculatedReasons | null
}

interface FinalCostInfo {
  finalCost: number | null
  noPackageingCost: number | null
  notCostCalculatedReasons: NotCostCalculatedReasons
  notCostCalculatedRecipeReasons: string[]
}

export interface NotCostCalculatedReasons {
  [materialId: string]: NotCalculatedReasons
}

export const getMaterialCostInformation = async (
  shopId: string | null = null,
  materialId: string | null = null,
  percentageOfUse: number,
  amountDoughBeforeBaking: number,
  parentFillingId: string | null = null,
  parentRecipeId: string | null = null,
) => {
  if (materialId && shopId) {
    // 原材料1個あたりの原価計算
    let cost: number | null = null
    const notCalculatedReasons: NotCalculatedReasons = {
      notAmountDoughBeforeBaking: false,
      notAmountDoughBeforeBakingRecipeId: '',
      notMaterialCostData: false,
      notInputInternalCapacity: false,
    }
    const qMaterialCosts = query(
      collection(db, 'material_costs'),
      where('material_ref', '==', doc(db, 'materials', materialId)),
      where('shop_ref', '==', doc(db, 'shops', shopId)),
    )
    const querySnapshotMaterialCosts = await getDocs(qMaterialCosts)
    // 1gあたりの単価
    let unitPrice1g: number | null = null
    let supplierCostPrice: number | null = null
    let supplierTax: number | null = null
    let internalCapacityMemo: string | null = null
    let yieldRate: number | null = null
    let internalCapacityCount: number | null = null
    let numberItem: number | null = null
    let internalCapacityUnit: string | null = null
    let numberItemUnit: string | null = null
    // 焼成前生地量の入力と原価情報がある場合のみ計算
    if (querySnapshotMaterialCosts.docs.length > 0) {
      const materialCostData = querySnapshotMaterialCosts.docs[0].data()
      const materialCost: MaterialCostType = {
        id: querySnapshotMaterialCosts.docs[0].id,
        material_ref: materialCostData.material_ref,
        shop_ref: materialCostData.shop_ref,
        suppliers: materialCostData.suppliers,
        yield_rate: materialCostData.yield_rate,
        author: materialCostData.author,
        created_at: materialCostData.created_at,
        updated_at: materialCostData.updated_at,
      }
      materialCost.suppliers.forEach((supplier) => {
        // 内容量情報が設定されている&原価計算に使用する設定の仕入れ先情報のみ原価計算に使用できる
        // 店舗ごとの内容量設定が存在する場合
        if (
          supplier.internal_capacity_count &&
          supplier.number_item &&
          supplier.used_costing
        ) {
          // 1gあたりの原価を出すために内容量単位を合わせる
          let unitAdjustment = 1
          if (
            supplier.internal_capacity_unit &&
            supplier.internal_capacity_unit === 'kg'
          ) {
            unitAdjustment = 1000
          } else if (
            supplier.internal_capacity_unit &&
            supplier.internal_capacity_unit === 'mL'
          ) {
            unitAdjustment = 1
          } else if (
            supplier.internal_capacity_unit &&
            supplier.internal_capacity_unit === 'L'
          ) {
            unitAdjustment = 1000
          }
          supplierCostPrice = supplier.cost_price
          supplierTax = supplier.tax
          internalCapacityMemo = supplier.memo
          yieldRate = materialCost.yield_rate
          internalCapacityCount = supplier.internal_capacity_count
          internalCapacityUnit = supplier.internal_capacity_unit
          numberItem = supplier.number_item
          numberItemUnit = supplier.number_item_unit
          unitPrice1g =
            (supplier.cost_price * (supplier.tax / 100 + 1)) /
            (materialCost.yield_rate / 100) /
            supplier.internal_capacity_count /
            supplier.number_item /
            unitAdjustment
        }
        // 原材料に紐づいた内容量情報が存在する場合
        else if (
          supplier.internal_capacity &&
          supplier.internal_capacity.internal_capacity_count &&
          supplier.internal_capacity.number_item &&
          supplier.used_costing
        ) {
          // 1gあたりの原価を出すために内容量単位を合わせる
          let unitAdjustment = 1
          if (
            supplier.internal_capacity.internal_capacity_unit &&
            supplier.internal_capacity.internal_capacity_unit === 'kg'
          ) {
            unitAdjustment = 1000
          } else if (
            supplier.internal_capacity.internal_capacity_unit &&
            supplier.internal_capacity.internal_capacity_unit === 'mL'
          ) {
            unitAdjustment = 1
          } else if (
            supplier.internal_capacity.internal_capacity_unit &&
            supplier.internal_capacity.internal_capacity_unit === 'L'
          ) {
            unitAdjustment = 1000
          }
          supplierCostPrice = supplier.cost_price
          supplierTax = supplier.tax
          internalCapacityMemo = supplier.memo
          yieldRate = materialCost.yield_rate
          internalCapacityCount =
            supplier.internal_capacity.internal_capacity_count
          internalCapacityUnit =
            supplier.internal_capacity.internal_capacity_unit
          numberItem = supplier.internal_capacity.number_item
          numberItemUnit = supplier.internal_capacity.number_item_unit
          unitPrice1g =
            (supplier.cost_price * (supplier.tax / 100 + 1)) /
            (materialCost.yield_rate / 100) /
            supplier.internal_capacity.internal_capacity_count /
            supplier.internal_capacity.number_item /
            unitAdjustment
        }
      })

      // フィリングパターン内の原材料の場合は完成後割合を考慮する
      let percentageAfterCompletion = 1
      if (parentFillingId) {
        const qFillings = doc(db, 'fillings', parentFillingId)
        const querySnapshotFillings = await getDoc(qFillings)
        const fillingData = querySnapshotFillings.data()
        if (fillingData) {
          const filling: FillingType = {
            id: querySnapshotFillings.id,
            pettern_name: fillingData.pettern_name,
            material_informations: fillingData.material_informations,
            finished_weight: fillingData.finished_weight,
            process: fillingData.process,
            memo: fillingData.memo,
            is_disabled: fillingData.is_disabled,
            is_percent: fillingData.is_percent,
            is_gram: fillingData.is_gram,
            shop_ref: fillingData.shop_ref,
            created_at: fillingData.created_at,
          }
          const totalWeight = filling.material_informations.reduce(
            (sum, productInformation) =>
              Number(productInformation.middle_knead_percent) +
              Number(productInformation.authentic_knead_percent) +
              Number(sum),
            0,
          )
          if (filling.finished_weight) {
            percentageAfterCompletion = filling.finished_weight / totalWeight
          }
        }
      }

      if (amountDoughBeforeBaking) {
        // 1gあたりの単価が出せる場合のみ原価計算
        if (unitPrice1g || unitPrice1g === 0) {
          cost =
            (amountDoughBeforeBaking * percentageOfUse * unitPrice1g) /
            percentageAfterCompletion
        } else {
          // 算出不可理由を追記
          notCalculatedReasons.notInputInternalCapacity = true
        }
      } else {
        notCalculatedReasons.notAmountDoughBeforeBaking = true
        notCalculatedReasons.notAmountDoughBeforeBakingRecipeId = parentRecipeId
          ? parentRecipeId
          : ''
      }
    } else {
      // 算出不可理由を追記
      if (!amountDoughBeforeBaking) {
        notCalculatedReasons.notAmountDoughBeforeBaking = true
        notCalculatedReasons.notAmountDoughBeforeBakingRecipeId = parentRecipeId
          ? parentRecipeId
          : ''
      }
      if (querySnapshotMaterialCosts.docs.length === 0) {
        notCalculatedReasons.notMaterialCostData = true
      }
    }
    const costInfo: CostInfo = {
      supplierCostPrice: supplierCostPrice,
      supplierTax: supplierTax,
      internalCapacityMemo: internalCapacityMemo,
      yieldRate: yieldRate,
      internalCapacityCount: internalCapacityCount,
      internalCapacityUnit: internalCapacityUnit,
      numberItem: numberItem,
      numberItemUnit: numberItemUnit,
      unitPrice1g: unitPrice1g,
      cost: cost,
      notCalculatedReasons: notCalculatedReasons,
    }
    return costInfo
  }
  return {
    supplierCostPrice: null,
    supplierTax: null,
    internalCapacityMemo: null,
    yieldRate: null,
    internalCapacityCount: null,
    internalCapacityUnit: null,
    numberItem: null,
    numberItemUnit: null,
    unitPrice1g: null,
    cost: null,
    notCalculatedReasons: null,
  }
}

export const getMaterialInformation = async (
  material_information: MaterialInformationType,
  unitType: UnitType,
  totalAmount: number,
  parentPercentageOfUse: number,
  amountDoughBeforeBaking: number,
  shopRef: DocumentReference | null = null,
  parentFillingId: string | null = null,
  parentRecipeId: string | null = null,
  callInRecipe: boolean = false,
  allMaterials: BreadDetailPetternInformation[] = [],
) => {
  if (material_information.material_ref) {
    const q = doc(db, 'materials', material_information.material_ref.id)
    const querySnapshot = await getDoc(q)
    const material = querySnapshot.data()
    if (!material) {
      return null
    }
    const subCategoeyQuery = doc(
      db,
      'material_sub_categories',
      material.material_sub_category_ref.id,
    )
    const subCategoeyQuerySnapshot = await getDoc(subCategoeyQuery)
    const subCategoey = subCategoeyQuerySnapshot.data()
    if (!subCategoey) {
      return null
    }

    const percentageOfUse =
      ((material_information.middle_knead_percent +
        material_information.authentic_knead_percent) /
        totalAmount) *
      parentPercentageOfUse

    const constInfo = await getMaterialCostInformation(
      shopRef?.id,
      material_information.material_ref.id,
      percentageOfUse,
      amountDoughBeforeBaking,
      parentFillingId,
      parentRecipeId,
    )

    const info: BreadDetailPetternInformation = {
      productName: material.product_name,
      category: subCategoey.name,
      categoryId: subCategoeyQuerySnapshot.id,
      dataType: DataType.MATERIAL,
      manufacturerName: material.manufacturer,
      middleKneadPercent: material_information.middle_knead_percent,
      authenticKneadPercent: material_information.authentic_knead_percent,
      allPercent:
        material_information.middle_knead_percent +
        material_information.authentic_knead_percent,
      unitType: unitType,
      energy: material.energy,
      protein: material.protein,
      lipid: material.lipid,
      carbohydrate: material.carbohydrate,
      saltEquivalent: material.salt_equivalent,
      materialId: querySnapshot.id,
      materialName: material.no_material
        ? ''
        : material.composite_material
        ? material.composite_material_name
        : material.material_name,
      originName: material.origin_name ? material.origin_name : null,
      additive: material.additive,
      baseAllergens: material.base_allergens,
      additiveAllergens: material.additive_allergens,
      percentageOfUse: percentageOfUse,
      parentFillingId: parentFillingId,
      parentRecipeId: parentRecipeId,
      callInRecipe: callInRecipe,
      supplierCostPrice: constInfo.supplierCostPrice,
      supplierTax: constInfo.supplierTax,
      internalCapacityMemo: constInfo.internalCapacityMemo,
      yieldRate: constInfo.yieldRate,
      internalCapacityCount: constInfo.internalCapacityCount,
      internalCapacityUnit: constInfo.internalCapacityUnit,
      numberItem: constInfo.numberItem,
      numberItemUnit: constInfo.numberItemUnit,
      unitPrice1g: constInfo.unitPrice1g,
      cost: constInfo.cost,
      burnout_rate: null,
      amount_dough_after_baking: null,
      is_after_baking_percent: null,
      is_after_baking_gram: null,
      notCalculatedReasons: constInfo.notCalculatedReasons,
      recipes: null,
      doughs: null,
      fillings: null,
      materials: null,
      alls: null,
    }
    allMaterials.push(info)
    return info
  } else if (material_information.dough_ref) {
    const q = doc(db, 'doughs', material_information.dough_ref.id)
    const querySnapshot = await getDoc(q)
    const dough = querySnapshot.data()
    if (!dough || dough.is_disabled || !dough.material_informations) {
      return null
    }
    const doughInfos: BreadDetailPetternInformation[] = []
    const fillingInfos: BreadDetailPetternInformation[] = []
    const materialInfos: BreadDetailPetternInformation[] = []
    const allInfos: BreadDetailPetternInformation[] = []
    // 生地パターンの合計量
    let childTotalAmount: number = 0
    await Promise.all(
      dough.material_informations.map(
        async (material_information: MaterialInformationType) => {
          childTotalAmount +=
            material_information.middle_knead_percent +
            material_information.authentic_knead_percent
        },
      ),
    )
    const percentageOfUse =
      ((material_information.middle_knead_percent +
        material_information.authentic_knead_percent) /
        totalAmount) *
      parentPercentageOfUse
    await dough.material_informations.reduce(
      (
        promise: Promise<void>,
        material_information: MaterialInformationType,
      ) => {
        return promise.then(async () => {
          const info = await getMaterialInformation(
            material_information,
            dough.is_gram ? UnitType.GRAM : UnitType.PERCENT,
            childTotalAmount,
            percentageOfUse,
            amountDoughBeforeBaking,
            dough.shop_ref,
            parentFillingId,
            parentRecipeId,
            callInRecipe,
            allMaterials,
          )
          if (!info) {
            return
          }
          if (material_information.dough_ref) {
            doughInfos.push(info)
          } else if (material_information.filling_ref) {
            fillingInfos.push(info)
          } else if (material_information.material_ref) {
            materialInfos.push(info)
          }
          allInfos.push(info)
        })
      },
      Promise.resolve(),
    )
    const info: BreadDetailPetternInformation = {
      productName: dough.pettern_name,
      category: '生地パターン',
      categoryId: null,
      manufacturerName: null,
      dataType: DataType.DOUGH,
      middleKneadPercent: material_information.middle_knead_percent,
      authenticKneadPercent: material_information.authentic_knead_percent,
      allPercent:
        material_information.middle_knead_percent +
        material_information.authentic_knead_percent,
      unitType: unitType,
      energy: null,
      protein: null,
      lipid: null,
      carbohydrate: null,
      saltEquivalent: null,
      materialId: null,
      materialName: null,
      originName: null,
      additive: null,
      baseAllergens: null,
      additiveAllergens: null,
      percentageOfUse: percentageOfUse,
      parentFillingId: parentFillingId,
      parentRecipeId: parentRecipeId,
      callInRecipe: callInRecipe,
      supplierCostPrice: null,
      supplierTax: null,
      internalCapacityMemo: null,
      yieldRate: null,
      internalCapacityCount: null,
      internalCapacityUnit: null,
      numberItem: null,
      numberItemUnit: null,
      unitPrice1g: null,
      cost: null,
      burnout_rate: null,
      amount_dough_after_baking: null,
      is_after_baking_percent: null,
      is_after_baking_gram: null,
      notCalculatedReasons: null,
      recipes: null,
      doughs: doughInfos,
      fillings: fillingInfos,
      materials: materialInfos,
      alls: allInfos,
    }
    return info
  } else if (material_information.filling_ref) {
    const q = doc(db, 'fillings', material_information.filling_ref.id)
    const querySnapshot = await getDoc(q)
    const filling = querySnapshot.data()
    const fillingId = querySnapshot.id
    if (!filling || filling.is_disabled || !filling.material_informations) {
      return null
    }
    const fillingInfos: BreadDetailPetternInformation[] = []
    const materialInfos: BreadDetailPetternInformation[] = []
    const allInfos: BreadDetailPetternInformation[] = []
    // フィリングパターンの合計量
    let childTotalAmount: number = 0
    await Promise.all(
      filling.material_informations.map(
        async (material_information: MaterialInformationType) => {
          childTotalAmount +=
            material_information.middle_knead_percent +
            material_information.authentic_knead_percent
        },
      ),
    )
    const percentageOfUse =
      ((material_information.middle_knead_percent +
        material_information.authentic_knead_percent) /
        totalAmount) *
      parentPercentageOfUse
    await filling.material_informations.reduce(
      (
        promise: Promise<void>,
        material_information: MaterialInformationType,
      ) => {
        return promise.then(async () => {
          const info = await getMaterialInformation(
            material_information,
            filling.is_percent ? UnitType.PERCENT : UnitType.GRAM,
            childTotalAmount,
            percentageOfUse,
            amountDoughBeforeBaking,
            filling.shop_ref,
            fillingId,
            parentRecipeId,
            callInRecipe,
            allMaterials,
          )
          if (!info) {
            return
          }
          if (material_information.filling_ref) {
            fillingInfos.push(info)
          } else if (material_information.material_ref) {
            materialInfos.push(info)
          }
          allInfos.push(info)
        })
      },
      Promise.resolve(),
    )
    const info: BreadDetailPetternInformation = {
      productName: filling.pettern_name,
      category: 'フィリングパターン',
      categoryId: null,
      manufacturerName: null,
      dataType: DataType.FILLING,
      middleKneadPercent: material_information.middle_knead_percent,
      authenticKneadPercent: material_information.authentic_knead_percent,
      allPercent:
        material_information.middle_knead_percent +
        material_information.authentic_knead_percent,
      unitType: unitType,
      energy: null,
      protein: null,
      lipid: null,
      carbohydrate: null,
      saltEquivalent: null,
      materialId: null,
      materialName: null,
      originName: null,
      additive: null,
      baseAllergens: null,
      additiveAllergens: null,
      percentageOfUse: percentageOfUse,
      parentFillingId: parentFillingId,
      parentRecipeId: parentRecipeId,
      callInRecipe: callInRecipe,
      supplierCostPrice: null,
      supplierTax: null,
      internalCapacityMemo: null,
      yieldRate: null,
      internalCapacityCount: null,
      internalCapacityUnit: null,
      numberItem: null,
      numberItemUnit: null,
      unitPrice1g: null,
      cost: null,
      burnout_rate: null,
      amount_dough_after_baking: null,
      is_after_baking_percent: null,
      is_after_baking_gram: null,
      notCalculatedReasons: null,
      recipes: null,
      doughs: null,
      fillings: fillingInfos,
      materials: materialInfos,
      alls: allInfos,
    }
    return info
  } else if (material_information.recipe_ref) {
    const q = doc(db, 'recipes', material_information.recipe_ref.id)
    const querySnapshot = await getDoc(q)
    const recipe = querySnapshot.data()
    const recipeId = querySnapshot.id
    if (!recipe) {
      return null
    }
    const breadQuery = doc(db, 'breads', recipe.bread_ref.id)
    const breadQuerySnapshot = await getDoc(breadQuery)
    const bread = breadQuerySnapshot.data()
    if (
      !bread ||
      bread.status === BreadStatus.DELETE ||
      bread.daseruno_status === BreadStatus.DRAFT ||
      bread.daseruno_recipe_status === BreadStatus.DRAFT
    ) {
      return null
    }
    const categoryQuery = doc(db, 'categories', bread.category.id)
    const categoryQuerySnapshot = await getDoc(categoryQuery)
    const categoey = categoryQuerySnapshot.data()
    if (!categoey) {
      return null
    }
    const doughInfos: BreadDetailPetternInformation[] = []
    const fillingInfos: BreadDetailPetternInformation[] = []
    const recipeInfos: BreadDetailPetternInformation[] = []
    const allInfos: BreadDetailPetternInformation[] = []
    // 使用割合算出のために合計量を計算する
    let childTotalAmount: number = 0
    if (
      recipe.dough_material_informations &&
      recipe.dough_material_informations.length > 0
    ) {
      await Promise.all(
        recipe.dough_material_informations.map(
          async (material_information: MaterialInformationType) => {
            childTotalAmount +=
              material_information.middle_knead_percent +
              material_information.authentic_knead_percent
          },
        ),
      )
    }
    if (
      recipe.filling_material_informations &&
      recipe.filling_material_informations.length > 0
    ) {
      await Promise.all(
        recipe.filling_material_informations.map(
          async (material_information: MaterialInformationType) => {
            childTotalAmount +=
              material_information.middle_knead_percent +
              material_information.authentic_knead_percent
          },
        ),
      )
    }
    if (
      recipe.recipe_material_informations &&
      recipe.recipe_material_informations.length > 0
    ) {
      await Promise.all(
        recipe.recipe_material_informations.map(
          async (material_information: MaterialInformationType) => {
            childTotalAmount +=
              material_information.middle_knead_percent +
              material_information.authentic_knead_percent
          },
        ),
      )
    }
    const percentageOfUse =
      ((material_information.middle_knead_percent +
        material_information.authentic_knead_percent) /
        totalAmount) *
      parentPercentageOfUse
    if (
      recipe.dough_material_informations &&
      recipe.dough_material_informations.length > 0
    ) {
      await recipe.dough_material_informations.reduce(
        (
          promise: Promise<void>,
          material_information: MaterialInformationType,
        ) => {
          return promise.then(async () => {
            const info = await getMaterialInformation(
              material_information,
              recipe.is_percent ? UnitType.PERCENT : UnitType.GRAM,
              childTotalAmount,
              percentageOfUse,
              amountDoughBeforeBaking,
              recipe.shop_ref,
              parentFillingId,
              recipeId,
              true,
              allMaterials,
            )
            if (!info) {
              return
            }
            doughInfos.push(info)
            allInfos.push(info)
          })
        },
        Promise.resolve(),
      )
    }
    if (
      recipe.filling_material_informations &&
      recipe.filling_material_informations.length > 0
    ) {
      await recipe.filling_material_informations.reduce(
        (
          promise: Promise<void>,
          material_information: MaterialInformationType,
        ) => {
          return promise.then(async () => {
            const info = await getMaterialInformation(
              material_information,
              recipe.is_percent ? UnitType.PERCENT : UnitType.GRAM,
              childTotalAmount,
              percentageOfUse,
              amountDoughBeforeBaking,
              recipe.shop_ref,
              parentFillingId,
              recipeId,
              true,
              allMaterials,
            )
            if (!info) {
              return
            }
            fillingInfos.push(info)
            allInfos.push(info)
          })
        },
        Promise.resolve(),
      )
    }
    if (
      recipe.recipe_material_informations &&
      recipe.recipe_material_informations.length > 0
    ) {
      await recipe.recipe_material_informations.reduce(
        (
          promise: Promise<void>,
          material_information: MaterialInformationType,
        ) => {
          return promise.then(async () => {
            const info = await getMaterialInformation(
              material_information,
              recipe.is_percent ? UnitType.PERCENT : UnitType.GRAM,
              childTotalAmount,
              percentageOfUse,
              amountDoughBeforeBaking,
              recipe.shop_ref,
              parentFillingId,
              recipeId,
              true,
              allMaterials,
            )
            if (!info) {
              return
            }
            recipeInfos.push(info)
            allInfos.push(info)
          })
        },
        Promise.resolve(),
      )
    }
    const info: BreadDetailPetternInformation = {
      productName: recipe.recipe_name,
      category: categoey.name,
      categoryId: categoryQuerySnapshot.id,
      manufacturerName: null,
      dataType: DataType.RECIPE,
      middleKneadPercent: material_information.middle_knead_percent,
      authenticKneadPercent: material_information.authentic_knead_percent,
      allPercent:
        material_information.middle_knead_percent +
        material_information.authentic_knead_percent,
      unitType: unitType,
      energy: null,
      protein: null,
      lipid: null,
      carbohydrate: null,
      saltEquivalent: null,
      materialId: null,
      materialName: null,
      originName: null,
      additive: null,
      baseAllergens: null,
      additiveAllergens: null,
      percentageOfUse: percentageOfUse,
      parentFillingId: parentFillingId,
      parentRecipeId: parentRecipeId,
      callInRecipe: callInRecipe,
      supplierCostPrice: null,
      supplierTax: null,
      internalCapacityMemo: null,
      yieldRate: null,
      internalCapacityCount: null,
      internalCapacityUnit: null,
      numberItem: null,
      numberItemUnit: null,
      unitPrice1g: null,
      cost: null,
      burnout_rate: recipe.burnout_rate,
      amount_dough_after_baking: recipe.amount_dough_after_baking,
      is_after_baking_percent: recipe.is_after_baking_percent,
      is_after_baking_gram: recipe.is_after_baking_gram,
      notCalculatedReasons: null,
      recipes: recipeInfos,
      doughs: doughInfos,
      fillings: fillingInfos,
      materials: null,
      alls: allInfos,
    }
    return info
  } else {
    // いずれのrefもnullの場合は未登録原材料を指定している
    const percentageOfUse =
      ((material_information.middle_knead_percent +
        material_information.authentic_knead_percent) /
        totalAmount) *
      parentPercentageOfUse
    const info: BreadDetailPetternInformation = {
      productName: material_information.productName
        ? material_information.productName
        : '',
      category: '',
      categoryId: '',
      manufacturerName: material_information.manufacturerName,
      dataType: DataType.MATERIAL,
      middleKneadPercent: material_information.middle_knead_percent,
      authenticKneadPercent: material_information.authentic_knead_percent,
      allPercent:
        material_information.middle_knead_percent +
        material_information.authentic_knead_percent,
      unitType: unitType,
      energy: null,
      protein: null,
      lipid: null,
      carbohydrate: null,
      saltEquivalent: null,
      materialId: 'manualInput',
      materialName: null,
      originName: null,
      additive: null,
      baseAllergens: null,
      additiveAllergens: null,
      percentageOfUse: percentageOfUse,
      parentFillingId: parentFillingId,
      parentRecipeId: parentRecipeId,
      callInRecipe: callInRecipe,
      supplierCostPrice: null,
      supplierTax: null,
      internalCapacityMemo: null,
      yieldRate: null,
      internalCapacityCount: null,
      internalCapacityUnit: null,
      numberItem: null,
      numberItemUnit: null,
      unitPrice1g: null,
      cost: null,
      burnout_rate: null,
      amount_dough_after_baking: null,
      is_after_baking_percent: null,
      is_after_baking_gram: null,
      notCalculatedReasons: null,
      recipes: null,
      doughs: null,
      fillings: null,
      materials: null,
      alls: null,
    }
    return info
  }
}

export const getFinalCost = async (
  allMaterials: BreadDetailPetternInformation[],
  amountDoughBeforeBaking: number,
  lossRate: number,
  packagingCost: number | null,
) => {
  // 原価計算
  let cost: number = 0
  let recipeCostMap: Map<string, number> = new Map<string, number>()
  let recipePercentageOfUseMap: Map<string, number> = new Map<string, number>()
  let isCostCalculated = true
  const notCostCalculatedReasons: NotCostCalculatedReasons = {}
  await Promise.all(
    allMaterials.map(async (materialInfo) => {
      // 一つでも原価不明な原材料があれば算出不可
      if (materialInfo.cost === null) {
        isCostCalculated = false
        // 算出不可理由を出す
        if (materialInfo.notCalculatedReasons && materialInfo.materialId) {
          notCostCalculatedReasons[materialInfo.materialId] =
            materialInfo.notCalculatedReasons
        }
        return
      }
      if (materialInfo.callInRecipe && materialInfo.parentRecipeId) {
        // あれば加算、なければ初期化
        const currentCost = recipeCostMap.get(materialInfo.parentRecipeId)
        if (currentCost) {
          recipeCostMap.set(
            materialInfo.parentRecipeId,
            currentCost + materialInfo.cost,
          )
        } else {
          recipeCostMap.set(materialInfo.parentRecipeId, materialInfo.cost)
        }
        const currentPercentageOfUse = recipePercentageOfUseMap.get(
          materialInfo.parentRecipeId,
        )
        if (currentPercentageOfUse) {
          recipePercentageOfUseMap.set(
            materialInfo.parentRecipeId,
            currentPercentageOfUse + materialInfo.percentageOfUse,
          )
        } else {
          recipePercentageOfUseMap.set(
            materialInfo.parentRecipeId,
            materialInfo.percentageOfUse,
          )
        }
      } else {
        cost += materialInfo.cost
      }
    }),
  )
  const notCostCalculatedRecipeReasons: string[] = []
  // レシピから呼び出されている原材料は焼減率および焼成後重量を考慮して原価を出す
  if (recipeCostMap) {
    await Promise.all(
      Array.from(recipeCostMap.entries()).map(async (recipeCost) => {
        const recipeId = recipeCost[0]
        const rCost = recipeCost[1]
        const q = doc(db, 'recipes', recipeId)
        const querySnapshot = await getDoc(q)
        const cRecipe = querySnapshot.data()
        if (!cRecipe) {
          return
        }
        let isError = false
        // 焼成前重量のバリデーションチェックは呼び出し元で行うためコメントアウト
        // if (!recipe.amount_dough_before_baking) {
        //   notCostCalculatedRecipeReasons.push("レシピの「商品1個あたりの重量(焼成前生地量)」が入力されていません。")
        //   isError = true
        // }
        if (!cRecipe.is_after_baking_percent && !cRecipe.is_after_baking_gram) {
          notCostCalculatedRecipeReasons.push(
            cRecipe.recipe_name +
              'の「焼減率」または「商品１個あたりの焼成後の重量」が入力されていません。',
          )
          isError = true
        }
        if (cRecipe.is_after_baking_percent && !cRecipe.burnout_rate) {
          notCostCalculatedRecipeReasons.push(
            cRecipe.recipe_name + 'の「焼減率」が入力されていません。',
          )
          isError = true
        }
        if (
          cRecipe.is_after_baking_gram &&
          !cRecipe.amount_dough_after_baking
        ) {
          notCostCalculatedRecipeReasons.push(
            cRecipe.recipe_name +
              'の「商品１個あたりの焼成後の重量」が入力されていません。',
          )
          isError = true
        }
        if (isError) {
          return
        }
        let recipeUnitPrice1g = 0
        const lossRate = cRecipe.loss_rate ? cRecipe.loss_rate : 0
        if (
          cRecipe.is_after_baking_percent &&
          cRecipe.amount_dough_before_baking &&
          (cRecipe.burnout_rate || cRecipe.burnout_rate === 0)
        ) {
          // 焼減率
          recipeUnitPrice1g =
            (rCost * lossRate + rCost) /
            (cRecipe.amount_dough_before_baking -
              cRecipe.burnout_rate * cRecipe.amount_dough_before_baking)
        } else if (
          cRecipe.is_after_baking_gram &&
          cRecipe.amount_dough_after_baking
        ) {
          // 焼成後重量
          recipeUnitPrice1g =
            (rCost * lossRate + rCost) / cRecipe.amount_dough_after_baking
        }
        const recipePercentageOfUseGetMap =
          recipePercentageOfUseMap.get(recipeId)
        const recipePercentageOfUse = recipePercentageOfUseGetMap
          ? recipePercentageOfUseGetMap
          : 1
        const recipeGPerPiece = amountDoughBeforeBaking * recipePercentageOfUse
        cost += recipeUnitPrice1g * recipeGPerPiece
      }),
    )
  }
  let finalCost: number | null = null
  let noPackageingCost: number | null = null
  if (isCostCalculated) {
    finalCost = cost * lossRate + cost
    noPackageingCost = cost * lossRate + cost

    if (packagingCost) {
      finalCost += packagingCost
    }
  }

  const costInfo: FinalCostInfo = {
    finalCost: finalCost,
    noPackageingCost: noPackageingCost,
    notCostCalculatedReasons: notCostCalculatedReasons,
    notCostCalculatedRecipeReasons: notCostCalculatedRecipeReasons,
  }
  return costInfo
}

export const getMaterialInfomationByBreadId = createAsyncThunk<
  BreadDetailInformation | null,
  { breadId: string }
>('business/getMaterialInfomationByBreadId', async ({ breadId }) => {
  const q = query(
    collection(db, 'recipes'),
    where('bread_ref', '==', doc(db, 'breads', breadId)),
  )
  const querySnapshot = await getDocs(q)
  const recipe = querySnapshot.docs[0].data()
  const recipeId = querySnapshot.docs[0].id
  if (!recipe) {
    return null
  }

  const allMaterials: BreadDetailPetternInformation[] = []

  // 使用割合算出のために合計量を計算する
  let totalAmount: number = 0
  if (
    recipe.dough_material_informations &&
    recipe.dough_material_informations.length > 0
  ) {
    await Promise.all(
      recipe.dough_material_informations.map(
        async (material_information: MaterialInformationType) => {
          totalAmount +=
            material_information.middle_knead_percent +
            material_information.authentic_knead_percent
        },
      ),
    )
  }
  if (
    recipe.filling_material_informations &&
    recipe.filling_material_informations.length > 0
  ) {
    await Promise.all(
      recipe.filling_material_informations.map(
        async (material_information: MaterialInformationType) => {
          totalAmount +=
            material_information.middle_knead_percent +
            material_information.authentic_knead_percent
        },
      ),
    )
  }
  if (
    recipe.recipe_material_informations &&
    recipe.recipe_material_informations.length > 0
  ) {
    await Promise.all(
      recipe.recipe_material_informations.map(
        async (material_information: MaterialInformationType) => {
          totalAmount +=
            material_information.middle_knead_percent +
            material_information.authentic_knead_percent
        },
      ),
    )
  }

  const doughInfos: BreadDetailPetternInformation[] = []
  const fillingInfos: BreadDetailPetternInformation[] = []
  const recipeInfos: BreadDetailPetternInformation[] = []
  if (
    recipe.dough_material_informations &&
    recipe.dough_material_informations.length > 0
  ) {
    await recipe.dough_material_informations.reduce(
      (
        promise: Promise<void>,
        material_information: MaterialInformationType,
      ) => {
        return promise.then(async () => {
          const info = await getMaterialInformation(
            material_information,
            recipe.is_percent ? UnitType.PERCENT : UnitType.GRAM,
            totalAmount,
            1,
            recipe.amount_dough_before_baking,
            recipe.shop_ref,
            null,
            recipeId,
            false,
            allMaterials, // 参照渡しでメソッド内部にて要素が追加されていく
          )
          if (!info) {
            return
          }
          doughInfos.push(info)
        })
      },
      Promise.resolve(),
    )
  }
  if (
    recipe.filling_material_informations &&
    recipe.filling_material_informations.length > 0
  ) {
    await recipe.filling_material_informations.reduce(
      (
        promise: Promise<void>,
        material_information: MaterialInformationType,
      ) => {
        return promise.then(async () => {
          const info = await getMaterialInformation(
            material_information,
            recipe.is_percent ? UnitType.PERCENT : UnitType.GRAM,
            totalAmount,
            1,
            recipe.amount_dough_before_baking,
            recipe.shop_ref,
            null,
            recipeId,
            false,
            allMaterials, // 参照渡しでメソッド内部にて要素が追加されていく
          )
          if (!info) {
            return
          }
          fillingInfos.push(info)
        })
      },
      Promise.resolve(),
    )
  }
  if (
    recipe.recipe_material_informations &&
    recipe.recipe_material_informations.length > 0
  ) {
    await recipe.recipe_material_informations.reduce(
      (
        promise: Promise<void>,
        material_information: MaterialInformationType,
      ) => {
        return promise.then(async () => {
          const info = await getMaterialInformation(
            material_information,
            recipe.is_percent ? UnitType.PERCENT : UnitType.GRAM,
            totalAmount,
            1,
            recipe.amount_dough_before_baking,
            recipe.shop_ref,
            null,
            recipeId,
            false,
            allMaterials, // 参照渡しでメソッド内部にて要素が追加されていく
          )
          if (!info) {
            return
          }
          recipeInfos.push(info)
        })
      },
      Promise.resolve(),
    )
  }

  // 原材料情報を使用割合降順に並び替える
  allMaterials.sort((a, b) => {
    return b.percentageOfUse - a.percentageOfUse
  })

  // 栄養成分・添加物・原材料・アレルゲン情報を取得加工する
  let energy = 0
  let protein = 0
  let lipid = 0
  let carbohydrate = 0
  let saltEquivalent = 0
  const materialNameInfoMap: MaterialNameInfoMap = {}
  const additiveInfoMap: AdditiveInfoMap = {}
  additiveInfoMap['noAdditiveUse'] = {}
  let baseAllergens = new Set<string>()
  let additiveAllergens = new Set<string>()
  // 水のsub_category_idを取得する
  const categoryQuery = query(
    collection(db, 'material_sub_categories'),
    where('name', '==', '水'),
  )
  const categoryQuerySnapshot = await getDocs(categoryQuery)
  const waterId = categoryQuerySnapshot.docs[0].id

  await Promise.all(
    allMaterials.map(async (materialInfo) => {
      // 水の場合は栄養成分表示に反映しない
      if (materialInfo.categoryId && materialInfo.categoryId === waterId) {
        return
      }
      energy += materialInfo.energy
        ? materialInfo.energy * materialInfo.percentageOfUse
        : 0
      protein += materialInfo.protein
        ? materialInfo.protein * materialInfo.percentageOfUse
        : 0
      lipid += materialInfo.lipid
        ? materialInfo.lipid * materialInfo.percentageOfUse
        : 0
      carbohydrate += materialInfo.carbohydrate
        ? materialInfo.carbohydrate * materialInfo.percentageOfUse
        : 0
      saltEquivalent += materialInfo.saltEquivalent
        ? materialInfo.saltEquivalent * materialInfo.percentageOfUse
        : 0
      if (
        materialInfo.materialId &&
        materialInfo.materialName &&
        materialInfo.categoryId
      ) {
        if (materialNameInfoMap.hasOwnProperty(materialInfo.materialName)) {
          materialNameInfoMap[materialInfo.materialName]['percentageOfUse'] +=
            materialInfo.percentageOfUse
        } else {
          materialNameInfoMap[materialInfo.materialName] = {
            materialName: materialInfo.materialName,
            originName: materialInfo.originName ? materialInfo.originName : '',
            categoryId: materialInfo.categoryId,
            percentageOfUse: materialInfo.percentageOfUse,
          }
        }
      }
      if (materialInfo.additive) {
        // 香料の用途IDを取得する
        const fragranceQuery = query(
          collection(db, 'additive_uses'),
          where('name', '==', '香料'),
        )
        const fragranceQuerySnapshot = await getDocs(fragranceQuery)
        const fragranceId = fragranceQuerySnapshot.docs[0].id
        const fragranceData = fragranceQuerySnapshot.docs[0].data()

        // 栄養成分に表示しない用途(栄養強化剤)の用途IDを取得する
        const fortifierQuery = query(
          collection(db, 'additive_uses'),
          where('name', '==', '栄養強化剤'),
        )
        const fortifierQuerySnapshot = await getDocs(fortifierQuery)
        const fortifierId = fortifierQuerySnapshot.docs[0].id

        const processingAidQuery = query(
          collection(db, 'additive_uses'),
          where('name', '==', '加工助剤'),
        )
        const processingAidQuerySnapshot = await getDocs(processingAidQuery)
        const processingAidId = processingAidQuerySnapshot.docs[0].id
        await Promise.all(
          materialInfo.additive.map(async (additive, i) => {
            // 用途と物質名両方ない場合は表示もしない
            if (!additive.additive_use_ref && !additive.additive_material) {
              return
            }
            // 物質名が「V.C」だった場合「ビタミンC」に変換する
            if (
              additive.additive_material &&
              additive.additive_material === 'V.C'
            ) {
              additive.additive_material = 'ビタミンC'
            }
            if (additive.additive_use_ref && additive.additive_use_ref.id) {
              const additiveId = additive.additive_use_ref.id
              // 栄養成分表示させない添加物かどうか判定
              if ([fortifierId, processingAidId].includes(additiveId)) {
                return
              }
              const additiveUseQuery = doc(db, 'additive_uses', additiveId)
              const additiveUseQuerySnapshot = await getDoc(additiveUseQuery)
              const additiveUse = additiveUseQuerySnapshot.data()
              if (!additiveUse) {
                return
              }
              if (additiveInfoMap.hasOwnProperty(additiveId)) {
                // 物質名の部分一致も含めて判定する
                // 例: 「グリセリン脂肪酸エステル、ソルビタン脂肪酸エステル」と「ソルビタン脂肪酸エステル」
                Object.keys(additiveInfoMap[additiveId]).forEach(
                  (additive_material) => {
                    const pattern = additive.additive_material
                      ? additive.additive_material
                      : 'noAdditiveMaterial'
                    if (additive_material.indexOf(pattern) > -1) {
                      // 判定対象の物質名が、既存の物質名内に存在している
                      additiveInfoMap[additiveId][additive_material][
                        'percentageOfUse'
                      ] += materialInfo.percentageOfUse
                    } else if (pattern.indexOf(additive_material) > -1) {
                      // 既存の物質名が、判定対象の物質名内に存在している
                      // 判定対象のmapを作成し既存のpercentageOfUseを足し合わせる
                      additiveInfoMap[additiveId][pattern] = {
                        additiveUseId: additiveId,
                        additiveUseName: additiveUse.name,
                        additiveUseDisabledMaterialName:
                          additiveUse.disabled_material_name,
                        materialName: additive.additive_material
                          ? additive.additive_material
                          : '',
                        percentageOfUse:
                          materialInfo.percentageOfUse +
                          additiveInfoMap[additiveId][additive_material]
                            .percentageOfUse,
                        order: i,
                      }
                      // 既存物質名のmapを削除
                      delete additiveInfoMap[additiveId][additive_material]
                    } else if (additiveUse.disabled_material_name === true) {
                      // 物質名を表示しないもので用途IDが共通のものについては足し合わせる
                      additiveInfoMap[additiveId][pattern] = {
                        additiveUseId: additiveId,
                        additiveUseName: additiveUse.name,
                        additiveUseDisabledMaterialName:
                          additiveUse.disabled_material_name,
                        materialName: additive.additive_material
                          ? additive.additive_material
                          : '',
                        percentageOfUse:
                          materialInfo.percentageOfUse +
                          additiveInfoMap[additiveId][additive_material]
                            .percentageOfUse,
                        order: i,
                      }
                      // 既存物質名のmapを削除
                      delete additiveInfoMap[additiveId][additive_material]
                    } else {
                      // 新規の物質名
                      additiveInfoMap[additiveId][pattern] = {
                        additiveUseId: additiveId,
                        additiveUseName: additiveUse.name,
                        additiveUseDisabledMaterialName:
                          additiveUse.disabled_material_name,
                        materialName: additive.additive_material
                          ? additive.additive_material
                          : '',
                        percentageOfUse: materialInfo.percentageOfUse,
                        order: i,
                      }
                    }
                  },
                )
              } else {
                additiveInfoMap[additiveId] = {}
                additiveInfoMap[additiveId][
                  additive.additive_material
                    ? additive.additive_material
                    : 'noAdditiveMaterial'
                ] = {
                  additiveUseId: additiveId,
                  additiveUseName: additiveUse.name,
                  additiveUseDisabledMaterialName:
                    additiveUse.disabled_material_name,
                  materialName: additive.additive_material
                    ? additive.additive_material
                    : '',
                  percentageOfUse: materialInfo.percentageOfUse,
                  order: i,
                }
              }
            } else {
              // 用途がない場合

              // 物質名に「香料」を含む場合
              if (
                additive.additive_material &&
                additive.additive_material.indexOf('香料') > -1
              ) {
                // 香料の用途IDとしてカウントする
                if (!additiveInfoMap.hasOwnProperty(fragranceId)) {
                  additiveInfoMap[fragranceId] = {}
                }
                additiveInfoMap[fragranceId][
                  additive.additive_material
                    ? additive.additive_material
                    : 'noAdditiveMaterial'
                ] = {
                  additiveUseId: fragranceId,
                  additiveUseName: fragranceData.name,
                  additiveUseDisabledMaterialName:
                    fragranceData.disabled_material_name,
                  materialName: additive.additive_material
                    ? additive.additive_material
                    : '',
                  percentageOfUse: materialInfo.percentageOfUse,
                  order: i,
                }
              } else {
                additiveInfoMap['noAdditiveUse'][
                  additive.additive_material
                    ? additive.additive_material
                    : 'noAdditiveMaterial'
                ] = {
                  additiveUseId: '',
                  additiveUseName: '',
                  additiveUseDisabledMaterialName: false,
                  materialName: additive.additive_material
                    ? additive.additive_material
                    : '',
                  percentageOfUse: materialInfo.percentageOfUse,
                  order: i,
                }
              }
            }
          }),
        )
      }
      if (materialInfo.baseAllergens) {
        materialInfo.baseAllergens.forEach((allergen) => {
          if (allergen === Allergens.EGG) {
            baseAllergens.add('卵')
          } else if (allergen === Allergens.MILK) {
            baseAllergens.add('乳成分')
          } else if (allergen === Allergens.WHEAT) {
            baseAllergens.add('小麦')
          } else if (allergen === Allergens.SHRIMP) {
            baseAllergens.add('えび')
          } else if (allergen === Allergens.CRUB) {
            baseAllergens.add('かに')
          } else if (allergen === Allergens.PEANUTS) {
            baseAllergens.add('落花生')
          } else if (allergen === Allergens.SOBA) {
            baseAllergens.add('そば')
          } else if (allergen === Allergens.WALNUT) {
            baseAllergens.add('くるみ')
          } else {
            // baseAllergens.add('その他')
            // TODO: 28品目表示について特定店舗のみの対応から、店舗ごとに設定値を持つ実装に切り替える
            console.log(2024092401, recipe, recipe.shop_ref.id)
            if (
              process.env.REACT_APP_NODE_ENV === 'development' ||
              (process.env.REACT_APP_NODE_ENV === 'production' &&
                ['LmrupsmUA1TCNvU8JnZJ', 'CEjRQ2AiDJMXZVK3kExF'].includes(
                  recipe.shop_ref.id,
                ))
            ) {
              if (allergen === Allergens.ALMOND) {
                baseAllergens.add('アーモンド')
              } else if (allergen === Allergens.ABALONE) {
                baseAllergens.add('あわび')
              } else if (allergen === Allergens.SQUID) {
                baseAllergens.add('いか')
              } else if (allergen === Allergens.IKURA) {
                baseAllergens.add('いくら')
              } else if (allergen === Allergens.ORANGE) {
                baseAllergens.add('オレンジ')
              } else if (allergen === Allergens.CASHEWNUTS) {
                baseAllergens.add('カシューナッツ')
              } else if (allergen === Allergens.KIWIFRUIT) {
                baseAllergens.add('キウイフルーツ')
              } else if (allergen === Allergens.BEEF) {
                baseAllergens.add('牛肉')
              } else if (allergen === Allergens.SESAME) {
                baseAllergens.add('ごま')
              } else if (allergen === Allergens.SALMON) {
                baseAllergens.add('鮭')
              } else if (allergen === Allergens.MACKEREL) {
                baseAllergens.add('鯖')
              } else if (allergen === Allergens.SOY) {
                baseAllergens.add('大豆')
              } else if (allergen === Allergens.CHICKEN) {
                baseAllergens.add('鶏肉')
              } else if (allergen === Allergens.BANANA) {
                baseAllergens.add('バナナ')
              } else if (allergen === Allergens.PORK) {
                baseAllergens.add('豚肉')
              } else if (allergen === Allergens.MACADAMIANUTS) {
                baseAllergens.add('マカダミアナッツ')
              } else if (allergen === Allergens.PEACH) {
                baseAllergens.add('桃')
              } else if (allergen === Allergens.YAM) {
                baseAllergens.add('山芋')
              } else if (allergen === Allergens.APPLE) {
                baseAllergens.add('りんご')
              } else if (allergen === Allergens.GELATINE) {
                baseAllergens.add('ゼラチン')
              }
            }
          }
        })
      }
      if (materialInfo.additiveAllergens) {
        materialInfo.additiveAllergens.forEach((allergen) => {
          if (allergen === Allergens.EGG) {
            additiveAllergens.add('卵')
          } else if (allergen === Allergens.MILK) {
            additiveAllergens.add('乳成分')
          } else if (allergen === Allergens.WHEAT) {
            additiveAllergens.add('小麦')
          } else if (allergen === Allergens.SHRIMP) {
            additiveAllergens.add('えび')
          } else if (allergen === Allergens.CRUB) {
            additiveAllergens.add('かに')
          } else if (allergen === Allergens.PEANUTS) {
            additiveAllergens.add('落花生')
          } else if (allergen === Allergens.SOBA) {
            additiveAllergens.add('そば')
          } else if (allergen === Allergens.WALNUT) {
            additiveAllergens.add('くるみ')
          } else {
            // additiveAllergens.add('その他')
            // TODO: 28品目表示について特定店舗のみの対応から、店舗ごとに設定値を持つ実装に切り替える
            console.log(2024092402, recipe, recipe.shop_ref.id)
            if (
              process.env.REACT_APP_NODE_ENV === 'development' ||
              (process.env.REACT_APP_NODE_ENV === 'production' &&
                ['LmrupsmUA1TCNvU8JnZJ', 'CEjRQ2AiDJMXZVK3kExF'].includes(
                  recipe.shop_ref.id,
                ))
            ) {
              if (allergen === Allergens.ALMOND) {
                additiveAllergens.add('アーモンド')
              } else if (allergen === Allergens.ABALONE) {
                additiveAllergens.add('あわび')
              } else if (allergen === Allergens.SQUID) {
                additiveAllergens.add('いか')
              } else if (allergen === Allergens.IKURA) {
                additiveAllergens.add('いくら')
              } else if (allergen === Allergens.ORANGE) {
                additiveAllergens.add('オレンジ')
              } else if (allergen === Allergens.CASHEWNUTS) {
                additiveAllergens.add('カシューナッツ')
              } else if (allergen === Allergens.KIWIFRUIT) {
                additiveAllergens.add('キウイフルーツ')
              } else if (allergen === Allergens.BEEF) {
                additiveAllergens.add('牛肉')
              } else if (allergen === Allergens.SESAME) {
                additiveAllergens.add('ごま')
              } else if (allergen === Allergens.SALMON) {
                additiveAllergens.add('鮭')
              } else if (allergen === Allergens.MACKEREL) {
                additiveAllergens.add('鯖')
              } else if (allergen === Allergens.SOY) {
                additiveAllergens.add('大豆')
              } else if (allergen === Allergens.CHICKEN) {
                additiveAllergens.add('鶏肉')
              } else if (allergen === Allergens.BANANA) {
                additiveAllergens.add('バナナ')
              } else if (allergen === Allergens.PORK) {
                additiveAllergens.add('豚肉')
              } else if (allergen === Allergens.MACADAMIANUTS) {
                additiveAllergens.add('マカダミアナッツ')
              } else if (allergen === Allergens.PEACH) {
                additiveAllergens.add('桃')
              } else if (allergen === Allergens.YAM) {
                additiveAllergens.add('山芋')
              } else if (allergen === Allergens.APPLE) {
                additiveAllergens.add('りんご')
              } else if (allergen === Allergens.GELATINE) {
                additiveAllergens.add('ゼラチン')
              }
            }
          }
        })
      }
    }),
  )
  //原材料と添加物を使用割合順に並べ替える
  let materialNames = new Set()
  let additives = ''
  const materialNameInfoList = Object.values(materialNameInfoMap)
  materialNameInfoList.sort((a, b) => {
    return b.percentageOfUse - a.percentageOfUse
  })
  materialNameInfoList.forEach((materialNameInfo, i) => {
    const materialName =
      i === 0
        ? materialNameInfo.materialName +
          '（' +
          materialNameInfo.originName +
          '）'
        : materialNameInfo.materialName
    materialNames.add(materialName)
  })
  const additiveInfoMap2 = Object.values(additiveInfoMap)
  const additiveInfoList: AdditiveInfo[] = []
  additiveInfoMap2.forEach((map) => {
    for (const key in map) {
      additiveInfoList.push(map[key])
    }
  })
  // const additiveInfoList2: AdditiveInfo[] = []
  // const fetchedAdditiveUseIds: string[] = []
  // additiveInfoList.map(async (additiveInfo) => {
  //   if (fetchedAdditiveUseIds.includes(additiveInfo.additiveUseId)) {
  //     //
  //   } else {
  //     additiveInfoList2.push(additiveInfo)
  //   }
  // })
  additiveInfoList.sort((a, b) => {
    // 最初に使用割合順にする
    if (a.percentageOfUse !== b.percentageOfUse) {
      if (b.percentageOfUse > a.percentageOfUse) return 1
      if (b.percentageOfUse < a.percentageOfUse) return -1
    }
    // 次に登録順にする
    if (a.order !== b.order) {
      if (b.order < a.order) return 1
      if (b.order > a.order) return -1
    }
    return 0
  })
  const fetchedAdditiveUseIds: string[] = []
  await Promise.all(
    additiveInfoList.map(async (additiveInfo) => {
      if (!additiveInfo.additiveUseId && !additiveInfo.materialName) {
        return
      }
      if (additiveInfo.additiveUseId) {
        // const additiveUseQuery = doc(
        //   db,
        //   'additive_uses',
        //   additiveInfo.additiveUseId,
        // )
        // const additiveUseQuerySnapshot = await getDoc(additiveUseQuery)
        // const additiveUse = additiveUseQuerySnapshot.data()
        // if (!additiveUse) {
        //   return
        // }
        if (fetchedAdditiveUseIds.includes(additiveInfo.additiveUseId)) {
          if (
            additiveInfo.materialName &&
            additiveInfo.additiveUseDisabledMaterialName === false
          ) {
            if (additives.slice(-2) === '）、') {
              additives =
                additives.slice(0, -2) +
                '、' +
                additiveInfo.materialName +
                '）、'
            } else {
              additives = additives + '（' + additiveInfo.materialName + '）、'
            }
          }
        } else {
          fetchedAdditiveUseIds.push(additiveInfo.additiveUseId)
          additives = additives + additiveInfo.additiveUseName
          if (
            additiveInfo.materialName &&
            additiveInfo.additiveUseDisabledMaterialName === false
          ) {
            additives = additives + '（' + additiveInfo.materialName + '）'
          }
          additives = additives + '、'
        }
      }
      if (!additiveInfo.additiveUseId && additiveInfo.materialName) {
        additives = additives + additiveInfo.materialName + '、'
      }
    }),
  )
  const backSealInformation: BackSealInformation = {
    // エネルギー：整数（小数点第一位四捨五入）
    energy: energy.toFixed(0).toLocaleString(),
    // タンパク質：小数点第一位（小数点第二位四捨五入）
    protein: protein.toFixed(1).toLocaleString(),
    // 脂質：小数点第一位（小数点第二位四捨五入）
    lipid: lipid.toFixed(1).toLocaleString(),
    // 炭水化物：小数点第一位（小数点第二位四捨五入）
    carbohydrate: carbohydrate.toFixed(1).toLocaleString(),
    // 食塩相当量：小数点第二位（小数点第三位四捨五入）
    saltEquivalent: saltEquivalent.toFixed(2).toLocaleString(),
    materialNames: Array.from(materialNames).join('、'),
    additives: additives.slice(0, -1),
    baseAllergens: Array.from(baseAllergens),
    additiveAllergens: Array.from(additiveAllergens),
  }

  // 原価計算
  // let cost: number = 0
  // let recipeCostMap: Map<string, number> = new Map<string, number>()
  // let recipePercentageOfUseMap: Map<string, number> = new Map<string, number>()
  // let isCostCalculated = true
  // const notCostCalculatedReasons: NotCostCalculatedReasons = {}
  // await Promise.all(
  //   allMaterials.map(async (materialInfo) => {
  //     // 一つでも原価不明な原材料があれば算出不可
  //     if (materialInfo.cost === null) {
  //       isCostCalculated = false
  //       // 算出不可理由を出す
  //       if (materialInfo.notCalculatedReasons && materialInfo.materialId) {
  //         notCostCalculatedReasons[materialInfo.materialId] =
  //           materialInfo.notCalculatedReasons
  //       }
  //       return
  //     }
  //     if (materialInfo.callInRecipe && materialInfo.parentRecipeId) {
  //       // あれば加算、なければ初期化
  //       const currentCost = recipeCostMap.get(materialInfo.parentRecipeId)
  //       if (currentCost) {
  //         recipeCostMap.set(
  //           materialInfo.parentRecipeId,
  //           currentCost + materialInfo.cost,
  //         )
  //       } else {
  //         recipeCostMap.set(materialInfo.parentRecipeId, materialInfo.cost)
  //       }
  //       const currentPercentageOfUse = recipePercentageOfUseMap.get(
  //         materialInfo.parentRecipeId,
  //       )
  //       if (currentPercentageOfUse) {
  //         recipePercentageOfUseMap.set(
  //           materialInfo.parentRecipeId,
  //           currentPercentageOfUse + materialInfo.percentageOfUse,
  //         )
  //       } else {
  //         recipePercentageOfUseMap.set(
  //           materialInfo.parentRecipeId,
  //           materialInfo.percentageOfUse,
  //         )
  //       }
  //     } else {
  //       cost += materialInfo.cost
  //     }
  //   }),
  // )
  // const notCostCalculatedRecipeReasons: string[] = []
  // // レシピから呼び出されている原材料は焼減率および焼成後重量を考慮して原価を出す
  // if (recipeCostMap) {
  //   await Promise.all(
  //     Array.from(recipeCostMap.entries()).map(async (recipeCost) => {
  //       const recipeId = recipeCost[0]
  //       const rCost = recipeCost[1]
  //       const q = doc(db, 'recipes', recipeId)
  //       const querySnapshot = await getDoc(q)
  //       const cRecipe = querySnapshot.data()
  //       if (!cRecipe) {
  //         return
  //       }
  //       let isError = false
  //       // 焼成前重量のバリデーションチェックは呼び出し元で行うためコメントアウト
  //       // if (!recipe.amount_dough_before_baking) {
  //       //   notCostCalculatedRecipeReasons.push("レシピの「商品1個あたりの重量(焼成前生地量)」が入力されていません。")
  //       //   isError = true
  //       // }
  //       if (!cRecipe.is_after_baking_percent && !cRecipe.is_after_baking_gram) {
  //         notCostCalculatedRecipeReasons.push(
  //           cRecipe.recipe_name +
  //             'の「焼減率」または「商品１個あたりの焼成後の重量」が入力されていません。',
  //         )
  //         isError = true
  //       }
  //       if (cRecipe.is_after_baking_percent && !cRecipe.burnout_rate) {
  //         notCostCalculatedRecipeReasons.push(
  //           cRecipe.recipe_name + 'の「焼減率」が入力されていません。',
  //         )
  //         isError = true
  //       }
  //       if (
  //         cRecipe.is_after_baking_gram &&
  //         !cRecipe.amount_dough_after_baking
  //       ) {
  //         notCostCalculatedRecipeReasons.push(
  //           cRecipe.recipe_name +
  //             'の「商品１個あたりの焼成後の重量」が入力されていません。',
  //         )
  //         isError = true
  //       }
  //       if (isError) {
  //         return
  //       }
  //       let recipeUnitPrice1g = 0
  //       const lossRate = cRecipe.loss_rate ? cRecipe.loss_rate : 0
  //       if (
  //         cRecipe.is_after_baking_percent &&
  //         cRecipe.amount_dough_before_baking &&
  //         (cRecipe.burnout_rate || cRecipe.burnout_rate === 0)
  //       ) {
  //         // 焼減率
  //         recipeUnitPrice1g =
  //           (rCost * lossRate + rCost) /
  //           (cRecipe.amount_dough_before_baking -
  //             cRecipe.burnout_rate * cRecipe.amount_dough_before_baking)
  //       } else if (
  //         cRecipe.is_after_baking_gram &&
  //         cRecipe.amount_dough_after_baking
  //       ) {
  //         // 焼成後重量
  //         recipeUnitPrice1g =
  //           (rCost * lossRate + rCost) / cRecipe.amount_dough_after_baking
  //       }
  //       const recipePercentageOfUseGetMap =
  //         recipePercentageOfUseMap.get(recipeId)
  //       const recipePercentageOfUse = recipePercentageOfUseGetMap
  //         ? recipePercentageOfUseGetMap
  //         : 1
  //       const recipeGPerPiece =
  //         recipe.amount_dough_before_baking * recipePercentageOfUse
  //       cost += recipeUnitPrice1g * recipeGPerPiece
  //     }),
  //   )
  // }
  // let finalCost: number | null = null
  // if (isCostCalculated) {
  //   finalCost = cost * recipe.loss_rate + cost

  //   const breadQuery = doc(db, 'breads', recipe.bread_ref.id)
  //   const breadQuerySnapshot = await getDoc(breadQuery)
  //   const bread = breadQuerySnapshot.data()
  //   if (bread && bread.packaging_cost) {
  //     finalCost += bread.packaging_cost
  //   }
  // }

  const breadQuery = doc(db, 'breads', recipe.bread_ref.id)
  const breadQuerySnapshot = await getDoc(breadQuery)
  const bread = breadQuerySnapshot.data()
  const finalCostInfo = await getFinalCost(
    allMaterials,
    recipe.amount_dough_before_baking,
    recipe.loss_rate,
    bread && bread.packaging_cost ? bread.packaging_cost : null,
  )

  const detailInfo: BreadDetailInformation = {
    doughs: doughInfos,
    fillings: fillingInfos,
    recipes: recipeInfos,
    allMaterials: allMaterials,
    backSealInformation: backSealInformation,
    costPrice:
      finalCostInfo.finalCost || finalCostInfo.finalCost === 0
        ? Math.floor(finalCostInfo.finalCost)
        : null,
    notCostCalculatedReasons: finalCostInfo.notCostCalculatedReasons,
    notCostCalculatedRecipeReasons: Array.from(
      new Set(finalCostInfo.notCostCalculatedRecipeReasons),
    ),
  }
  return detailInfo
})

export const businessSlice: Slice<BusinessState, {}, 'business'> = createSlice({
  name: 'business',
  initialState,
  reducers: {},
  extraReducers: {
    [getTopBreadIdsRecursivelyById.fulfilled.type]: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      if (action.payload !== null) {
        state.ngBreadIdList = action.payload
      }
    },
  },
})

export default businessSlice.reducer
