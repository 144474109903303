import { FC, useState, useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import ReactToPrint from 'react-to-print'
import styles from 'styles/components/template.module.scss'
import PageHedding from 'components/PageHedding/PageHedding'
import defaultBread from 'images/default-bread.png'
import SubmitButton from 'components/Button/SubmitButton'
import BreadDetailBoxPrimaryV2 from 'components/Table/BreadDetailBoxPrimaryV2'
import AccordionField from 'components/Accordion/Field'
import NumberBox from 'components/Box/NumberBox'
import LossRateBox from 'components/Box/LossRateBox'
import AllergenIconBox, { Allergens } from 'components/Box/AllergenIconBox'
import {
  getBreadById,
  clearBread,
  BreadType,
  BackingStickerStatus,
  BreadStatus,
} from 'reducks/bread/slice'
import {
  getRecipeByBreadId,
  RecipeType,
  clearRecipe,
} from 'reducks/recipe/slice'
import {
  getMaterialInfomationByBreadId,
  BreadDetailInformationType,
} from 'reducks/business/slice'
import { setIsLoading } from 'reducks/loading/slice'
import { RootState } from 'reducks/reducers'
import { TAX } from 'Constants'

const BreadDetailTemplate: FC = () => {
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const location = useLocation()
  const componentRef = useRef<HTMLDivElement>(null)
  // 更新の場合か確認
  const path = location.pathname
  const breadId = path.split('/bread/detail/')[1]
    ? path.split('/bread/detail/')[1]
    : null
  const [breadName, setBreadName] = useState<string>('')
  const [breadThumbnail, setBreadThumbnail] = useState<string>('')
  const [breadBackingStickerStatus, setBreadBackingStickerStatus] =
    useState<BackingStickerStatus>(BackingStickerStatus.NG)
  const [breadStatus, setBreadStatus] = useState<BreadStatus>(BreadStatus.DRAFT)
  const [breadRecipeStatus, setBreadRecipeStatus] = useState<BreadStatus>(
    BreadStatus.DRAFT,
  )
  const [energy, setEnergy] = useState<string>('')
  const [protein, setProtein] = useState<string>('')
  const [lipid, setLipid] = useState<string>('')
  const [carbohydrate, setCarbohydrate] = useState<string>('')
  const [saltEquivalent, setSaltEquivalent] = useState<string>('')
  const [materialNames, setMaterialNames] = useState<string>('')
  const [additives, setAdditives] = useState<string>('')
  const [allergens, setAllergens] = useState<string[]>([])
  const [petternInfo, setPetternInfo] = useState<BreadDetailInformationType>({
    recipes: null,
    doughs: null,
    fillings: null,
    allMaterials: [],
    backSealInformation: {
      energy: '',
      protein: '',
      lipid: '',
      carbohydrate: '',
      saltEquivalent: '',
      materialNames: '',
      additives: '',
      baseAllergens: [],
      additiveAllergens: [],
    },
    costPrice: null,
    notCostCalculatedReasons: {},
    notCostCalculatedRecipeReasons: [],
  })
  const [memo, setMemo] = useState<string>('')
  const [process, setProcess] = useState<string>('')
  const [sellPrice, setSellPrice] = useState<string | null>(null)
  const [costPrice, setCostPrice] = useState<string | null>(null)
  const [packagingCostPrice, setPackagingCostPrice] = useState<string | null>(
    null,
  )
  const [isCostCalculating, setIsCostCalculating] = useState<boolean>(false)
  const [amountDoughBeforeBaking, setAmountDoughBeforeBaking] = useState<
    string | null
  >(null)
  const [lossRate, setLossRate] = useState<number>(0)

  const { staff } = useSelector((state: RootState) => state.staffSlice)
  const initBreadInfo = useCallback(
    async (breadId: string | null) => {
      if (!staff) {
        navigate('/initload?path=' + path)
        window.scrollTo(0, 0)
        return
      }
      dispatch(setIsLoading({ isLoading: true }))
      await dispatch(clearBread())
      await dispatch(clearRecipe())
      if (!breadId) {
        // 商品IDが取得できない場合はTOPに飛ばす
        navigate('/initload')
        window.scrollTo(0, 0)
        return
      }
      const breadPayload = await dispatch(getBreadById({ id: breadId }))
      const bread: BreadType = breadPayload.payload
      if (!bread) {
        // 商品が取得できない場合はTOPに飛ばす
        navigate('/initload')
        window.scrollTo(0, 0)
        return
      }
      setBreadName(bread.name)
      setBreadThumbnail(bread.image_url)
      setBreadBackingStickerStatus(bread.backing_sticker_status)
      setBreadStatus(bread.daseruno_status)
      setBreadRecipeStatus(bread.daseruno_recipe_status)
      setSellPrice(bread.selling_price ? String(bread.selling_price) : null)
      setCostPrice(bread.cost_price ? String(bread.cost_price) : null)
      setPackagingCostPrice(
        bread.packaging_cost ? String(bread.packaging_cost) : null,
      )
      setIsCostCalculating(
        bread.is_cost_calculating ? bread.is_cost_calculating : false,
      )
      const infoPayload = await dispatch(
        getMaterialInfomationByBreadId({ breadId: breadId }),
      )
      const info: BreadDetailInformationType | null = infoPayload.payload
      if (info) {
        console.log(info)
        setPetternInfo(info)
        setEnergy(info.backSealInformation.energy)
        setProtein(info.backSealInformation.protein)
        setLipid(info.backSealInformation.lipid)
        setCarbohydrate(info.backSealInformation.carbohydrate)
        setSaltEquivalent(info.backSealInformation.saltEquivalent)
        setMaterialNames(info.backSealInformation.materialNames)
        setAdditives(info.backSealInformation.additives)
        // 原材料と添加物のアレルゲンは一緒に表示する
        const allergens: string[] = Array.from(
          new Set([
            ...info.backSealInformation.baseAllergens,
            ...info.backSealInformation.additiveAllergens,
          ]),
        )
        console.log(allergens)
        setAllergens(allergens)
      }
      const recipePayload = await dispatch(getRecipeByBreadId({ id: breadId }))
      const recipe: RecipeType = recipePayload.payload
      if (recipe) {
        setMemo(recipe.memo)
        setProcess(recipe.process)
        setAmountDoughBeforeBaking(
          recipe.amount_dough_before_baking
            ? String(recipe.amount_dough_before_baking)
            : null,
        )
        setLossRate(recipe.loss_rate)
      }
      dispatch(setIsLoading({ isLoading: false }))
    },
    [breadId],
  )

  useEffect(() => {
    initBreadInfo(breadId)
  }, [])

  return (
    <div className={styles.bread_detail_container} ref={componentRef}>
      <div className={styles.container} ref={componentRef}>
        <PageHedding title={breadName} to="/initload" />
        <div className={styles.bread_detail_display_unit}>
          <NumberBox
            list={[
              {
                main: true,
                label: '売価',
                num: sellPrice ? Math.floor(Number(sellPrice) * TAX) : null,
                unit: '円',
                annotation: '(税込)',
                alert: sellPrice === null,
                isCostCalculating: false,
              },
            ]}
            alert={sellPrice === null}
          />
          <div className={styles.bread_detail_space}></div>
          <NumberBox
            list={[
              {
                main: true,
                label: '原価',
                num:
                  costPrice && packagingCostPrice
                    ? Number(costPrice) + Number(packagingCostPrice)
                    : costPrice
                    ? Number(costPrice)
                    : null,
                unit: '円',
                annotation: '(税込)',
                alert: costPrice === null,
                isCostCalculating: isCostCalculating,
              },
              {
                main: false,
                label: '材料費',
                num: costPrice ? Number(costPrice) : null,
                unit: '円',
                annotation: '(税込)',
                alert: false,
                isCostCalculating:
                  costPrice === null ? false : isCostCalculating,
              },
              {
                main: false,
                label: '包材費',
                num: packagingCostPrice ? Number(packagingCostPrice) : null,
                unit: '円',
                annotation: '(税込)',
                alert: false,
                isCostCalculating: false,
              },
            ]}
            alert={costPrice === null}
          />
          <div className={styles.bread_detail_space}></div>
          <NumberBox
            list={[
              {
                main: true,
                label: '原価率',
                num:
                  sellPrice && costPrice
                    ? Math.floor(
                        (Number(costPrice) / (Number(sellPrice) * TAX)) * 100,
                      )
                    : null,
                unit: '%',
                annotation: null,
                alert: sellPrice === null || costPrice === null,
                isCostCalculating: false,
              },
            ]}
            alert={sellPrice === null || costPrice === null}
          />
          <div className={styles.bread_detail_space}></div>
          <NumberBox
            list={[
              {
                main: true,
                label: '重量',
                num: amountDoughBeforeBaking
                  ? Number(amountDoughBeforeBaking)
                  : null,
                unit: 'g',
                annotation: null,
                alert: amountDoughBeforeBaking === null,
                isCostCalculating: false,
              },
            ]}
            alert={amountDoughBeforeBaking === null}
          />
          <div className={styles.bread_detail_space_wide}></div>
          <ReactToPrint
            trigger={() => (
              <SubmitButton
                label="このページを印刷する"
                color="orange_outlined"
                size="large"
                icon="none"
                onClick={() => {}}
              />
            )}
            content={() => componentRef.current}
          />
        </div>
        <div className={styles.bread_detail_summary}>
          <div>
            <div className={styles.bread_detail_summary_thumbnail}>
              <img
                className={styles.bread_detail_summary_thumbnail}
                src={breadThumbnail ? breadThumbnail : defaultBread}
                alt="商品画像"
              ></img>
            </div>
            <div>
              <LossRateBox label="ロス率" value={lossRate * 100} unit="%" />
            </div>
            {petternInfo.recipes &&
              petternInfo.recipes.map((recipe) => (
                <>
                  <div>
                    <LossRateBox
                      label={
                        recipe.is_after_baking_percent
                          ? recipe.productName + 'の焼減率'
                          : recipe.productName + 'の焼成後重量'
                      }
                      value={
                        recipe.is_after_baking_percent && recipe.burnout_rate
                          ? recipe.burnout_rate * 100
                          : recipe.is_after_baking_gram &&
                            recipe.amount_dough_after_baking
                          ? recipe.amount_dough_after_baking
                          : 0
                      }
                      unit={recipe.is_after_baking_percent ? '%' : 'g'}
                    />
                  </div>
                </>
              ))}
          </div>
          <div className={styles.bread_detail_summary_info}>
            <div className={styles.bread_detail_summary_info_main}>
              <div className={styles.bread_detail_summary_info_main_head}>
                <span>栄養成分表示（100gあたり）※推定値</span>
              </div>
              <div className={styles.bread_detail_summary_info_main_body}>
                <span>
                  エネルギー:{energy}kcal / タンパク質:{protein}g / 脂質:{lipid}
                  g / 炭水化物:{carbohydrate}g / 食塩相当量:{saltEquivalent}g
                </span>
              </div>
            </div>
            <div className={styles.bread_detail_summary_info_main}>
              <div className={styles.bread_detail_summary_info_main_head2}>
                <span>アレルギー</span>
              </div>
              <div className={styles.bread_detail_summary_info_main_body2}>
                <AllergenIconBox
                  active={allergens.includes('小麦')}
                  allergen={Allergens.WHEAT}
                  label="小麦"
                />
                <AllergenIconBox
                  active={allergens.includes('卵')}
                  allergen={Allergens.EGG}
                  label="卵"
                />
                <AllergenIconBox
                  active={allergens.includes('乳成分')}
                  allergen={Allergens.MILK}
                  label="乳成分"
                />
                <AllergenIconBox
                  active={allergens.includes('えび')}
                  allergen={Allergens.SHRIMP}
                  label="えび"
                />
                <AllergenIconBox
                  active={allergens.includes('かに')}
                  allergen={Allergens.CRUB}
                  label="かに"
                />
                <AllergenIconBox
                  active={allergens.includes('そば')}
                  allergen={Allergens.SOBA}
                  label="そば"
                />
                <AllergenIconBox
                  active={allergens.includes('落花生')}
                  allergen={Allergens.PEANUTS}
                  label="落花生"
                />
                <AllergenIconBox
                  active={allergens.includes('くるみ')}
                  allergen={Allergens.WALNUT}
                  label="くるみ"
                />
              </div>
            </div>
            <div className={styles.bread_detail_summary_info_sub}>
              <div className={styles.bread_detail_summary_info_sub_box}>
                <div className={styles.bread_detail_summary_info_sub_box_pad}>
                  <div
                    className={styles.bread_detail_summary_info_sub_box_head}
                  >
                    <span>添加物</span>
                  </div>
                  <div
                    className={styles.bread_detail_summary_info_sub_box_body}
                  >
                    <span>{additives}</span>
                  </div>
                </div>
              </div>
              <div className={styles.bread_detail_summary_info_sub_box}>
                <div className={styles.bread_detail_summary_info_sub_box_pad}>
                  <div
                    className={styles.bread_detail_summary_info_sub_box_head}
                  >
                    <span>原材料</span>
                  </div>
                  <div
                    className={styles.bread_detail_summary_info_sub_box_body}
                  >
                    <span>{materialNames}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bread_detail_table}>
          {petternInfo.recipes && petternInfo.recipes.length > 0 ? (
            <>
              <div className={styles.bread_detail_table_tab}>商品</div>
              <>
                {petternInfo.recipes.map((recipe) => (
                  <>
                    <div className={styles.bread_detail_table_container}>
                      <BreadDetailBoxPrimaryV2 petternInformation={recipe} />
                    </div>
                    <div
                      className={styles.bread_detail_table_margin_bottom}
                    ></div>
                  </>
                ))}
              </>
            </>
          ) : (
            ''
          )}
          {petternInfo.doughs && petternInfo.doughs.length > 0 ? (
            <>
              <div className={styles.bread_detail_table_tab}>生地</div>
              {petternInfo.doughs.map((dough) => (
                <>
                  <div className={styles.bread_detail_table_container}>
                    <BreadDetailBoxPrimaryV2 petternInformation={dough} />
                  </div>
                  <div
                    className={styles.bread_detail_table_margin_bottom}
                  ></div>
                </>
              ))}
            </>
          ) : (
            ''
          )}
          {petternInfo.fillings && petternInfo.fillings.length > 0 ? (
            <>
              <div className={styles.bread_detail_table_tab}>フィリング</div>
              <>
                {petternInfo.fillings.map((filling) => (
                  <>
                    <div className={styles.bread_detail_table_container}>
                      <BreadDetailBoxPrimaryV2 petternInformation={filling} />
                    </div>
                    <div
                      className={styles.bread_detail_table_margin_bottom}
                    ></div>
                  </>
                ))}
              </>
            </>
          ) : (
            ''
          )}
        </div>
        <div className={styles.bread_detail_memo}>
          <AccordionField label="メモ" text={memo} />
          <div className={styles.bread_detail_memo_space}></div>
          <AccordionField label="工程" text={process} />
        </div>
        <div className={styles.bread_detail_foot}>
          <div className={styles.bread_detail_foot_button_small}>
            <SubmitButton
              label="編集する"
              color="orange"
              size="xsmall"
              icon="edit"
              onClick={() => navigate('/bread/edit/' + breadId)}
            />
            <SubmitButton
              label="複製する"
              color="orange"
              size="xsmall"
              icon="copy"
              onClick={() => navigate('/bread/copy/' + breadId)}
            />
          </div>
          <div className={styles.bread_detail_foot_button_middle}>
            {breadBackingStickerStatus === BackingStickerStatus.OK &&
            breadStatus === BreadStatus.PUBLIC &&
            breadRecipeStatus === BreadStatus.PUBLIC ? (
              <SubmitButton
                label="裏貼りシールを印刷する"
                color="orange"
                size="xmiddle"
                icon="none"
                onClick={() =>
                  navigate('/food_nutrition_label?breadIds=' + breadId)
                }
              />
            ) : (
              <SubmitButton
                label="裏貼りシールを印刷する"
                color="gray"
                size="xmiddle"
                icon="none"
                onClick={() => {}}
              />
            )}
            <ReactToPrint
              trigger={() => (
                <SubmitButton
                  label="このページを印刷する"
                  color="orange_outlined"
                  size="xmiddle"
                  icon="none"
                  onClick={() => {}}
                />
              )}
              content={() => componentRef.current}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BreadDetailTemplate
