import { FC, useState, useEffect, useCallback, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import styles from 'styles/components/template.module.scss'
import imageCloseIcon from 'images/ImagePreview/image-close-icon.png'
import PageHedding from 'components/PageHedding/PageHedding'
import InputTextWithLightLabel from 'components/Form/WithLightLabel/InputText'
import accountIcon from 'images/account-icon.png'
import FileUploadButton from 'components/Button/FileUploadButton'
import SubmitButton from 'components/Button/SubmitButton'
import { changeEmail, changePassword } from 'reducks/auth/slice'
import {
  uploadStaffImage,
  removeStaffImage,
  updateImageUrl,
  getStaff,
} from 'reducks/staff/slice'
import { RootState } from 'reducks/reducers'
import { setIsLoading } from 'reducks/loading/slice'

const AccountTemplate: FC = () => {
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const location = useLocation()
  const path = location.pathname
  const [accountImage, setAccountImage] = useState<File | null>(null)
  const [accountImageUrl, setAccountImageUrl] = useState<string>('')
  const [accountImageChanged, setAccountImageChanged] = useState<boolean>(false)
  const [mailAddress, setMailAddress] = useState<string>('')
  const [currentPassword, setCurrentPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>('')
  const [isPreview, setIsPreview] = useState(false)
  const [isError, setIsError] = useState(false)

  const handleMailAddress = (mailAddress: string) => {
    setMailAddress(mailAddress)
  }
  const handleCurrentPassword = (currentPassword: string) => {
    setCurrentPassword(currentPassword)
  }
  const handleNewPassword = (newPassword: string) => {
    setNewPassword(newPassword)
  }
  const handleNewPasswordConfirm = (newPasswordConfirm: string) => {
    setNewPasswordConfirm(newPasswordConfirm)
  }

  const changeFileHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target?.files && event.target.files.length > 0) {
        setAccountImageChanged(true)
        let newImage: File = event.target.files[0]
        if (
          [
            'image/jpeg',
            'image/png',
            'image/heif',
            'image/heif-sequence',
          ].indexOf(newImage.type) < 0
        ) {
          setAccountImageErrorText('対応形式でない画像はアップロードできません')
          return
        } else if (newImage.size > 10485760) {
          setAccountImageErrorText(
            '容量の大きすぎる画像はアップロードできません',
          )
          return
        }
        setAccountImageErrorText(null)
        setAccountImage(newImage)
        event.target.value = ''

        let reader: FileReader | null = new FileReader()
        reader.onloadend = () => {
          const res = reader ? reader!.result : null
          if (res && typeof res === 'string') {
            setAccountImageUrl(res)
          }
        }
        reader.readAsDataURL(newImage)
      }
    },
    [accountImage],
  )

  const resetHandler = () => {
    setAccountImageChanged(true)
    setAccountImage(null)
    setAccountImageUrl('')
    setAccountImageErrorText(null)
  }

  const { staff } = useSelector((state: RootState) => state.staffSlice)
  const initInfo = async () => {
    if (!staff) {
      navigate('/initload?path=' + path)
      window.scrollTo(0, 0)
      return
    }
    setMailAddress(staff.email)
    if (staff.imageUrl) {
      const response = await fetch(staff.imageUrl)
      const blob = await response.blob()
      const file = new File([blob], 'image.jpg')
      setAccountImage(file)
      setAccountImageUrl(staff.imageUrl)
    }
  }

  useEffect(() => {
    initInfo()
  }, [staff])

  const [mailAddressErrorText, setMailAddressErrorText] = useState<
    string | null
  >(null)
  const [currentPasswordErrorText, setCurrentPasswordErrorText] = useState<
    string | null
  >(null)
  const [newPasswordErrorText, setNewPasswordErrorText] = useState<
    string | null
  >(null)
  const [newPasswordConfirmErrorText, setNewPasswordConfirmErrorText] =
    useState<string | null>(null)
  const [accountImageErrorText, setAccountImageErrorText] = useState<
    string | null
  >(null)
  const [errorText, setErrorText] = useState<string | null>(null)

  const validatePassword = (password: string) => {
    if (
      !password.match(/^[A-Za-z0-9]*$/) ||
      password.length < 8 ||
      password.length > 16
    ) {
      return false
    }
    return true
  }

  const validateMailAddress = (mailAddress: string) => {
    if (
      !mailAddress.match(
        /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/,
      ) ||
      mailAddress.length > 255
    ) {
      return false
    }
    return true
  }

  const handleSubmit = async () => {
    if (!staff) {
      return
    }
    setCurrentPasswordErrorText('')
    setNewPasswordErrorText('')
    setNewPasswordConfirmErrorText('')
    setErrorText('')
    // メールアドレス変更、パスワード変更ともに現在のパスワードが必要
    if (!currentPassword) {
      setCurrentPasswordErrorText('入力されておりません')
      return
    }
    if ((!mailAddress || mailAddress === staff.email) && !newPassword) {
      setErrorText(
        'メールアドレスの変更もしくは新しいパスワードを設定してください',
      )
      return
    }
    if (newPassword || newPasswordConfirm) {
      // 新しいパスワードが入力されている場合、確認も含めて入力されている必要がある
      if (!newPassword) {
        setNewPasswordErrorText('入力されておりません')
        return
      } else if (!newPasswordConfirm) {
        setNewPasswordConfirmErrorText('入力されておりません')
        return
      } else {
        let isError = false
        if (!validatePassword(newPassword)) {
          setNewPasswordErrorText(
            '半角英数字8文字以上16文字以内で入力してください',
          )
          isError = true
        }
        if (isError) {
          return
        }
        if (newPassword !== newPasswordConfirm) {
          setNewPasswordConfirm('')
          setNewPasswordConfirmErrorText(
            '新しいパスワードと同じ内容を入力してください',
          )
          return
        }
        dispatch(setIsLoading({ isLoading: true }))
        const status = await dispatch(
          changePassword({
            newPassword: newPassword,
            password: currentPassword,
          }),
        )
        if (status.payload === 'failed') {
          setIsError(true)
          dispatch(setIsLoading({ isLoading: false }))
          return
        }
      }
    }
    if (mailAddress && mailAddress !== staff.email) {
      if (!validateMailAddress(mailAddress)) {
        setMailAddressErrorText('メールアドレスの形式で入力してください')
        return
      }
      // 新しいメールアドレスが設定されている場合
      await dispatch(setIsLoading({ isLoading: true }))
      const status = await dispatch(
        changeEmail({ newEmail: mailAddress, password: currentPassword }),
      )
      if (status.payload === 'failed') {
        setIsError(true)
        dispatch(setIsLoading({ isLoading: false }))
        return
      }
    }
    // 画像が変更されている場合Storageにアップロード
    if (accountImageChanged) {
      if (accountImage !== null) {
        dispatch(setIsLoading({ isLoading: true }))
        const imageUrl = staff.imageUrl
        const uploadResult = await dispatch(
          uploadStaffImage({
            image: accountImage,
          }),
        )
        // Firestoreの画像パスを更新
        await dispatch(
          updateImageUrl({
            image_url: uploadResult.payload,
          }),
        )
        if (imageUrl) {
          // 古い画像がある場合はstorageから削除
          await dispatch(
            removeStaffImage({
              image_url: imageUrl,
            }),
          )
        }
      } else {
        // accountImageがnullの場合はstorageから画像を削除
        if (staff.imageUrl) {
          dispatch(setIsLoading({ isLoading: true }))
          await dispatch(
            removeStaffImage({
              image_url: staff.imageUrl,
            }),
          )
          // Firestoreの画像パスを更新
          await dispatch(
            updateImageUrl({
              image_url: '',
            }),
          )
        }
      }
    }
    // staffのstoreを更新
    await dispatch(getStaff({ uid: staff.uid }))
    setIsPreview(true)
    dispatch(setIsLoading({ isLoading: false }))
  }

  return (
    <div className={styles.container}>
      <PageHedding title="ユーザー設定" to="/mypage" />
      <div className={styles.account}>
        <div>
          <div className={styles.account_icon}>
            <img
              className={styles.account_icon_image}
              src={accountImageUrl ? accountImageUrl : accountIcon}
              alt="ユーザーアカウント"
            ></img>
            {accountImageUrl ? (
              <button
                className={styles.account_image_delete_button}
                type="button"
                onClick={resetHandler}
              >
                <img
                  className={styles.account_close_icon}
                  src={imageCloseIcon}
                  alt={'x'}
                />
              </button>
            ) : (
              ''
            )}
          </div>
          <div className={styles.account_upload}>
            <FileUploadButton
              label="画像をアップロード"
              description=""
              onChange={changeFileHandler}
            />
            <p className={styles.account_upload_text}>
              ( JPEG / PNG / HEIC 10MB以下 )
            </p>
            <div className={styles.account_upload_error_text}>
              {accountImageErrorText}
            </div>
          </div>
          <div className={styles.account_form}>
            <InputTextWithLightLabel
              labelName="メールアドレス"
              description=""
              line={1}
              type="text"
              size="semilarge"
              value={mailAddress}
              placeholder=""
              errorText={mailAddressErrorText}
              onChange={(event) => handleMailAddress(event.target.value)}
            />
            <InputTextWithLightLabel
              labelName="現在のパスワード"
              description=""
              line={1}
              type="password"
              size="semilarge"
              value={currentPassword}
              placeholder=""
              errorText={currentPasswordErrorText}
              onChange={(event) => handleCurrentPassword(event.target.value)}
            />
            <InputTextWithLightLabel
              labelName="新しいパスワード"
              description=""
              line={1}
              type="password"
              size="semilarge"
              value={newPassword}
              placeholder=""
              errorText={newPasswordErrorText}
              onChange={(event) => handleNewPassword(event.target.value)}
            />
            <InputTextWithLightLabel
              labelName="新しいパスワード(再入力)"
              description=""
              line={1}
              type="password"
              size="semilarge"
              value={newPasswordConfirm}
              placeholder=""
              errorText={newPasswordConfirmErrorText}
              onChange={(event) => handleNewPasswordConfirm(event.target.value)}
            />
          </div>
          <div className={styles.account_error_text}>{errorText || ''}</div>
          <span className={styles.account_note}>
            ご注意：半角英数字8文字以上16文字以内で入力ください。（空白および ¥
            は使用できません。）
          </span>
          <div className={styles.account_button}>
            <SubmitButton
              label="戻る"
              color="gray"
              size="large"
              icon="none"
              onClick={() => navigate('/mypage')}
            />
            <span className={styles.breadAddButtonSpacer}></span>
            <SubmitButton
              label="編集する"
              color="orange"
              size="large"
              icon="plus"
              onClick={() => handleSubmit()}
            />
          </div>
        </div>
      </div>
      {isPreview && (
        <div className={styles.modal_account}>
          <div className={styles.modal_account_text}>
            アカウント情報の更新が完了しました
          </div>
          <div className={styles.modalButtonContainer}>
            <SubmitButton
              label="マイページに戻る"
              color="orange"
              size="xmiddle"
              icon="none"
              onClick={() => navigate('/mypage')}
            />
          </div>
        </div>
      )}
      {isError && (
        <div className={styles.modal_account}>
          <div className={styles.modal_account_text}>
            {`アカウント情報の更新に失敗しました\n現在のパスワードが違っている可能性があります`}
          </div>
          <div className={styles.modalButtonContainer}>
            <SubmitButton
              label="戻る"
              color="orange"
              size="xmiddle"
              icon="none"
              onClick={() => setIsError(false)}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default AccountTemplate
