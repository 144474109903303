import { FC } from 'react'
import styles from 'styles/components/box.module.scss'
import alertIcon from 'images/alert2-icon.png'

type Props = {
  alert: boolean
  list: BoxParam[]
}

type BoxParam = {
  main: boolean
  label: string
  num: number | null
  unit: string
  annotation: string | null
  alert: boolean
  isCostCalculating: boolean
}

const NumberBox: FC<Props> = (props) => {
  const { alert, list } = props

  return (
    <>
      <div className={alert ? styles.number_box_alert : styles.number_box}>
        {list.map((box) => (
          <div
            key={box.label}
            className={
              box.alert
                ? styles.number_box_container_red
                : box.main
                ? styles.number_box_container_gray
                : styles.number_box_container
            }
          >
            <div
              className={
                box.alert
                  ? styles.number_box_container_label_white
                  : box.num === null
                  ? styles.number_box_container_label_red
                  : styles.number_box_container_label
              }
            >
              {box.label}
            </div>
            {box.alert ? (
              <>
                <img
                  className={styles.number_box_container_alert_image}
                  src={alertIcon}
                  alt="注意"
                ></img>
              </>
            ) : box.isCostCalculating ? (
              <>
                <div className={styles.number_box_container_flex}>
                  <div
                    className={
                      box.num === null && box.main
                        ? styles.number_box_container_num_white
                        : box.num === null
                        ? styles.number_box_container_num_red
                        : styles.number_box_container_num
                    }
                  >
                    算出中
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={styles.number_box_container_flex}>
                  <div
                    className={
                      box.num === null
                        ? styles.number_box_container_num_red
                        : styles.number_box_container_num
                    }
                  >
                    {box.num !== null ? box.num.toLocaleString() : '-'}
                  </div>
                  <div
                    className={
                      box.num === null
                        ? styles.number_box_container_unit_red
                        : styles.number_box_container_unit
                    }
                  >
                    {box.unit}
                  </div>
                </div>
                <div
                  className={
                    box.num === null
                      ? styles.number_box_container_annotation_red
                      : styles.number_box_container_annotation
                  }
                >
                  {box.annotation !== null ? box.annotation : ''}
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </>
  )
}

export default NumberBox
