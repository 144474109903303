import { FC } from 'react'
import styles from 'styles/components/form.module.scss'

type Props = {
  size: string
  value: string | number
  options: SelectOption[]
  displayNone?: boolean
  errorText?: string | null
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
  onFocus?: (event: React.FocusEvent<HTMLSelectElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void
  containerStyle?: React.CSSProperties
  style?: React.CSSProperties
  errorTextStyle?: React.CSSProperties
}

export type SelectOption = {
  is_default?: boolean
  label: string
  color?: string
  filter?: string
  value: string | number
}

const SelectBox: FC<Props> = (props) => {
  const {
    size,
    value,
    options,
    displayNone,
    errorText,
    onChange,
    onFocus,
    onBlur,
    containerStyle,
    style,
    errorTextStyle,
  } = props

  const errorStyle = (() => {
    return errorText ? styles.selectbox_error : styles.selectbox
  })()

  const sizeStyle = (() => {
    switch (size) {
      case 'large':
        return styles.selectbox_large
      case 'large2':
        return styles.selectbox_large2
      case 'xlarge':
        return styles.selectbox_xlarge
      case 'middle':
        return styles.selectbox_middle
      case 'small':
        return styles.selectbox_small
      default:
        return styles.selectbox_large
    }
  })()

  const displayStyle = (() => {
    return displayNone ? styles.selectbox_display_none : ''
  })()

  const className = `${errorStyle} ${sizeStyle} ${displayStyle}`

  return (
    <div style={containerStyle}>
      <select
        className={className}
        style={style}
        value={value}
        onChange={(e) => (onChange ? onChange(e) : null)}
        onFocus={(e) => (onFocus ? onFocus(e) : null)}
        onBlur={(e) => (onBlur ? onBlur(e) : null)}
      >
        {options.map((option) => (
          <option key={option.label} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {errorText ? (
        <p className={styles.selectbox_error_text} style={errorTextStyle}>
          {errorText}
        </p>
      ) : (
        ''
      )}
    </div>
  )
}

export default SelectBox
