import { FC, useState, useEffect } from 'react'
import styles from 'styles/components/table.module.scss'
import BreadDetailRowV2 from 'components/Table/BreadDetailRowV2'
import BreadDetailInputTextRowV2 from 'components/Table/BreadDetailInputTextRowV2'
import BreadDetailAddButtonV2 from 'components/Table/BreadDetailAddButtonV2'
import {
  BreadDetailPetternInformationType,
  DataType,
  UnitType,
} from 'reducks/business/slice'
import plusIcon from 'images/Accordion/plus-icon.png'
import minusIcon from 'images/Accordion/minus-icon.png'
import terminal from 'images/table/terminal.png'
import jump from 'images/table/jump.png'

type Props = {
  petternInformation: BreadDetailPetternInformationType
  level: number
  isTerminal: boolean | null
  editable?: boolean
  handleAddButton?: (
    dataType: DataType | undefined,
    params: (number | DataType | boolean)[] | undefined,
    unitType: UnitType.GRAM | UnitType.PERCENT,
  ) => void
  handleEditRecord?: (
    dataType: DataType | undefined,
    key:
      | 'productName'
      | 'manufacturerName'
      | 'materialId'
      | 'middleKneadPercent'
      | 'authenticKneadPercent'
      | 'supplierCostPrice'
      | 'supplierTax'
      | 'internalCapacityMemo'
      | 'yieldRate'
      | 'internalCapacityCount'
      | 'internalCapacityUnit'
      | 'numberItem'
      | 'numberItemUnit'
      | 'unitPrice1g'
      | 'cost',
    value: any,
    params: (number | DataType | boolean)[] | undefined,
    inputPetternInfos?: BreadDetailPetternInformationType[] | null,
  ) => BreadDetailPetternInformationType[]
  handleDeleteButton?: (
    dataType: DataType | undefined,
    params: (number | DataType | boolean)[] | undefined,
  ) => void
  handleIsPreview?: (
    dataType: DataType | undefined,
    params: (number | DataType | boolean)[] | undefined,
  ) => void
  dataType?: DataType
  params?: (number | DataType | boolean)[]
  isOpen?: boolean
}

const BreadDetailBoxSecondaryV2: FC<Props> = (props) => {
  const {
    petternInformation,
    level,
    isTerminal,
    editable,
    handleAddButton,
    handleEditRecord,
    handleDeleteButton,
    handleIsPreview,
    dataType,
    params,
    isOpen,
  } = props
  const [open, setOpen] = useState<boolean>(false)

  const handleOpen = () => {
    // はじめに表示されるものが原材料であれば開閉アクションしない
    if (petternInformation.dataType === DataType.MATERIAL) {
      return
    }
    setOpen(!open)
  }

  useEffect(() => {
    if (isOpen) {
      handleOpen()
    }
  }, [isOpen])

  return (
    <>
      {
        // 最上層のレシピ or 生地パターン or フィリングパターン or 原材料
      }
      <div
        className={styles.bread_detail_primary_header_orange}
        onClick={() => setOpen(!open)}
      >
        {level > 0 &&
          [...Array(level)].map((_) => (
            <div className={styles.bread_detail_paragraph_down}></div>
          ))}
        {level > 0 ? (
          <div>
            <img
              className={styles.bread_detail_terminal_icon}
              src={isTerminal ? terminal : jump}
              alt={''}
            ></img>
          </div>
        ) : (
          ''
        )}
        <div>
          <img
            className={styles.bread_detail_open_close_icon}
            src={open ? minusIcon : plusIcon}
            alt={open ? '-' : '+'}
          ></img>
        </div>
        <div className={styles.bread_detail_paragraph_down_large3}></div>
        <WrapedBreadDetailRowV2
          petternInformation={petternInformation}
          isBold={true}
          nameMinusWidth={level * 12 + 36}
          isSimulation={editable}
          unitPriceInvisible={true}
        />
      </div>
      {
        // レシピ原料のうちパターンのものを表示
      }
      {open && petternInformation.recipes
        ? petternInformation.recipes
            .filter((recipe) => !recipe.materialId)
            .map((recipe, i) => (
              <BreadDetailBoxSecondaryV2
                petternInformation={recipe}
                level={level + 1}
                isTerminal={
                  petternInformation.recipes &&
                  i ===
                    petternInformation.recipes.filter(
                      (recipe) => !recipe.materialId,
                    ).length -
                      1
                }
                editable={editable}
                handleAddButton={handleAddButton}
                handleEditRecord={handleEditRecord}
                handleDeleteButton={handleDeleteButton}
                handleIsPreview={handleIsPreview}
                dataType={dataType}
                params={params ? [...params, DataType.RECIPE, false, i] : []}
                isOpen={isOpen}
              />
            ))
        : ''}
      {
        // 生地原料のうちパターンのものを表示
      }
      {open && petternInformation.doughs
        ? petternInformation.doughs
            .filter((dough) => !dough.materialId)
            .map((dough, i) => (
              <BreadDetailBoxSecondaryV2
                petternInformation={dough}
                level={level + 1}
                isTerminal={
                  petternInformation.doughs &&
                  i ===
                    petternInformation.doughs.filter(
                      (dough) => !dough.materialId,
                    ).length -
                      1
                }
                editable={editable}
                handleAddButton={handleAddButton}
                handleEditRecord={handleEditRecord}
                handleDeleteButton={handleDeleteButton}
                handleIsPreview={handleIsPreview}
                dataType={dataType}
                params={params ? [...params, DataType.DOUGH, false, i] : []}
                isOpen={isOpen}
              />
            ))
        : ''}
      {
        // フィリング原料のうちパターンのものを表示
      }
      {open && petternInformation.fillings
        ? petternInformation.fillings
            .filter((filling) => !filling.materialId)
            .map((filling, i) => (
              <BreadDetailBoxSecondaryV2
                petternInformation={filling}
                level={level + 1}
                isTerminal={
                  petternInformation.fillings &&
                  i ===
                    petternInformation.fillings.filter(
                      (filling) => !filling.materialId,
                    ).length -
                      1
                }
                editable={editable}
                handleAddButton={handleAddButton}
                handleEditRecord={handleEditRecord}
                handleDeleteButton={handleDeleteButton}
                handleIsPreview={handleIsPreview}
                dataType={dataType}
                params={params ? [...params, DataType.FILLING, false, i] : []}
                isOpen={isOpen}
              />
            ))
        : ''}
      {
        // レシピ原料のうち原材料のみを表示
      }
      {open && petternInformation.recipes
        ? petternInformation.recipes
            .filter((recipe) => recipe.materialId)
            .map((recipe, i) => (
              <div className={open ? styles.bread_detail_primary_header : ''}>
                <div
                  className={styles.bread_detail_paragraph_down_large4}
                ></div>
                <WrapedBreadDetailRowV2
                  petternInformation={recipe}
                  isBold={false}
                  underLine={
                    petternInformation.recipes &&
                    petternInformation.recipes.filter(
                      (recipe) => recipe.materialId,
                    ).length -
                      1 !==
                      i
                      ? 'orange'
                      : undefined
                  }
                  editable={editable}
                  isSimulation={editable}
                  handleEditRecord={handleEditRecord}
                  handleDeleteButton={handleDeleteButton}
                  handleIsPreview={handleIsPreview}
                  dataType={dataType}
                  params={params ? [...params, DataType.RECIPE, true, i] : []}
                  nameReadOnly={
                    recipe.materialId && recipe.materialId !== 'manualInput'
                      ? true
                      : false
                  }
                />
              </div>
            ))
        : ''}
      {
        // 生地原料のうち原材料のみを表示
      }
      {open && petternInformation.doughs
        ? petternInformation.doughs
            .filter((dough) => dough.materialId)
            .map((dough, i) => (
              <div className={open ? styles.bread_detail_primary_header : ''}>
                <div
                  className={styles.bread_detail_paragraph_down_large4}
                ></div>
                <WrapedBreadDetailRowV2
                  petternInformation={dough}
                  isBold={false}
                  underLine={
                    petternInformation.doughs &&
                    petternInformation.doughs.filter(
                      (dough) => dough.materialId,
                    ).length -
                      1 !==
                      i
                      ? 'orange'
                      : undefined
                  }
                  editable={editable}
                  isSimulation={editable}
                  handleEditRecord={handleEditRecord}
                  handleDeleteButton={handleDeleteButton}
                  handleIsPreview={handleIsPreview}
                  dataType={dataType}
                  params={params ? [...params, DataType.DOUGH, true, i] : []}
                  nameReadOnly={
                    dough.materialId && dough.materialId !== 'manualInput'
                      ? true
                      : false
                  }
                />
              </div>
            ))
        : ''}
      {
        // フィリング原料のうち原材料のみを表示
      }
      {open && petternInformation.fillings
        ? petternInformation.fillings
            .filter((filling) => filling.materialId)
            .map((filling, i) => (
              <div className={open ? styles.bread_detail_primary_header : ''}>
                <div
                  className={styles.bread_detail_paragraph_down_large4}
                ></div>
                <WrapedBreadDetailRowV2
                  petternInformation={filling}
                  isBold={false}
                  underLine={
                    petternInformation.fillings &&
                    petternInformation.fillings.filter(
                      (filling) => filling.materialId,
                    ).length -
                      1 !==
                      i
                      ? 'orange'
                      : undefined
                  }
                  editable={editable}
                  isSimulation={editable}
                  handleEditRecord={handleEditRecord}
                  handleDeleteButton={handleDeleteButton}
                  handleIsPreview={handleIsPreview}
                  dataType={dataType}
                  params={params ? [...params, DataType.FILLING, true, i] : []}
                  nameReadOnly={
                    filling.materialId && filling.materialId !== 'manualInput'
                      ? true
                      : false
                  }
                />
              </div>
            ))
        : ''}
      {
        // 直接紐づく原材料を表示
      }
      {open && petternInformation.materials
        ? petternInformation.materials.map((material, i) => (
            <div className={open ? styles.bread_detail_primary_header : ''}>
              <div className={styles.bread_detail_paragraph_down_large4}></div>
              <WrapedBreadDetailRowV2
                petternInformation={material}
                isBold={false}
                underLine={
                  petternInformation.materials &&
                  petternInformation.materials.length - 1 !== i
                    ? 'orange'
                    : undefined
                }
                editable={editable}
                isSimulation={editable}
                handleEditRecord={handleEditRecord}
                handleDeleteButton={handleDeleteButton}
                handleIsPreview={handleIsPreview}
                dataType={dataType}
                params={params ? [...params, DataType.MATERIAL, true, i] : []}
                nameReadOnly={
                  material.materialId && material.materialId !== 'manualInput'
                    ? true
                    : false
                }
              />
            </div>
          ))
        : ''}
      {open && editable ? (
        <div className={styles.bread_detail_add_button}>
          <BreadDetailAddButtonV2
            handleAddButton={handleAddButton}
            dataType={dataType}
            params={params}
            unitType={
              petternInformation.recipes &&
              petternInformation.recipes.length > 0
                ? petternInformation.recipes[0].unitType
                : petternInformation.doughs &&
                  petternInformation.doughs.length > 0
                ? petternInformation.doughs[0].unitType
                : petternInformation.fillings &&
                  petternInformation.fillings.length > 0
                ? petternInformation.fillings[0].unitType
                : petternInformation.materials &&
                  petternInformation.materials.length > 0
                ? petternInformation.materials[0].unitType
                : UnitType.GRAM
            }
          />
        </div>
      ) : (
        ''
      )}
    </>
  )
}

type WrapedProps = {
  petternInformation: BreadDetailPetternInformationType
  isBold: boolean
  nameMinusWidth?: number
  underLine?: 'gray' | 'orange' | undefined
  editable?: boolean
  isSimulation?: boolean
  handleEditRecord?: (
    dataType: DataType | undefined,
    key:
      | 'productName'
      | 'manufacturerName'
      | 'materialId'
      | 'middleKneadPercent'
      | 'authenticKneadPercent'
      | 'supplierCostPrice'
      | 'supplierTax'
      | 'internalCapacityMemo'
      | 'yieldRate'
      | 'internalCapacityCount'
      | 'internalCapacityUnit'
      | 'numberItem'
      | 'numberItemUnit'
      | 'unitPrice1g'
      | 'cost',
    value: any,
    params: (number | DataType | boolean)[] | undefined,
    inputPetternInfos?: BreadDetailPetternInformationType[] | null,
  ) => BreadDetailPetternInformationType[]
  handleDeleteButton?: (
    dataType: DataType | undefined,
    params: (number | DataType | boolean)[] | undefined,
  ) => void
  handleIsPreview?: (
    dataType: DataType | undefined,
    params: (number | DataType | boolean)[] | undefined,
  ) => void
  dataType?: DataType
  params?: (number | DataType | boolean)[]
  nameReadOnly?: boolean
  unitPriceInvisible?: boolean
}

const WrapedBreadDetailRowV2: FC<WrapedProps> = (props) => {
  const {
    petternInformation,
    isBold,
    nameMinusWidth,
    underLine,
    editable,
    isSimulation,
    handleEditRecord,
    handleDeleteButton,
    handleIsPreview,
    dataType,
    params,
    nameReadOnly,
    unitPriceInvisible,
  } = props

  const recursiveUnitPriceSum = (
    petternInformation: BreadDetailPetternInformationType,
  ) => {
    let priceSum = 0
    if (petternInformation.unitPrice1g) {
      priceSum += petternInformation.unitPrice1g
    }
    if (petternInformation.materials) {
      priceSum += petternInformation.materials.reduce((sum, material) => {
        return material.unitPrice1g ? sum + material.unitPrice1g : sum
      }, 0)
    }
    if (petternInformation.recipes) {
      petternInformation.recipes.forEach((recipe) => {
        priceSum += recursiveUnitPriceSum(recipe)
      })
    }
    if (petternInformation.doughs) {
      petternInformation.doughs.forEach((dough) => {
        priceSum += recursiveUnitPriceSum(dough)
      })
    }
    if (petternInformation.fillings) {
      petternInformation.fillings.forEach((filling) => {
        priceSum += recursiveUnitPriceSum(filling)
      })
    }
    return priceSum
  }

  const recursiveCostPriceSum = (
    petternInformation: BreadDetailPetternInformationType,
  ) => {
    let priceSum = 0
    if (petternInformation.cost) {
      priceSum += petternInformation.cost
    }
    if (petternInformation.materials) {
      priceSum += petternInformation.materials.reduce((sum, material) => {
        return material.cost ? sum + material.cost : sum
      }, 0)
    }
    if (petternInformation.recipes) {
      petternInformation.recipes.forEach((recipe) => {
        priceSum += recursiveCostPriceSum(recipe)
      })
    }
    if (petternInformation.doughs) {
      petternInformation.doughs.forEach((dough) => {
        priceSum += recursiveCostPriceSum(dough)
      })
    }
    if (petternInformation.fillings) {
      petternInformation.fillings.forEach((filling) => {
        priceSum += recursiveCostPriceSum(filling)
      })
    }
    return priceSum
  }

  return (
    <>
      {editable ? (
        <BreadDetailInputTextRowV2
          name={petternInformation.productName}
          maker={
            petternInformation.manufacturerName
              ? petternInformation.manufacturerName
              : ''
          }
          middlePercent={String(petternInformation.middleKneadPercent)}
          middlePercentUnit={petternInformation.unitType}
          mainPercent={String(petternInformation.authenticKneadPercent)}
          mainPercentUnit={petternInformation.unitType}
          allPercent={String(
            Number(petternInformation.middleKneadPercent) +
              Number(petternInformation.authenticKneadPercent),
          )}
          allPercentUnit={petternInformation.unitType}
          unitPrice={
            petternInformation.unitPrice1g
              ? String(
                  (
                    Math.floor(petternInformation.unitPrice1g * 100) / 100
                  ).toFixed(2),
                )
              : String(
                  (
                    Math.floor(
                      recursiveUnitPriceSum(petternInformation) * 100,
                    ) / 100
                  ).toFixed(2),
                )
          }
          unitPriceUnit={'円 / g'}
          costPrice={
            petternInformation.cost
              ? String(
                  (Math.floor(petternInformation.cost * 10) / 10).toFixed(1),
                )
              : String(
                  (
                    Math.floor(recursiveCostPriceSum(petternInformation) * 10) /
                    10
                  ).toFixed(1),
                )
          }
          costPriceUnit={'円'}
          isHeader={false}
          isBold={isBold}
          isParagraphDown={false}
          underLine={underLine}
          mode="primary"
          handleEditRecord={handleEditRecord}
          handleDeleteButton={handleDeleteButton}
          handleIsPreview={handleIsPreview}
          dataType={dataType}
          params={params}
          nameReadOnly={nameReadOnly}
          unitPriceInvisible={unitPriceInvisible}
        />
      ) : (
        <BreadDetailRowV2
          name={petternInformation.productName}
          maker={
            petternInformation.manufacturerName
              ? petternInformation.manufacturerName
              : ''
          }
          middlePercent={
            String(petternInformation.middleKneadPercent) +
            petternInformation.unitType
          }
          mainPercent={
            String(petternInformation.authenticKneadPercent) +
            petternInformation.unitType
          }
          allPercent={
            String(
              Number(petternInformation.middleKneadPercent) +
                Number(petternInformation.authenticKneadPercent),
            ) + petternInformation.unitType
          }
          unitPrice={
            petternInformation.materialId === 'manualInput'
              ? '材料未登録'
              : petternInformation.materialId &&
                !petternInformation.supplierCostPrice &&
                petternInformation.supplierCostPrice !== 0
              ? '原価未登録'
              : petternInformation.unitPrice1g
              ? String(
                  (
                    Math.floor(petternInformation.unitPrice1g * 100) / 100
                  ).toFixed(2),
                ) + '円 / g'
              : String(
                  (
                    Math.floor(
                      recursiveUnitPriceSum(petternInformation) * 100,
                    ) / 100
                  ).toFixed(2),
                ) + '円 / g'
          }
          costPrice={
            petternInformation.materialId === 'manualInput'
              ? '材料未登録'
              : petternInformation.materialId &&
                !petternInformation.supplierCostPrice &&
                petternInformation.supplierCostPrice !== 0
              ? '原価未登録'
              : petternInformation.cost
              ? String(
                  (Math.floor(petternInformation.cost * 10) / 10).toFixed(1),
                ) + '円'
              : String(
                  (
                    Math.floor(recursiveCostPriceSum(petternInformation) * 10) /
                    10
                  ).toFixed(1),
                ) + '円'
          }
          dataType={petternInformation.dataType}
          docId={
            petternInformation.materialId ? petternInformation.materialId : null
          }
          isHeader={false}
          isBold={isBold}
          isParagraphDown={false}
          nameMinusWidth={nameMinusWidth}
          underLine={underLine}
          mode="secondary"
          isSimulation={isSimulation}
          unitPriceInvisible={unitPriceInvisible}
          isInactive={!!(petternInformation.materialId === 'manualInput')}
        />
      )}
    </>
  )
}

export default BreadDetailBoxSecondaryV2
